import {
  OrderStateTextileType,
  TextileAppState
} from 'api/textile_deals/fetchTextileAppState';

export type SectionType =
  | 'onboarding'
  | 'dashboard'
  | 'management'
  | 'profile'
  | 'own_preorder'
  | 'preorder'
  | 'design'
  | 'calculator'
  | 'checkout'
  | 'account'
  | 'timeplan'
  | 'discount'
  | 'management_checkout'
  | 'order'
  | 'order_placed';

export function hasAccess(
  context: SectionType,
  textileAppState?: TextileAppState
): boolean {
  if (!textileAppState) {
    return false;
  }

  const textileOrder = textileAppState.textile_order;

  if (!textileOrder) {
    return false;
  }

  if (textileAppState.textile_profile.is_applicant) {
    return false;
  }

  if (textileAppState.textile_profile.is_organizer) {
    switch (context) {
      case 'onboarding':
        if (textileOrder.order_state !== 'onboarding') {
          return false;
        } else {
          return true;
        }
      case 'management':
        if (atLeastOrderStarted(textileOrder.order_state)) {
          return false;
        } else {
          return true;
        }
      case 'management_checkout':
        if (
          (textileAppState.textile_profile.is_checkout_author &&
            !atLeastOrderPlaced(textileOrder.order_state)) ||
          !atLeastOrderStarted(textileOrder.order_state)
        ) {
          return true;
        } else {
          return false;
        }
      case 'order':
        if (
          textileAppState.textile_profile.is_checkout_author &&
          atLeastOrderPlaced(textileOrder.order_state)
        ) {
          return true;
        } else {
          return false;
        }
      case 'preorder':
        if (atLeastOrderStarted(textileOrder.order_state)) {
          return false;
        } else {
          return true;
        }
      case 'own_preorder':
        if (atLeastOrderStarted(textileOrder.order_state)) {
          return false;
        } else {
          return true;
        }
      case 'profile':
        if (atLeastOrderStarted(textileOrder.order_state)) {
          return false;
        } else {
          return true;
        }
      case 'design':
        if (atLeastOrderStarted(textileOrder.order_state)) {
          return false;
        } else {
          return true;
        }

      case 'calculator':
        if (atLeastOrderPlaced(textileOrder.order_state)) {
          return false;
        } else {
          return true;
        }

      case 'account':
        return true;
      case 'checkout':
        if (textileOrder.order_state === 'preparing') {
          return false;
        } else {
          return true;
        }
      case 'timeplan':
        return true;
      case 'discount':
        if (atLeastOrderPlaced(textileOrder.order_state)) {
          return false;
        } else {
          return true;
        }
      case 'order_placed':
        if (atLeastOrderPlaced(textileOrder.order_state)) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  if (textileAppState.textile_profile.is_student) {
    switch (context) {
      case 'onboarding':
        return false;
      case 'management':
        return false;
      case 'own_preorder':
        if (textileOrder.order_state !== 'preorder') {
          return false;
        }
        return true;
      case 'preorder':
        return false;
      case 'profile':
        if (
          atLeastOrderStarted(textileOrder.order_state) ||
          !textileOrder.print_data_edit_open
        ) {
          return false;
        } else {
          return true;
        }
      case 'design':
        return false;
      case 'calculator':
        return false;
      case 'account':
        return true;
      case 'checkout':
        return false;
      case 'timeplan':
        return false;
      case 'discount':
        return true;
      case 'order_placed':
        return false;
      default:
        return false;
    }
  }

  return false;
}

export const atLeastPreorder = (
  order_state: OrderStateTextileType
): boolean => {
  switch (order_state) {
    case 'preorder':
    case 'preorder_paused':
    case 'order_started':
    case 'order_placed':
    case 'order_confirmed':
    case 'order_printing':
    case 'order_shipped':
    case 'done':
      return true;

    default:
      return false;
  }
};

export const atLeastOrderPlaced = (
  order_state: OrderStateTextileType
): boolean => {
  switch (order_state) {
    case 'order_placed':
    case 'order_confirmed':
    case 'order_printing':
    case 'order_shipped':
    case 'done':
      return true;

    default:
      return false;
  }
};

export const atLeastOrderStarted = (
  order_state: OrderStateTextileType
): boolean => {
  switch (order_state) {
    case 'order_started':
    case 'order_placed':
    case 'order_confirmed':
    case 'order_printing':
    case 'order_shipped':
    case 'done':
      return true;

    default:
      return false;
  }
};

export const isLockedForOrder = (
  order_state: OrderStateTextileType
): boolean => {
  return order_state === 'order_placed' || order_state === 'order_started';
};

export const orderAtLeastConfirmed = (
  order_state: OrderStateTextileType
): boolean => {
  switch (order_state) {
    case 'order_confirmed':
    case 'order_printing':
    case 'order_shipped':
    case 'done':
      return true;

    default:
      return false;
  }
};

export const orderAtLeastPaymentOpen = (
  order_state: OrderStateTextileType
): boolean => {
  switch (order_state) {
    case 'order_placed':
    case 'order_confirmed':
    case 'order_printing':
    case 'order_shipped':
    case 'done':
      return true;

    default:
      return false;
  }
};
