import ItemStackBlock from "blocks/ItemStackBlock";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import Paragraph from "components/Paragraph/Paragraph";
import Center from "elements/Center";
import Headline from "elements/Headline";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ROUTE_ACCOUNT_DASHBOARD, ROUTE_ACCOUNT_DASHBOARD_ACCOUNT } from "utils/constants/routes";

const LegacyContainer = styled.div`
  margin: auto;
`
const ImageContainer = styled.div`
  height: 60px;
  width: auto;
`

const TextileLegacyScreen = () => {
    return (
      <>
      <HeaderComponent
        linkHome={ROUTE_ACCOUNT_DASHBOARD}
        linkAccount={ROUTE_ACCOUNT_DASHBOARD_ACCOUNT}
      />
      <LegacyContainer>
        <Center>
          <ItemStackBlock gap="XXS">
            <ImageContainer>
              <img src="/images/archived.svg" alt="locked" />
            </ImageContainer>
            <Headline.Large>
              <FormattedMessage id="textile order is legacy" />
            </Headline.Large>
            <Paragraph color="TEXT_LIGHT" size="L">
              <FormattedMessage id="textile order is legacy info" />
            </Paragraph>
          </ItemStackBlock>
        </Center>
      </LegacyContainer>
    </>
    );
}

export default TextileLegacyScreen;
