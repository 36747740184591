import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

interface PricingTableProps {
  pricingsJSON: { [key: string]: { [key: string]: number } };
  textileName?: string;
  displayName?: string;
}

const TextilePricingTable = ({
  pricingsJSON,
  textileName,
  displayName
}: PricingTableProps) => {
  if (!textileName || !displayName) {
    return null;
  }

  const pricingsForTextile = pricingsJSON[textileName];

  const amounts = Object.keys(pricingsForTextile).map(Number);

  const result = [];
  let previousValue;

  for (const key of amounts) {
    const value = pricingsForTextile[key];

    if (value !== previousValue) {
      result.push({ amount: key, price: value });
      previousValue = value;
    }
  }

  return (
    <>
      <Headline.Medium>
        <FormattedMessage id="textile pricing table header" />
      </Headline.Medium>

      <ItemStackBlock gap="XXS">
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="textile pricing table" /> - {displayName}
        </Paragraph>

        {result
          .sort((a, b) => b.amount - a.amount)
          .map((item) => {
            return (
              <HorizontalStackBlock key={item.amount} justified={true}>
                <Paragraph size="M" weight="BOLD">
                  <FormattedMessage
                    id="textile price per amount"
                    values={{ amount: item.amount }}
                  />
                </Paragraph>
                <Paragraph size="M" weight="BOLD">
                  <FormattedMessage
                    id="textile price per piece"
                    values={{
                      price: (
                        <FormattedNumber
                          value={item.price}
                          style="currency"
                          currency="EUR"
                        />
                      )
                    }}
                  />
                </Paragraph>
              </HorizontalStackBlock>
            );
          })}
      </ItemStackBlock>
    </>
  );
};

export default TextilePricingTable;
