import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  TextileGroup,
  createTextileGroup,
  getTextileGroup,
  getTextileGroups,
  removeTextileGroup,
  setTextileGroupsSorting,
  updateTextileGroup
} from 'api/textile_deals/fetchTextileGroups';

export const useTextileGroupsQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_groups', textileOrderId],
    queryFn: () => getTextileGroups(textileOrderId!),
    enabled: !!textileOrderId
  });
};

export const useTextileGroupQuery = (textileOrderId?: number, id?: number) => {
  return useQuery({
    queryKey: ['textile_group', id],
    queryFn: () => getTextileGroup(id!, textileOrderId!),
    enabled: !!id && !!textileOrderId
  });
};

export const useCreateTextileGroup = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { textileOrderId: number; name: string }) =>
      createTextileGroup(data.textileOrderId, data.name),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['textile_groups', patch.textileOrderId]);
      const previousStateGroups:
        | TextileGroup[]
        | undefined = queryClient.getQueryData([
        'textile_groups',
        patch.textileOrderId
      ]);

      if (!previousStateGroups) {
        return;
      }

      const newGroup = { id: -1, name: patch.name, student_names: [], teacher_names: [] };
      const newGroupList = previousStateGroups;
      newGroupList.push(newGroup);
      queryClient.setQueryData(
        ['teacher_list', patch.textileOrderId],
        newGroupList
      );

      return { previousStateGroups };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_groups', patch.textileOrderId],
        context?.previousStateGroups
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_groups', textileOrderId]);
    }
  });
};

export const useOptimisticUpdateTexitleGroupQuery = (
  textileOrderId?: number,
  id?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { data: TextileGroup; textileOrderId: number }) =>
      updateTextileGroup(patch.data.id, patch.data, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['textile_groups', patch.textileOrderId]);
      await queryClient.cancelQueries(['textile_group', patch.data.id]);
      const previousStateGroups:
        | TextileGroup[]
        | undefined = queryClient.getQueryData([
        'textile_groups',
        patch.textileOrderId
      ]);

      const previousStateGroup:
        | TextileGroup
        | undefined = queryClient.getQueryData([
        'textile_group',
        patch.data.id
      ]);

      if (!previousStateGroups || !previousStateGroup) {
        return;
      }

      const newStateGroup: TextileGroup = patch.data;
      queryClient.setQueryData(['textile_group', patch.data.id], newStateGroup);

      const newStateGroups: TextileGroup[] = previousStateGroups.map((itm) => {
        if (itm.id === patch.data.id) {
          itm = patch.data;
        }
        return itm;
      });

      queryClient.setQueryData(
        ['textile_groups', patch.textileOrderId],
        newStateGroups
      );

      return { previousStateGroups, previousStateGroup };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_group', patch.data.id],
        context?.previousStateGroup
      );
      queryClient.setQueryData(
        ['textile_groups', patch.textileOrderId],
        context?.previousStateGroups
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_groups', textileOrderId]);
      queryClient.invalidateQueries(['textile_group', id]);
    }
  });
};

export const useOptimisticDeleteTexitleGroupQuery = (
  textileOrderId?: number,
  id?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number }) =>
      removeTextileGroup(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['textile_groups', patch.textileOrderId]);
      await queryClient.cancelQueries(['textile_group', patch]);
      const previousStateGroups:
        | TextileGroup[]
        | undefined = queryClient.getQueryData([
        'textile_groups',
        patch.textileOrderId
      ]);

      if (!previousStateGroups) {
        return;
      }

      queryClient.setQueryData(
        ['textile_groups', patch.textileOrderId],
        (old?: TextileGroup[]) => old?.filter((t) => t.id !== patch.id)
      );

      return { previousStateGroups };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_groups', patch.textileOrderId],
        context?.previousStateGroups
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_groups', textileOrderId]);
    }
  });
};

export const useMoveTextileGroup = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { groups: TextileGroup[]; textileOrderId: number }) =>
      setTextileGroupsSorting(patch.groups, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['textile_groups', patch.textileOrderId]);
      const previousStateGroups:
        | TextileGroup[]
        | undefined = queryClient.getQueryData([
        'textile_groups',
        patch.textileOrderId
      ]);

      if (!previousStateGroups) {
        return;
      }

      queryClient.setQueryData(
        ['textile_groups', patch.textileOrderId],
        patch.groups
      );

      return { previousStateGroups };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_groups', patch.textileOrderId],
        context?.previousStateGroups
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_groups', textileOrderId]);
    }
  });
};
