export enum SPACES {
  NO = '0',
  XXS = '0.625rem',
  XS = '0.94rem',
  S = '1.25rem',
  M = '1.56rem',
  L = '1.87rem',
  XL = '2.5rem',
  XXL = '7.5rem'
}

export default SPACES;
