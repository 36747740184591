import { useQuery } from "@tanstack/react-query"
import { getTextileVariantByTextile, getTextileVariantsForTextileOrder } from "api/textile_deals/fetchTextileVariants"

export const useTextileVariantsQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_variants', textileOrderId],
    queryFn: () => getTextileVariantsForTextileOrder(textileOrderId!),
    enabled: !!textileOrderId,
    staleTime: 7 * 24 * 60 * 60 * 1000, // we dont want to fetch this every time, this data can be stale for a while in the normal app context
    cacheTime: 7 * 24 * 60 * 60 * 1000
  })
}

export const useTextileVariantsByTextileQuery = (textileId?: number) => {
  return useQuery({
    queryKey: ['textile_variants_by_textile', textileId],
    queryFn: () => getTextileVariantByTextile(textileId!),
    enabled: !!textileId,
    staleTime: 7 * 24 * 60 * 60 * 1000, // we dont want to fetch this every time, this data can be stale for a while in the normal app context
    cacheTime: 7 * 24 * 60 * 60 * 1000
  })
}