import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TopActionBlock from 'blocks/TopActionBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_ONBOARDING, ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';

import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import NumberOfStudentsSelect from './NumberOfStudentsSelect';

interface SelectClassSizeProps {
  applicationStore: ApplicationStoreType;
  onboarding?: boolean;
}

// TODO: ROUTE_SETTINGS + '/class' does not exist anymore - remove all references here or keep them just to be sure?

@inject('applicationStore')
@observer
class SelectClassSizeScreen extends React.Component<
  SelectClassSizeProps & HistoryProps
> {
  componentDidMount() {
    const { applicationStore, history } = this.props;

    if (
      !applicationStore.isBookLoading &&
      (isPush(history) || !applicationStore.book)
    ) {
      applicationStore.getBook();
    }
  }

  reloadInfo() {
    this.props.applicationStore.getBook();
  }

  async updateSize(newSize: number) {
    if (this.props.onboarding) {
      try {
        await this.props.applicationStore.completeOnboarding();
        await this.props.applicationStore.setClassSize(newSize);
      } catch (e) {}

      this.props.history.push(ROUTE_ONBOARDING + '/invite');
    } else {
      try {
        await this.props.applicationStore.setClassSize(newSize);
      } catch (e) {}

      this.props.history.push(ROUTE_SETTINGS + '/class');
    }
  }

  renderPage(content: any) {
    if (this.props.onboarding) {
      return (
        <>
          <SignupHeaderComponent progressStatus={50} />

          <PageStackBlock>
            <Headline.Large>
              <FormattedMessage id="onboarding size header" />
            </Headline.Large>

            {content}
          </PageStackBlock>
        </>
      );
    }

    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_SETTINGS + '/class'} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="number of students add" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderView() {
    const { applicationStore } = this.props;

    return this.renderPage(
      <>
        <NumberOfStudentsSelect
          onSelect={(_, number) => this.updateSize(number)}
          selected={
            applicationStore.book && applicationStore.book.number_of_students
          }
        />
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.reloadInfo()} />
    );
  }

  render() {
    const { applicationStore } = this.props;

    if (applicationStore.isBookLoading) {
      return this.renderLoading();
    }

    if (applicationStore.isBookError) {
      return this.renderError();
    }

    return this.renderView();
  }
}

export default SelectClassSizeScreen;
