import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import React from 'react';

const TextileOrderInformation = ({
  textileName,
  colorName
}: {
  textileName?: string;
  colorName?: string;
}) => {
  return (
    <ItemStackBlock gap="XS">
      {textileName && (
        <HorizontalStackBlock justified={true}>
          <Paragraph size="M" weight="BOLD">
            Modell
          </Paragraph>
          <Paragraph size="M">{textileName}</Paragraph>
        </HorizontalStackBlock>
      )}

      {colorName && (
        <HorizontalStackBlock justified={true}>
          <Paragraph size="M" weight="BOLD">
            Farbe
          </Paragraph>
          <Paragraph size="M">{colorName}</Paragraph>
        </HorizontalStackBlock>
      )}

      <HorizontalStackBlock justified={true}>
        <Paragraph size="M" weight="BOLD">
          Motiv
        </Paragraph>
        <Paragraph size="M">Vorne & Hinten bedruckt</Paragraph>
      </HorizontalStackBlock>

      <HorizontalStackBlock justified={true}>
        <Paragraph size="M" weight="BOLD">
          Druckfarben
        </Paragraph>
        <Paragraph size="M">unbegrenzt</Paragraph>
      </HorizontalStackBlock>
    </ItemStackBlock>
  );
};

export default TextileOrderInformation;
