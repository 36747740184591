import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import InputMessage from 'elements/InputMessage';
import { CustomBindingType, PriceTier } from 'models/BookModel';
import { BookPriceErrorModelType } from 'models/BookPriceErrorModel';
import { BookPriceModelType } from 'models/BookPriceModel';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import { FormType } from 'utils/hooks/useForm';
import { sanitizeNumberInput } from 'utils/sanitize_number_input';

const CustomTextDisplayErrorInput = styled(TextInputComponent)<{
  hasError: boolean;
}>`
  border: 1px solid
    rgba(${(props) => (props.hasError ? COLORS.RED_RGB : COLORS.GRAY800)}, 0.8);
`;

interface CalculatorFormProps {
  form: FormType;
  changeInput: () => void;
  bookPrice?: BookPriceModelType;
  type: 'checkout' | 'calculator';
  errors?: BookPriceErrorModelType;
  priceTier: PriceTier;
  customBindingType?: CustomBindingType;
}

const CalculatorForm = ({
  form,
  changeInput,
  bookPrice,
  type,
  priceTier,
  errors,
  customBindingType
}: CalculatorFormProps) => {
  const softcover = form.bindRadioButton('binding_type', 'softcover');
  const hardcover = form.bindRadioButton('binding_type', 'hardcover');

  const isPagesRounded = bookPrice && bookPrice.is_pages_rounded;
  const isPiecesRounded = bookPrice && bookPrice.is_pieces_rounded;
  const noHardCoverAllowed = bookPrice && bookPrice.no_hardcover_allowed;

  const pageCountError = errors && errors.page_count_error;
  const piecesError = errors && errors.pieces_error;

  const hasPageCountError =
    pageCountError && pageCountError.length !== 0 ? true : false;
  const hasPiecesError = piecesError && piecesError.length !== 0 ? true : false;

  const formPriceTier: 'silver' | 'gold' | 'platinum' =
    form.values.price_tier === 'platinum_light'
      ? 'gold'
      : form.values.price_tier;

  const hardcoverExtraCharge =
    bookPrice &&
    bookPrice.price[formPriceTier].hardcover &&
    bookPrice.price[formPriceTier].hardcover!.possible_price /
      bookPrice.pieces -
      bookPrice.price[formPriceTier].softcover.possible_price /
        bookPrice.pieces;

  const hardCoverMaxPages = bookPrice && bookPrice.hardcover_max_pages;

  const hasMessage =
    hasPageCountError || hasPiecesError || isPagesRounded || isPiecesRounded;

  const hardcoverLabel = (
    <>
      <HorizontalStackBlock justified={true}>
        <FormattedMessage id="Hardcover" />
        {hardcoverExtraCharge !== undefined && (
          <TextElement size="XS" weight="BOLD">
            {' + '}
            <FormattedNumber
              value={hardcoverExtraCharge}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="EUR"
            />{' '}
            <FormattedMessage id="Hardcover extra charge per piece" />
          </TextElement>
        )}
      </HorizontalStackBlock>
      <TextElement size="M">
        <FormattedMessage id="hardcover info" />
      </TextElement>
    </>
  );

  const priceTierLabel = (priceTier: PriceTier) => {
    return (
      <>
        <p>
          <FormattedMessage id={'price tier ' + priceTier} />
        </p>
        <p>
          <TextElement size="M">
            <FormattedMessage id={'price tier info ' + priceTier} />
          </TextElement>
        </p>
      </>
    );
  };

  return (
    <ItemStackBlock gap="M">
      <HorizontalStackBlock justified={true}>
        <ItemStackBlock gap="S" style={{ flex: '1', marginRight: '.625rem' }}>
          <CustomTextDisplayErrorInput
            name="pieces"
            type="number"
            label={<FormattedMessage id="Pieces" />}
            {...form.bindInput('pieces', changeInput)}
            hasError={hasPiecesError}
            onKeyDown={(event: { key: string; preventDefault: () => any }) => sanitizeNumberInput(event)}
          />
        </ItemStackBlock>
        <ItemStackBlock gap="S" style={{ flex: '1', marginLeft: '.625rem' }}>
          <CustomTextDisplayErrorInput
            name="page_count"
            type="number"
            label={<FormattedMessage id="Page count" />}
            {...form.bindInput('page_count', changeInput)}
            disabled={type === 'checkout' ? true : false}
            hasError={hasPageCountError}
            onKeyDown={(event: { key: string; preventDefault: () => any }) => sanitizeNumberInput(event)}
          />
        </ItemStackBlock>
      </HorizontalStackBlock>

      {hasMessage && (
        <ItemStackBlock gap="S">
          {hasPageCountError && (
            <InputMessage error={true}>{[...pageCountError!]}</InputMessage>
          )}

          {hasPiecesError && (
            <InputMessage error={true}>{[...piecesError!]}</InputMessage>
          )}

          {isPagesRounded && (
            <InputMessage warning={true}>
              <FormattedMessage id="Rounded page count info" />
            </InputMessage>
          )}

          {isPiecesRounded && (
            <InputMessage warning={true}>
              <FormattedMessage id="Rounded pieces info" />
            </InputMessage>
          )}
        </ItemStackBlock>
      )}

      <ItemStackBlock gap="XS">
        <Paragraph color="BLACK">
          <FormattedMessage id="Binding" />
        </Paragraph>
        {customBindingType === 'softcover' && (
          <RadioButtonComponent
            name="binding_type"
            value="softcover"
            label={
              <>
                <p>
                  <FormattedMessage id="Softcover" />
                </p>
                <p>
                  <TextElement size="M">
                    <FormattedMessage id="softcover info" />
                  </TextElement>
                </p>
              </>
            }
            {...softcover}
          />
        )}
        {customBindingType === 'hardcover' && (
          <RadioButtonComponent
            name="binding_type"
            value="hardcover"
            label={hardcoverLabel}
            {...hardcover}
          />
        )}

        {!customBindingType || customBindingType === 'no_binding_type' ? (
          <>
            <RadioButtonComponent
              name="binding_type"
              value="softcover"
              label={
                <>
                  <p>
                    <FormattedMessage id="Softcover" />
                  </p>
                  <p>
                    <TextElement size="M">
                      <FormattedMessage id="softcover info" />
                    </TextElement>
                  </p>
                </>
              }
              {...softcover}
            />
            <RadioButtonComponent
              name="binding_type"
              value="hardcover"
              label={hardcoverLabel}
              {...hardcover}
            />

            {noHardCoverAllowed && form.values.binding_type === 'hardcover' && (
              <InputMessage error={true}>
                <FormattedMessage
                  id="too many pages hardcover"
                  values={{
                    count: hardCoverMaxPages
                  }}
                />
              </InputMessage>
            )}
          </>
        ) : (
          ''
        )}
      </ItemStackBlock>

      <ItemStackBlock gap="XS">
        <Paragraph color="BLACK">
          <FormattedMessage id="price tier" />
        </Paragraph>
        {priceTier !== 'default' ? (
          <RadioButtonComponent
            name="price_tier"
            label={priceTierLabel(form.values.price_tier)}
            {...form.bindRadioButton('price_tier', form.values.price_tier)}
          />
        ) : (
          <>
            <RadioButtonComponent
              name="price_tier"
              value="silver"
              label={priceTierLabel('silver')}
              {...form.bindRadioButton('price_tier', 'silver')}
            />
            <RadioButtonComponent
              name="price_tier"
              value="gold"
              label={priceTierLabel('gold')}
              {...form.bindRadioButton('price_tier', 'gold')}
            />

            <RadioButtonComponent
              name="price_tier"
              value="platinum"
              label={priceTierLabel('platinum')}
              {...form.bindRadioButton('price_tier', 'platinum')}
            />
          </>
        )}
      </ItemStackBlock>
    </ItemStackBlock>
  );
};

export default CalculatorForm;
