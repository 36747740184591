import { get } from "api/BaseApi";
import { FullName, TextileGroup } from "./fetchTextileGroups";

interface TextileBackNames {
  student_names: FullName[];
  teacher_names: FullName[];
  groups: TextileGroup[];
}

export const getTextileBackNames = (textileOrderId: number): Promise<TextileBackNames> => {
  return get(`/textile_names_back?textile_order_id=${textileOrderId}`);
}