import Paragraph from 'components/Paragraph/Paragraph';
import InputMessage from 'elements/InputMessage';
import React from 'react';

interface InfoTextoForTextileComponentProps {
  size_info?: string;
}

const InfoTextoForTextileComponent: React.FC<InfoTextoForTextileComponentProps> = ({
  size_info
}) => {
  if (!size_info) {
    return null;
  }

  return (
    <InputMessage warning={true}>
      <Paragraph color="BLACK">{size_info}</Paragraph>
    </InputMessage>
  );
};

export default InfoTextoForTextileComponent;
