import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { useSelectedTextileVariantQuery } from 'queries/textile_deals/useSelectedTextileVariantQueries';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { useCreateProfileTexitlePreorderQuery } from 'queries/textile_deals/useTextilePreorderQueries';
import { useCurrentTextileProfileQuery } from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import InfoTextoForTextileComponent from 'screens/preorders/components/InfoTextForTextileComponent';
import OrganizerMessageComponent from 'screens/preorders/components/OrganizerMessageComponent';
import PreordersFormErrorsComponent from 'screens/preorders/components/PreorderFormErrorsComponent';
import PreorderPreferenceComponent from 'screens/preorders/components/PreorderPreferenceComponent';
import SizesByTextileComponent from 'screens/preorders/components/SizesByTextileVariantComponent';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const ProfilePreorderAddScreen: React.FC<HistoryProps & FormProps> = (
  props
) => {
  const [errors, setErrors] = useState<string[]>([]);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const textileProfileId = idFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const selectedTextileVariant = useSelectedTextileVariantQuery(textileOrderId);
  const currentTextileProfile = useCurrentTextileProfileQuery(
    textileProfileId,
    textileOrderId
  );
  const createTextilePreorder = useCreateProfileTexitlePreorderQuery(
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (textileAppState?.textile_order.order_state === 'preparing') {
      props.history.replace(textileOrderRoute(props.match, '/preorder'));
    }

    accessContext.checkSectionAccess('preorder');

    props.form.setField('preorder_state', 'submitted');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessContext,
    textileAppState?.textile_order.order_state,
    currentTextileProfile.data
  ]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/preorder')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={
              currentTextileProfile.data?.first_name +
              ' ' +
              currentTextileProfile.data?.last_name
            }
            text={''}
          />
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (currentTextileProfile.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => currentTextileProfile.refetch()}
      />
    );
  }

  if (selectedTextileVariant.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => selectedTextileVariant.refetch()}
      />
    );
  }

  if (
    props.form.loading ||
    !textileAppState ||
    textileOrder.isLoading ||
    !textileOrder.data ||
    selectedTextileVariant.isLoading ||
    !selectedTextileVariant.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const addPreorder = () => {
    const { form, match, history } = props;

    if (!textileOrderId || !currentTextileProfile.data) {
      return;
    }

    const currentProfileName =
      currentTextileProfile.data.first_name +
      ' ' +
      currentTextileProfile.data?.last_name;

    const currentErrors = [];

    console.log(form.values.preorder_state === 'submitted', form.values.size);

    if (form.values.preorder_state === 'submitted' && !form.values.size) {
      currentErrors.push(
        intl.formatMessage({ id: 'preorder add missing size' })
      );
      setErrors(currentErrors);
    }

    let patch;
    if (form.values.preorder_state === 'submitted' && form.values.size) {
      patch = {
        textile_profile_id: currentTextileProfile.data.id,
        textile_size_id: form.values.size
      };
    } else if (form.values.preorder_state === 'no_order') {
      patch = {
        textile_profile_id: currentTextileProfile.data.id
      };
    }

    if (patch) {
      form.setLoading(true);
      createTextilePreorder.mutate(
        {
          data: patch,
          textileOrderId,
          textileProfileId: currentTextileProfile.data.id,
          preorder_state: form.values.preorder_state,
          name: currentProfileName
        },
        {
          onSettled: () => {
            form.setLoading(false);
            history.push(textileOrderRoute(match, '/preorder'));
          }
        }
      );
    }
  };

  const renderAdd = () => {
    return renderPage(
      <>
        <Divider />
        <Headline.Medium>
          <FormattedMessage id="Your Order" />
        </Headline.Medium>
        <OrganizerMessageComponent
          organizerMessage={textileOrder.data.organizer_preorder_message}
        />
        <InfoTextoForTextileComponent
          size_info={selectedTextileVariant.data.textile_size_info}
        />
        <PreorderPreferenceComponent form={props.form} />
        <SizesByTextileComponent
          form={props.form}
          sizes={selectedTextileVariant.data.textile_sizes}
        />
        <PreordersFormErrorsComponent errors={errors} />
        <ButtonBlock background="PRIMARY" onClick={() => addPreorder()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  };

  return renderAdd();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <ProfilePreorderAddScreen {...props} form={form} />;
};
