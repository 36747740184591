import { get, post } from "api/BaseApi";

type DiscountIdentifier = 'book_closed_discount' | 'nuggit_individual_discount'

export interface TextileDiscount {
  id: number;
  value: number;
  name?: string;
  amount?: number;
  identifier?: DiscountIdentifier;
  info?: string;
  info_done?: string;
  info_not_used?: string;
  checked: boolean;
}

export interface TextilePrice {
  price: Price;
  discounts?: TextileDiscount[];
  preorders_amount?: number;
  form_amount?: number;
  errors: string[];
}

interface Price {
  price: number;
  end_price: number;
  possible_price: number;
  form_textile_price: number;
  price_per_piece: number;
  price_textiles: number;
  textile_name: string;
}

export const calculateTextilePriceFromForm = (
  textileId: number,
  amount: number,
  textileOrderId: number
): Promise<TextilePrice> => {
  return post(`/textile_pricing/calculate_form`, {
    textile_pricing: {
      textile_id: textileId,
      amount: amount
    },
    textile_order_id: textileOrderId
  });
}

export const getTextilePricingList = (): Promise<{[key: string]: { [key: string]: number }}> => {
  return get(`/textile_pricing/pricing_list`);
}