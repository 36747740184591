import React, { JSXElementConstructor } from 'react';

import {
  EveryCard,
  EveryCardBody,
  EveryCardFooter,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';

import { PreorderProfileListItem } from 'api/textile_deals/fetchPreorders';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';
import { FormattedMessage } from 'react-intl';
import { CardTag } from 'screens/settings/screens/AccountScreen/ManageStudentsListItem';

type PreorderTagType = 'deactivated' | 'open';

export const PreorderCardTag = ({ type }: { type?: PreorderTagType }) => {
  switch (type) {
    case 'deactivated':
      return (
        <EveryCardFooter textStart={true} background="RED_LIGHT">
          <TextElement color="BLACK">
            <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
            <FormattedMessage id="Deactivated" />
          </TextElement>
        </EveryCardFooter>
      );

    case 'open':
      return (
        <EveryCardFooter textStart={true} background="PRIMARY_LIGHT">
          <TextElement color="BLACK">
            <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />
            <FormattedMessage id="TBD" />
          </TextElement>
        </EveryCardFooter>
      );

    default:
  }

  return null;
};

export interface TextileProfileListItemProps {
  profile: PreorderProfileListItem;
  preorder_deactivated?: boolean;
  onClick?: (virtual: boolean, preorderId?: number, profileId?: number) => void;
}

export type TextileProfileListItemType = JSXElementConstructor<
  TextileProfileListItemProps
>;

export const TextilePreorderProfileListItem: TextileProfileListItemType = ({
  profile,
  preorder_deactivated,
  onClick
}: TextileProfileListItemProps) => (
  <EveryCard
    as={onClick && 'button'}
    onClick={
      onClick &&
      (() => onClick(profile.virtual, profile.preorder_id, profile.profile_id))
    }
  >
    <EveryCardPadding>
      <EveryCardBody>
        <EveryCardHeadline>
          {profile.name || profile.preorder_id}
        </EveryCardHeadline>
      </EveryCardBody>
      <CardTag
        type={
          profile.virtual
            ? 'manuel'
            : profile.organizer
            ? 'organizer'
            : undefined
        }
      />
    </EveryCardPadding>
    <PreorderCardTag
      type={
        preorder_deactivated
          ? 'deactivated'
          : profile.preorder_state === 'open'
          ? 'open'
          : undefined
      }
    />
  </EveryCard>
);

export default TextilePreorderProfileListItem;
