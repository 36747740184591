
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createPhotoTextile, CreateTextilePhotoBody, getTextilePhoto, removePhotoTextile, updatePhotoTextile, UpdateTextilePhotoBody } from "api/textile_deals/fetchTextilePhoto";

export const useTextilePhotoQuery = (textileOrderId?: number, photoId?: number) => {
  return useQuery({
    queryKey: ['textile_photo', photoId],
    queryFn: () => getTextilePhoto(photoId!, textileOrderId!),
    enabled: !!textileOrderId && !!photoId,
  })
}

export const useCreateTextilePhotoQuery = (textileOrderId?: number) => {
  return useMutation({
    mutationFn: (data: { textileOrderId: number; body: CreateTextilePhotoBody }) =>
      createPhotoTextile(data.body, data.textileOrderId)
  });
};

export const useUpdateTextilePhotoQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { textileOrderId: number; body: UpdateTextilePhotoBody }) =>
      updatePhotoTextile(data.body, data.textileOrderId),
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(['textile_photo', variables.body.id]);
      queryClient.invalidateQueries(['textile_design_setting', variables.textileOrderId]);
    }
  });
}

export const useDeleteTextilePhotoQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { textileOrderId: number; photoId: number }) =>
      removePhotoTextile(data.photoId, data.textileOrderId),
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(['textile_design_setting', variables.textileOrderId]);
    }
  });
}
