import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EveryCard,
  EveryCardBody,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import BackLink from 'domain/Links/BackLink';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import {
  ROUTE_ACCOUNT_DASHBOARD,
  ROUTE_DASHBOARD
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface JoinBookScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
}

@inject('applicationStore', 'profilesStore')
@observer
class JoinBookScreen extends React.Component<
  JoinBookScreenProps & HistoryProps
> {
  state = {
    loadingState: undefined
  };

  componentDidMount() {
    const { applicationStore, history } = this.props;

    if (applicationStore.clientStates?.has('join_book_role_select')) {
      history.replace(ROUTE_ACCOUNT_DASHBOARD);
    }
  }

  private async requestOrganizerRole() {
    const { profilesStore, applicationStore } = this.props;

    const { currentUser } = applicationStore;

    if (!currentUser) {
      return;
    }

    this.setState({
      loadingState: 'loading'
    });

    try {
      if (currentUser.role === 'applicant') {
        await profilesStore!.requestRoleChange('applicant_to_organizer');
      }

      if (currentUser.role === 'student') {
        await profilesStore!.requestRoleChange('student_to_organizer');
      }

      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'role change requested flash' }),
        'success'
      );

      applicationStore.checkAuthenticatedBook(true);

      this.props.history.push(ROUTE_DASHBOARD);
    } catch (error: any) {
      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'role change request error flash' }),
        'error'
      );

      this.setState({
        loadingState: 'error'
      });
    }
  }

  private async requestStudent() {
    const { profilesStore, applicationStore } = this.props;

    const { currentUser } = applicationStore;

    if (!currentUser) {
      return;
    }

    this.setState({
      loadingState: 'loading'
    });

    try {
      await profilesStore!.requestRoleChange('applicant_to_student');

      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'role change requested flash' }),
        'success'
      );

      applicationStore.checkAuthenticatedBook(true);

      this.props.history.push(ROUTE_DASHBOARD);
    } catch (error: any) {
      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'role change request error flash' }),
        'error'
      );

      this.setState({
        loadingState: 'error'
      });
    }
  }

  private async stayStudent() {
    const { applicationStore } = this.props;

    const { currentUser } = applicationStore;

    if (!currentUser) {
      return;
    }

    this.setState({
      loadingState: 'loading'
    });

    if (currentUser.role !== 'applicant') {
      try {
        await applicationStore.updateClientState(
          'join_book_role_select',
          'sent'
        );

        this.props.history.push(ROUTE_DASHBOARD);
      } catch (errors: any) {
        this.setState({
          loadingState: 'error'
        });
      }
    }
  }

  private renderTopAction() {
    return (
      <TopActionBlock>
        <BackLink to={ROUTE_ACCOUNT_DASHBOARD} />
      </TopActionBlock>
    );
  }

  private renderPage(content?: any) {
    return (
      <>
        {this.renderTopAction()}

        <PageStackBlock>{content}</PageStackBlock>

        <HelpSubject hidden={true} />
      </>
    );
  }

  private renderOnboarding() {
    const { applicationStore } = this.props;
    const { currentUser } = applicationStore;

    if (!currentUser) {
      return null;
    }

    return this.renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="onboarding role header" />}
          text=""
        />

        <ListStackBlock>
          <EveryCard
            onClick={() =>
              currentUser.role !== 'applicant'
                ? this.stayStudent()
                : this.requestStudent()
            }
          >
            <EveryCardPadding>
              <EveryCardBody>
                <NonGapStackBlock>
                  <ItemStackBlock gap="XXS" marginBottom="XXS">
                    <Paragraph size="L" weight="BOLD">
                      <FormattedMessage id="role student" />
                    </Paragraph>
                  </ItemStackBlock>
                  <Paragraph color="TEXT_LIGHT" size="S">
                    <FormattedMessage id="role student info" />
                  </Paragraph>
                </NonGapStackBlock>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>

          <EveryCard onClick={() => this.requestOrganizerRole()}>
            <EveryCardPadding>
              <EveryCardBody>
                <NonGapStackBlock>
                  <ItemStackBlock gap="XXS" marginBottom="XXS">
                    <Paragraph size="L" weight="BOLD">
                      <FormattedMessage id="role organizer" />
                    </Paragraph>
                  </ItemStackBlock>
                  <Paragraph color="TEXT_LIGHT" size="S">
                    <FormattedMessage id="role organizer info" />
                  </Paragraph>
                </NonGapStackBlock>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </ListStackBlock>
      </>
    );
  }

  render() {
    if (this.state.loadingState === 'loading') {
      return <LoadingOverlayComponent />;
    }

    return this.renderOnboarding();
  }
}

export default JoinBookScreen;
