import React from 'react';

import { Textile } from 'api/textile_deals/fetchTextiles';
import CardBlock from 'blocks/CardBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { FormattedMessage, FormattedNumber } from 'react-intl';

interface TextileOrderCalculatorFormProps {
  amount?: number;
  textile: Textile;
}

const TextileOrderCalculatorForm = ({
  amount,
  textile
}: TextileOrderCalculatorFormProps) => {
  if (!amount || !textile.possible_price) {
    return null;
  }

  return (
    <>
      <FormStackBlock>
        <TextInputComponent
          name="amount"
          type="number"
          disabled={true}
          label={<FormattedMessage id="textile count" />}
          value={amount}
        />
        <ItemStackBlock gap="XXS">
          <Paragraph size="M">
            <FormattedMessage id="textile model label" />
          </Paragraph>
          <CardBlock>
            <PaddingBlock arrow={false}>
              <Paragraph size="M" weight="BOLD">
                {textile.name}
              </Paragraph>
              <TextElement>
                <FormattedMessage
                  id="textile from price"
                  values={{
                    price: (
                      <FormattedNumber
                        value={textile.possible_price}
                        style="currency"
                        currency="EUR"
                      />
                    )
                  }}
                />
              </TextElement>
            </PaddingBlock>
          </CardBlock>
        </ItemStackBlock>
      </FormStackBlock>
    </>
  );
};

export default TextileOrderCalculatorForm;
