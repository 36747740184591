import { TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import ButtonBlock from 'blocks/ButtonBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import TextElement from 'components/TextElement/TextElement';
import InputMessage from 'elements/InputMessage';
import UppercaseHeading from 'elements/UppercaseHeading';
import { intl } from 'i18n';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormType } from 'utils/hooks/useForm';

interface TextileNameFormProps {
  form: FormType;
  textileGroups: TextileGroup[];
  onSave: () => void;
  onDelete?: () => void;
  formOfAddress?: boolean;
  showGroups?: boolean;
}

const TextileNameForm = ({
  form,
  textileGroups,
  formOfAddress,
  showGroups,
  onSave,
  onDelete
}: TextileNameFormProps) => {
  return (
    <>
      <FormStackBlock>
        {formOfAddress ? (
          <>
            <UppercaseHeading>
              <FormattedMessage id="Print" />
            </UppercaseHeading>

            <ListStackBlock>
              <RadioButtonComponent
                name="formOfAddress"
                value=""
                label={<FormattedMessage id="No address form" />}
                {...form.bindRadioButton('first_name', '')}
              />

              <RadioButtonComponent
                name="formOfAddress"
                value={intl.formatMessage({
                  id: 'MR'
                })}
                label={<FormattedMessage id="MR" />}
                {...form.bindRadioButton(
                  'first_name',
                  intl.formatMessage({
                    id: 'MR'
                  })
                )}
              />

              <RadioButtonComponent
                name="formOfAddress"
                value={intl.formatMessage({
                  id: 'MRS'
                })}
                label={<FormattedMessage id="MRS" />}
                {...form.bindRadioButton(
                  'first_name',
                  intl.formatMessage({
                    id: 'MRS'
                  })
                )}
              />
            </ListStackBlock>
          </>
        ) : (
          <TextInputComponent
            name="first_name"
            label={<FormattedMessage id="First name" />}
            {...form.bindInput('first_name')}
            autoFocus={true}
          />
        )}

        <TextInputComponent
          name="last_name"
          label={<FormattedMessage id="Last name" />}
          {...form.bindInput('last_name')}
        />

        {showGroups && textileGroups && textileGroups.length !== 0 && (
          <>
            <UppercaseHeading>
              <FormattedMessage id="Group" />
            </UppercaseHeading>
            {form.errors.textile_group_id && (
              <InputMessage error={true}>
                <TextElement color="BLACK">
                  {form.errors.textile_group_id}
                </TextElement>
              </InputMessage>
            )}
            <ListStackBlock>
              {textileGroups.map((group) => (
                <RadioButtonComponent
                  key={group.id}
                  name="group_id"
                  value={group.id}
                  label={group.name}
                  {...form.bindRadioButton(
                    'textile_group_id',
                    group.id.toString()
                  )}
                />
              ))}
            </ListStackBlock>
          </>
        )}
      </FormStackBlock>

      <ButtonBlock background="PRIMARY" onClick={() => onSave()}>
        <FormattedMessage id="Save" />
      </ButtonBlock>
      {!!onDelete && (
        <>
          <Divider />
          <ButtonBlock
            background="RED_LIGHT"
            color="RED"
            onClick={() => onDelete()}
          >
            <FormattedMessage id="Remove" />
          </ButtonBlock>
        </>
      )}
    </>
  );
};
export default TextileNameForm;
