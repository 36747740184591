import { TextileSize } from 'api/textile_deals/fetchTextileVariants';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import UppercaseHeading from 'elements/UppercaseHeading';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormType } from 'utils/hooks/useForm';

interface SizesByTextileVariantComponentProps {
  form: FormType;
  sizes?: TextileSize[];
}

const SizesByTextileComponent: React.FC<SizesByTextileVariantComponentProps> = ({
  form,
  sizes
}) => {
  if (form.values.preorder_state === 'no_order') {
    return null;
  }

  return (
    <>
      {sizes && sizes.length !== 0 && (
        <>
          <UppercaseHeading>
            <FormattedMessage id="Sizes" />
          </UppercaseHeading>
          <ListStackBlock>
            {sizes.map((size: TextileSize) => (
              <RadioButtonComponent
                key={size.id}
                name="size"
                value={size.id}
                label={size.title}
                {...form.bindRadioButton('size', size.id.toString())}
                disabled={form.values.preorder_state === 'no_order'}
              />
            ))}
          </ListStackBlock>
        </>
      )}
    </>
  );
};

export default SizesByTextileComponent;
