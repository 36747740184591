import { destroy, get, patch, post, uri } from "api/BaseApi";
import { BaseTextileProfile } from "./fetchTextileProfiles";
export type PreorderState = 'submitted' | 'no_order' | 'open';
export interface Preorder {
  id: number;
  textile_order_id: number;
  textile_profile_id?: number;
  textile_variant_id: number;
  author?: BaseTextileProfile;
  textile_size_id?: string;
  preorder_name?: string;
  textile_name?: string
  profile_preorder_state?: PreorderState;
  deactivated?: boolean;
}

export interface PreorderProfileListItem {
  preorder_id?: number;
  name: string;
  preorder_state: PreorderState;
  virtual: boolean;
  profile_id?: number;
  preorder_deactivated?: boolean;
  organizer?: boolean;
}

export const removeTextilePreorder = (id: number, textileOrderId: number) => {
  return destroy(
    '/textile_preorders/' + uri(id) + '?textile_order_id=' + textileOrderId
  );
}

export const getTextilePreorderProfilesList = (textileOrderId: number): Promise<PreorderProfileListItem[]> => {
  return get('/textile_preorders/profile_list?textile_order_id=' + textileOrderId);
}

export const getTextilePreorder = (preoderId: number, textileOrderId: number): Promise<Preorder> => {
  return get('/textile_preorders/' + uri(preoderId) + '?textile_order_id=' + textileOrderId);
}

export const createManualTextilePreorder = (patch: any, textileOrderId: number): Promise<Preorder> => {
  return post('/textile_preorders/create_manual_preorder', {
    textile_preorder: patch,
    textile_order_id: textileOrderId,
  });
}

export const createProfileTextilePreorder = (patch: any, textileOrderId: number, textileProfileId: number, preorder_state: "submitted" | "no_order"): Promise<Preorder> => {
  return post('/textile_preorders/create_profile_preorder', {
    textile_preorder: patch,
    textile_order_id: textileOrderId,
    textile_profile_id: textileProfileId,
    textile_profile: {
      preorder_state: preorder_state
    }
  });
}

export const updateManualTextilePreorder = (preorderId: number, textileOrderId: number, data: any): Promise<Preorder> => {
  return patch('/textile_preorders/' + uri(preorderId) + '/update_manual_preorder', {
    textile_order_id: textileOrderId,
    textile_preorder: data
  });
}

export const updateProfileTextilePreorder = (preorderId: number, textileOrderId: number, textileProfileId: number, data: any, preorder_state: "submitted" | "no_order"): Promise<Preorder> => {
  return patch('/textile_preorders/' + uri(preorderId) + '/update_profile_preorder', {
    textile_order_id: textileOrderId,
    textile_preorder: data,
    textile_profile_id: textileProfileId,
    textile_profile: {
      preorder_state: preorder_state
    }
  });
}

export const deactivateProfileTextilePreorder = (preorderId: number, textileOrderId: number): Promise<Preorder> => {
  return patch('/textile_preorders/' + uri(preorderId) + '/deactivate_preorder', {
    textile_order_id: textileOrderId
  });
}

export const reactivateProfileTextilePreorder = (preorderId: number, textileOrderId: number): Promise<Preorder> => {
  return patch('/textile_preorders/' + uri(preorderId) + '/reactivate_preorder', {
    textile_order_id: textileOrderId
  });
}