import React, { useContext, useEffect } from 'react';

import { TextileOrderForManagement } from 'api/textile_deals/fetchTextileOrders';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import TextElement from 'components/TextElement/TextElement';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { HasAccessContext } from 'contexts/HasAccessContext';
import { intl } from 'i18n';
import {
  useOptimisticUpdateTextileOrderQuery
} from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedMessage } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
  textileOrder: TextileOrderForManagement;
}

const NameEditingToggleScreen: React.FC<FormProps> = (props) => {
  const textileOrderId = props.textileOrder.id;
  const accessContext = useContext(HasAccessContext);
  const flashMessageContext = useContext(FlashMessageContext);
  const updateTextileOrder = useOptimisticUpdateTextileOrderQuery(
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  if (!flashMessageContext) {
    throw Error('Component must be used within HasFlashMessageProvider');
  }

  useEffect(() => {
    if (props.textileOrder) {
      props.form.setField(
        'allow_edit_print_data',
        props.textileOrder.allow_edit_print_data || false
      );
    }
    accessContext.checkSectionAccess('management');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext]);

  const setPrintDataState = (allow: boolean) => {
    if (!textileOrderId || !props.textileOrder) {
      return;
    }

    const patch: TextileOrderForManagement = {
      ...props.textileOrder,
      allow_edit_print_data: allow
    };
    updateTextileOrder.mutate(
      { data: patch, textileOrderId },
      {
        onSuccess: () => {
          props.form.setField('allow_edit_print_data', allow);
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'Saved' }),
            'success'
          );
        }
      }
    );
  };

  const renderActivate = () => {
    const { form } = props;

    if (form.values.allow_edit_print_data === undefined) {
      return null;
    }

    return (
      <>
        <FormStackBlock>
          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="allow_edit_print_data"
              {...form.bindCheckbox('allow_edit_print_data')}
              onClick={(e: any) => setPrintDataState(e.target.checked)}
            />
            <CustomCheckboxBlock.LabelElement htmlFor="allow_edit_print_data">
              <FormattedMessage id="toggle edit states" />
            </CustomCheckboxBlock.LabelElement>
            {form.values.allow_edit_print_data ? (
              <TextElement size="S">
                <FormattedMessage id="textile edit allowed" />
              </TextElement>
            ) : (
              <TextElement size="S">
                <FormattedMessage id="textile edit not allowed" />
              </TextElement>
            )}
          </CustomCheckboxBlock>
        </FormStackBlock>
      </>
    );
  };

  return renderActivate();
};

export default (props: { textileOrder: TextileOrderForManagement }) => {
  const form = useForm();
  // @ts-ignore
  return <NameEditingToggleScreen {...props} form={form} />;
};
