import { useQuery } from "@tanstack/react-query";
import { getTextileThemes } from "api/textile_deals/fetchTextileThemes";

export const useTextileThemesQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_themes', textileOrderId],
    queryFn: () => getTextileThemes(textileOrderId!),
    enabled: !!textileOrderId,
    staleTime: 7 * 24 * 60 * 60 * 1000, // we dont want to fetch this every time, this data can be stale for a while in the normal app context
    cacheTime: 7 * 24 * 60 * 60 * 1000
  })
}