import { get, uri } from "api/BaseApi";
import { TextileGroup } from "./fetchTextileGroups";
import { TextileProfilePrintState } from "./fetchTextileProfiles";

export type StudentType = 'textile_profile' | 'textile_name';

export interface TextileStudent {
  id: number;
  identifier: string;
  first_name?: string;
  last_name?: string;
  name?: string;
  textile_group?: TextileGroup;
  type?:  StudentType;
  organizer?: boolean;
  print_state: TextileProfilePrintState;
  deactivated?: boolean;
}

export const getAllStudents = (id: number): Promise<TextileStudent[]> => {
  return get('/textile_orders/' + uri(id) + '/all_students');
}

export const getAllStudentsWithoutGroups = (id: number): Promise<TextileStudent[]> => {
  return get('/textile_orders/' + uri(id) + '/all_students_without_groups');
}