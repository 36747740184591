import React, { useEffect } from 'react';

import { inject, observer, useObserver } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { BooksStoreType } from 'models/BooksStore';

import { BaseTextileOrder } from 'api/textile_deals/fetchTextileOrders';
import {
  EveryCard,
  EveryCardBody,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import HeaderComponent from 'components/HeaderComponent/HeaderComponent';
import IconComponent from 'components/IconComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import { useTextileOrdersQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedMessage } from 'react-intl';
import { CardTag } from 'screens/settings/screens/AccountScreen/ManageStudentsListItem';
import {
  ROUTE_ACCOUNT_DASHBOARD,
  ROUTE_ACCOUNT_DASHBOARD_ACCOUNT,
  ROUTE_BOOK,
  ROUTE_TEXTILE
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import isTextileLocked from 'utils/isTextileLocked';

interface AccountDashboardProps {
  applicationStore: ApplicationStoreType;
  booksStore: BooksStoreType;
}

const AccountDashboardScreen: React.FC<
  HistoryProps & AccountDashboardProps
> = ({ applicationStore, booksStore, history }) => {
  const textileOrders = useTextileOrdersQuery();

  useEffect(() => {
    const loadData = async () => {
      await booksStore.availableBook();
    };

    loadData();
  }, [booksStore]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <HeaderComponent
          linkHome={ROUTE_ACCOUNT_DASHBOARD}
          linkAccount={ROUTE_ACCOUNT_DASHBOARD_ACCOUNT}
          nolinkProjects={true}
        />
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Account dashboard header" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  };

  const renderDashboard = () => {
    const { currentAccount } = applicationStore;
    const { book } = booksStore;

    if (!currentAccount) {
      return null;
    }

    return renderPage(
      <ListStackBlock>
        {book ? (
          <EveryCard
            onClick={() => {
              history.push(ROUTE_BOOK);
            }}
            disabled={book.isLockedForOrder || !currentAccount.profile_id}
          >
            <EveryCardPadding>
              <EveryCardBody>
                <HorizontalStackBlock
                  justifyCenter={true}
                  gap="XS"
                  centered={true}
                >
                  <IconComponent
                    icon="BOOKS"
                    fill={
                      book.isLockedForOrder && !currentAccount.profile_id
                        ? 'GRAY900'
                        : 'PRIMARY'
                    }
                    circleBackground={
                      book.isLockedForOrder && !currentAccount.profile_id
                        ? 'GRAY800'
                        : 'PRIMARY_LIGHT'
                    }
                    size={4.5}
                    iconOffsetCircle={20}
                  />
                  <NonGapStackBlock>
                    <ItemStackBlock gap="XXS" marginBottom="XXS">
                      <Paragraph size="L" weight="BOLD">
                        <FormattedMessage id="Book" />
                      </Paragraph>
                    </ItemStackBlock>
                    <HorizontalStackBlock gap="XS">
                      {book.profiles_count && (
                        <Paragraph color="TEXT_LIGHT" size="S">
                          <IconComponent icon="USERGROUP" />
                          {book.profiles_count}
                        </Paragraph>
                      )}

                      {currentAccount.is_book_organizer ? (
                        <>
                          <CardTag type="organizer" />
                        </>
                      ) : (
                        book.manager_name && (
                          <Paragraph color="TEXT_LIGHT" size="S">
                            <IconComponent icon="ORGANIZER" />
                            {book.manager_name}
                          </Paragraph>
                        )
                      )}
                    </HorizontalStackBlock>

                    <Paragraph color="TEXT_LIGHT" size="S">
                      <FormattedMessage id="go to book" />
                    </Paragraph>
                  </NonGapStackBlock>
                </HorizontalStackBlock>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        ) : (
          <EveryCard disabled={true}>
            <EveryCardPadding>
              <EveryCardBody>
                <HorizontalStackBlock
                  justifyCenter={true}
                  gap="XS"
                  centered={true}
                >
                  <IconComponent
                    icon="BOOKS"
                    fill="PRIMARY"
                    circleBackground="PRIMARY_LIGHT"
                    size={4.5}
                    iconOffsetCircle={20}
                  />
                  <NonGapStackBlock>
                    <ItemStackBlock gap="XXS" marginBottom="XXS">
                      <Paragraph size="L" weight="BOLD">
                        <FormattedMessage id="Book" />
                      </Paragraph>
                    </ItemStackBlock>
                    <Paragraph color="TEXT_LIGHT" size="S">
                      <FormattedMessage id="Schoolyear done" />
                    </Paragraph>
                  </NonGapStackBlock>
                </HorizontalStackBlock>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        )}

        <>
          {textileOrders.data?.length === 0 ? (
            <EveryCard disabled={true}>
              <EveryCardPadding>
                <EveryCardBody>
                  <HorizontalStackBlock
                    justifyCenter={true}
                    gap="XS"
                    centered={true}
                  >
                    <IconComponent
                      icon="HOODY"
                      fill="PRIMARY"
                      circleBackground="PRIMARY_LIGHT"
                      size={4.5}
                      iconOffsetCircle={20}
                    />
                    <NonGapStackBlock>
                      <ItemStackBlock gap="XXS" marginBottom="XXS">
                        <Paragraph size="L" weight="BOLD">
                          <FormattedMessage id="Textile order" />
                        </Paragraph>
                      </ItemStackBlock>
                      <Paragraph color="TEXT_LIGHT" size="S">
                        <FormattedMessage id="Schoolyear done" />
                      </Paragraph>
                    </NonGapStackBlock>
                  </HorizontalStackBlock>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          ) : (
            <>
              {textileOrders.data?.map((textileOrder: BaseTextileOrder) => {
                const accountHasTextileOrder = currentAccount.textile_order_ids.find(
                  (ids) => ids === textileOrder.id
                );
                const isOrganizer = currentAccount.textile_order_ids_organizer.find(
                  (ids) => ids === textileOrder.id
                );

                const isLocked =
                  isTextileLocked(
                    textileOrder.order_state,
                    'atLeastOrderStarted'
                  ) && !accountHasTextileOrder;
                return (
                  <EveryCard
                    onClick={() => {
                      history.push(ROUTE_TEXTILE + '/' + textileOrder.id);
                    }}
                    disabled={isLocked}
                    key={textileOrder.id}
                  >
                    <EveryCardPadding>
                      <EveryCardBody>
                        <HorizontalStackBlock
                          justifyCenter={true}
                          gap="XS"
                          centered={true}
                        >
                          <IconComponent
                            icon="HOODY"
                            fill={isLocked ? 'GRAY900' : 'PRIMARY'}
                            circleBackground={
                              isLocked ? 'GRAY800' : 'PRIMARY_LIGHT'
                            }
                            size={4.5}
                            iconOffsetCircle={20}
                          />
                          <NonGapStackBlock>
                            <ItemStackBlock gap="XXS" marginBottom="XXS">
                              <Paragraph size="L" weight="BOLD">
                                <FormattedMessage id="Textile order" />
                              </Paragraph>
                            </ItemStackBlock>
                            <HorizontalStackBlock gap="XS">
                              {textileOrder.active_students_amount && (
                                <Paragraph color="TEXT_LIGHT" size="S">
                                  <IconComponent icon="USERGROUP" />
                                  {textileOrder.active_students_amount}
                                </Paragraph>
                              )}
                              {isOrganizer ? (
                                <>
                                  <CardTag type="organizer" />
                                </>
                              ) : (
                                textileOrder.manager_name && (
                                  <Paragraph color="TEXT_LIGHT" size="S">
                                    <IconComponent icon="ORGANIZER" />
                                    {textileOrder.manager_name}
                                  </Paragraph>
                                )
                              )}
                            </HorizontalStackBlock>

                            <Paragraph color="TEXT_LIGHT" size="S">
                              <FormattedMessage id="go to textile order" />
                            </Paragraph>
                          </NonGapStackBlock>
                        </HorizontalStackBlock>
                      </EveryCardBody>
                    </EveryCardPadding>
                  </EveryCard>
                );
              })}
            </>
          )}
        </>
      </ListStackBlock>
    );
  };

  return useObserver(() => {
    // if (textileOrders.isError) {
    //   return renderPage(
    //     <GenericErrorComponent onRetryClick={() => textileOrders.refetch()} />
    //   );
    // }

    if (
      // textileOrders.isLoading ||
      // !textileOrders.data ||
      booksStore.loadingState === 'loading'
    ) {
      return renderPage(<LoadingIndicatorComponent />);
    }
    return renderDashboard();
  });
};

// This is where you would inject your stores, similar to what you did in the class component
export default inject(
  'applicationStore',
  'booksStore',
)(observer(AccountDashboardScreen));
