import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTextileOrdersTextileVariant } from 'api/textile_deals/fetchTextileOrdersTextileVariant';
import { TextileVariant } from 'api/textile_deals/fetchTextileVariants';

export const useCreateTextileOrdersTextileVariantsQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      textileOrderId: number;
      textileVariant: TextileVariant;
    }) =>
      createTextileOrdersTextileVariant(
        data.textileOrderId,
        data.textileVariant.id
      ),

    onMutate: async (variables) => {
      const { textileOrderId, textileVariant } = variables;

      await queryClient.cancelQueries(['selected_textile_variant', textileOrderId]);

      const previousVariant = queryClient.getQueryData<TextileVariant>([
        'selected_textile_variant',
        textileOrderId,
      ]);

      queryClient.setQueryData(
        ['selected_textile_variant', textileOrderId],
        textileVariant
      );

      return { previousVariant };
    },

    onError: (err, variables, context) => {
      const { textileOrderId } = variables;
      queryClient.setQueryData(
        ['selected_textile_variant', textileOrderId],
        context?.previousVariant
      );
    },

    onSettled: (data, error, variables) => {
      const { textileOrderId } = variables;
      queryClient.invalidateQueries(['selected_textile_variant', textileOrderId]);
    },
  });
};
