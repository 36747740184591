import Divider from 'components/Divider/Divider';
import Paragraph from 'components/Paragraph/Paragraph';
import InputMessage from 'elements/InputMessage';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface OrganizerMessageComponentProps {
  organizerMessage?: string;
}

const OrganizerMessageComponent: React.FC<OrganizerMessageComponentProps> = ({
  organizerMessage
}) => {
  if (!organizerMessage) {
    return null;
  }

  return (
    <InputMessage info={true}>
      <Paragraph weight="BOLD" size="L">
        <FormattedMessage id="message from organiser" />
      </Paragraph>

      <Divider color="PURPLE" />

      <Paragraph>{organizerMessage}</Paragraph>
    </InputMessage>
  );
};

export default OrganizerMessageComponent;
