import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import Divider from 'components/Divider/Divider';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import SharingComponent from 'components/SharingComponent';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { HasAccessContext } from 'contexts/HasAccessContext';
import { intl } from 'i18n';
import { useTextileOnboardingQuery } from 'queries/textile_deals/useTextileAppStateQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const TextileOnboardingInviteScreen: React.FC<HistoryProps> = (props) => {
  const [didInteract, setDidInteract] = useState<boolean>(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const completeOnboardingTextile = useTextileOnboardingQuery(textileOrderId);

  const accessContext = useContext(HasAccessContext);
  const flashMessageContext = useContext(FlashMessageContext);
  if (!accessContext || !flashMessageContext) {
    throw Error('Component must be used witin context');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('onboarding');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext]);

  if (!textileAppState) {
    return <LoadingIndicatorComponent />;
  }

  const finish = () => {
    const textileOrderId = textileOrderIdFromMatch(props.match);

    if (!textileOrderId) {
      return;
    }

    completeOnboardingTextile.mutate(textileOrderId, {
      onSuccess: () => {
        props.history.push(
          textileOrderRoute(props.match, '/onboarding/invite')
        );
      },
      onError: () => {
        flashMessageContext.triggerFlashMessage(
          intl.formatMessage({ id: 'error generic' }),
          'error'
        );
      }
    });
  };

  return (
    <>
      <SignupHeaderComponent progressStatus={50} />

      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Invite students" />}
          text={<FormattedMessage id="onboarding invite text" />}
        />

        <SharingComponent
          label="personal invite link"
          buttonOutside={true}
          modalHeader="invite link"
          message="Nuggit invite text"
          buttonColor="WHATSAPP"
          url={textileAppState?.textile_order.invitation_url}
          onDidInteract={() => setDidInteract(true)}
        />

        <Divider />

        {didInteract ? (
          <ButtonBlock
            background="PRIMARY"
            color="WHITE"
            onClick={() =>
              finish()
            }
          >
            <FormattedMessage id="Next" />
          </ButtonBlock>
        ) : (
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() =>
              finish()
            }
          >
            <FormattedMessage id="Later" />
          </ButtonBlock>
        )}
      </PageStackBlock>
    </>
  );
};

export default TextileOnboardingInviteScreen;
