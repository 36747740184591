import React from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import {
  ROUTE_TEXTILE_ONBOARDING
} from 'utils/constants/routes';
import TextileOnboardingInviteScreen from './screens/TextileOnboardingInviteScreen';

export default () => (
  <Switch>
    <Route
      path={ROUTE_TEXTILE_ONBOARDING + '/invite'}
      component={TextileOnboardingInviteScreen}
      exact={true}
    />

    <NotFoundRoute />
  </Switch>
);
