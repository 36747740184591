import {
  CreateTextilePhotoBody,
  TextilePhoto,
  UpdateTextilePhotoBody
} from 'api/textile_deals/fetchTextilePhoto';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import ImageEditorComponent from 'components/ImageEditorComponent/ImageEditorComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import FileUploadComponent from 'components/Inputs/FileUploadComponent';
import { ACCEPT_IMAGES_AND_PDFS } from 'components/Inputs/FileUploadInput';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ImageConfig } from 'models/PhotoModel';
import {
  useCreateTextilePhotoQuery,
  useDeleteTextilePhotoQuery,
  useTextilePhotoQuery,
  useUpdateTextilePhotoQuery
} from 'queries/useTextilePhotoQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface PublicPhotoEditScreenProps extends HistoryProps {
  photoId?: number;
  parentId: number;
  handleSave?: () => void;
}

interface FormProps {
  form: FormType;
}

const TextilePhotoEditScreen: React.FC<
  PublicPhotoEditScreenProps & FormProps
> = (props) => {
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const flashMessageContext = useContext(FlashMessageContext);

  const textilePhoto = useTextilePhotoQuery(textileOrderId, props.photoId);
  const createTextilePhoto = useCreateTextilePhotoQuery();
  const updateTextilePhoto = useUpdateTextilePhotoQuery();
  const deleteTextilePhoto = useDeleteTextilePhotoQuery();

  if (!flashMessageContext) {
    throw Error('Component must be used within HasFlashMessageProvider');
  }

  const fillForm = (item: TextilePhoto) => {
    const { form } = props;

    form.setField(
      'photo',
      item.photo
        ? { old: { url: item.photo.shrinked, preview: item.photo.preview } }
        : null
    );

    form.setField('image_config', {
      // this is a hack to make Cropper recalculate aspect for new images
      // aspect: Aspect.LANDSCAPE,
    });
  };

  useEffect(() => {
    if (textilePhoto.data) fillForm(textilePhoto.data);
  }, [textilePhoto.data]);

  const handleUploadPhoto = (file: File) => {
    const { form, parentId } = props;

    if (!parentId || !textileOrderId) {
      return;
    }

    form.setLoading(true);

    const photoItem = textilePhoto.data;

    if (!photoItem || photoItem.id < 0) {
      // New photo
      const body: CreateTextilePhotoBody = {
        parent_id: parentId,
        photo: { file },
        image_config: {
          cutOff: {
            width: 100,
            height: 100,
            top: 0,
            left: 0
          },
          rotation: 0,
          crop: false
        }
      };


      createTextilePhoto.mutate(
        { textileOrderId, body: body },
        {
          onSuccess: () => {
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'photo added flash' }),
              'success'
            );
            props.history.push(
              textileOrderRoute(props.match, '/textile_design/back')
            );
          },
          onError: () => {
            form.setLoading(false);
          }
        }
      );
    } else {
      updateTextilePhoto.mutate(
        { textileOrderId, body: { id: photoItem.id, photo: { file } } },
        {
          onSuccess: () => {
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'photo updated flash' }),
              'success'
            );
            props.history.push(
              textileOrderRoute(props.match, '/textile_design/back')
            );
          },
          onError: () => {
            form.setLoading(false);
          }
        }
      );
    }
  };

  const updatePhoto = (imageConfig: ImageConfig, turn: boolean) => {
    const { form } = props;
    const { photo } = form.values;
    if (turn) {
      form.setLoading(true);
    }

    const photoItem = textilePhoto.data;
    if (!photoItem || !textileOrderId) {
      return;
    }

    const body: UpdateTextilePhotoBody = {
      id: photoItem.id,
      photo,
      image_config: imageConfig
    };

    updateTextilePhoto.mutate(
      { textileOrderId, body: body },

      {
        onSuccess: () => {
          if (!turn) {
            props.history.push(
              textileOrderRoute(props.match, '/textile_design/back')
            );
          }
          form.setLoading(false);
        },
        onError: (error: any) => {
          handleFormError(form, error);
        }
      }
    );
  };

  const performDelete = () => {
    const { photoId } = props;
    const textileOrderId = textileOrderIdFromMatch(props.match);

    const photoItem = textilePhoto.data;

    let id = photoId;
    if (!id && photoItem && photoItem.id) {
      id = photoItem.id;
    }

    if (!id || !textileOrderId) {
      return;
    }

    setConfirmDelete(false);

    deleteTextilePhoto.mutate(
      { textileOrderId, photoId: id },
      {
        onSuccess: () =>
          props.history.push(
            textileOrderRoute(props.match, '/textile_design/back')
          )
      }
    );
  };

  const isAddMode = () => {
    return props.photoId === undefined;
  };

  const resetForm = () => {
    const { form } = props;

    form.setField('image_config', {});
    form.setField('caption', undefined);
  };

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink
            to={textileOrderRoute(props.match, '/textile_design/back')}
          />
        </TopActionBlock>
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id={isAddMode() ? 'Add photo' : 'Edit photo'} />
          </Headline.Large>
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textilePhoto.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textilePhoto.refetch()} />
    );
  }

  if (textilePhoto.isFetching || props.form.loading) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const calculateOffsetX = (photoItem: TextilePhoto) => {
    if (!photoItem.image_config.cutOff || !photoItem.image_config.cutOff.left) {
      return undefined;
    }

    return photoItem.image_config.cutOff.left * 100;
  };

  const calculateOffsetY = (photoItem: TextilePhoto) => {
    if (!photoItem.image_config.cutOff || !photoItem.image_config.cutOff.top) {
      return undefined;
    }

    return photoItem.image_config.cutOff.top * 100;
  };

  const renderForm = () => {
    const { form } = props;

    const photoItem = textilePhoto.data;

    let extra;
    if (confirmDelete) {
      const textComponent = (
        <FormattedMessage
          id="confirm delete item"
          values={{
            item: <FormattedMessage id="This photo" />
          }}
        />
      );
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm delete header" />}
          text={textComponent}
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => performDelete()}
          onAbort={() => setConfirmDelete(false)}
          confirmColor="RED"
        />
      );
    }

    let url;
    let width;
    let height;
    let rotation = 0;
    let swap;
    let processing = false;
    let offsetX;
    let offsetY;
    let fitToFrame;
    let caption;

    if (photoItem) {
      if (photoItem.photo) {
        url = photoItem.photo.shrinked;
        width = photoItem.metadata.width;
        height = photoItem.metadata.height;
        rotation = photoItem.image_config.rotation || 0;
        offsetX = calculateOffsetX(photoItem);
        offsetY = calculateOffsetY(photoItem);
        fitToFrame = photoItem.fit_to_frame;
        const turnPosition = rotation / 90;
        swap = turnPosition % 2 === 0 ? false : true;
        if (swap) {
          width = photoItem.metadata.height;
          height = photoItem.metadata.width;
        }
        caption = photoItem.caption;
      } else if (!isAddMode()) {
        processing = true;
      }
    }

    return renderPage(
      <>
        {extra}

        {processing ? (
          <PageHeader
            headline={<FormattedMessage id="Photo is being processed" />}
            text={<FormattedMessage id="processing info" />}
          />
        ) : !isAddMode() && url && width && height ? (
          <ImageEditorComponent
            src={url}
            frameWidth={175}
            frameHeight={280}
            imageWidth={width}
            imageHeight={height}
            fitToFrameEnabled={true}
            fitToFrame={fitToFrame}
            rotation={rotation}
            handleSave={(imageConfig: ImageConfig, turn: boolean) => updatePhoto(imageConfig, turn)}
            offsetX={offsetX}
            offsetY={offsetY}
            unit={'px'}
            caption={caption}
            allowEditCaption={false}
          />
        ) : (
          <FileUploadComponent
            name="photo"
            accept={ACCEPT_IMAGES_AND_PDFS}
            autoUpload={true}
            resetForm={resetForm}
            onUploadPhoto={(file: File) => handleUploadPhoto(file)}
            url={url}
            isAddMode={isAddMode()}
            {...form.bindInput('photo')}
          />
        )}

        {!isAddMode() && (
          <DeleteButton onClick={() => setConfirmDelete(true)} />
        )}
      </>
    );
  };

  return renderForm();
};

export default withRouter((props: PublicPhotoEditScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <TextilePhotoEditScreen {...props} form={form} />;
});
