import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormType } from 'utils/hooks/useForm';

interface PreorderNameFieldComponentProps {
  form: FormType;
}

const PreorderNameFieldComponent: React.FC<PreorderNameFieldComponentProps> = ({
  form
}) => {
  return (
    <FormStackBlock>
      <TextInputComponent
        name="name"
        label={<FormattedMessage id="preorder manuell name" />}
        {...form.bindInput('name')}
      />
    </FormStackBlock>
  );
};

export default PreorderNameFieldComponent;
