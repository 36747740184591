import React, { useContext, useEffect } from 'react';

import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import CardBlock from 'blocks/CardBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { useSelectedTextileVariantQuery } from 'queries/textile_deals/useSelectedTextileVariantQueries';
import { useTextilesQuery } from 'queries/textile_deals/useTextileQueries';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const TextileDesignModelSelection: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  const selectedTextileVariant = useSelectedTextileVariantQuery(textileOrderId);
  const textiles = useTextilesQuery(textileOrderId);

  useEffect(() => {
    accessContext.checkSectionAccess('design');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/textile_design')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="textile design model selection" />}
            text={<FormattedMessage id="textile design model selection info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textiles.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textiles.refetch()} />
    );
  }

  if (selectedTextileVariant.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => selectedTextileVariant.refetch()}
      />
    );
  }

  if (
    selectedTextileVariant.isLoading ||
    !selectedTextileVariant.data ||
    textiles.isLoading ||
    !textiles.data ||
    !textileAppState
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderModelSelection = () => {
    const allTextiles = textiles.data;

    return renderPage(
      <>
        <Divider />
        <ListStackBlock>
          {allTextiles.map((textile) => (
            <Link
              to={textileOrderRoute(
                props.match,
                `/textile_design/color_selection/${textile.id}`
              )}
            >
              <CardBlock>
                <PaddingBlock arrow={false}>
                  <Headline.Small color="GRAY10">{textile.name}</Headline.Small>
                  {textile.possible_price && (
                    <TextElement>
                      <FormattedMessage
                        id="textile from price"
                        values={{
                          price: (
                            <FormattedNumber
                              value={textile.possible_price}
                              style="currency"
                              currency="EUR"
                            />
                          )
                        }}
                      />
                    </TextElement>
                  )}
                </PaddingBlock>
              </CardBlock>
            </Link>
          ))}
        </ListStackBlock>
      </>
    );
  };

  return renderModelSelection();
};

export default TextileDesignModelSelection;
