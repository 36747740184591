import React from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_TEXTILE_PREORDER } from 'utils/constants/routes';
import PreorderManagementScreen from './PreOrderManagementScreen';
import PreorderMessageFormScreen from './screens/PreorderMessageFormScreen';
import PreorderToggleScreen from './screens/PreorderToggleScreen';
import ProfilePreorderAddScreen from './screens/students/ProfilePreorderAddScreen';
import ProfilePreorderEditScreen from './screens/students/ProfilePreorderEditScreen';
import PreorderVirtualAddScreen from './screens/virtual/PreorderVirtualAddScreen';
import PreorderVirtualEditScreen from './screens/virtual/PreorderVirtualEditScreen';

export default () => (
  <Switch>
    <Route
      path={ROUTE_TEXTILE_PREORDER + '/virtual/add'}
      component={PreorderVirtualAddScreen}
    />

    {/* student preorder */}

    <Route
      path={ROUTE_TEXTILE_PREORDER + '/profile/add/:id'}
      component={ProfilePreorderAddScreen}
    />

    <Route
      path={ROUTE_TEXTILE_PREORDER + '/profile/edit/:id'}
      component={ProfilePreorderEditScreen}
    />

    <Route
      path={ROUTE_TEXTILE_PREORDER + '/virtual/edit/:id'}
      component={PreorderVirtualEditScreen}
    />

    {/* Management */}

    <Route
      path={ROUTE_TEXTILE_PREORDER + '/preorder_state'}
      component={PreorderToggleScreen}
    />

    <Route
      path={ROUTE_TEXTILE_PREORDER + '/preorder_message'}
      component={PreorderMessageFormScreen}
    />

    <Route
      path={ROUTE_TEXTILE_PREORDER}
      component={PreorderManagementScreen}
      exact={true}
    />

    <NotFoundRoute />
  </Switch>
);
