import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import TextElement from 'components/TextElement/TextElement';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { HasAccessContext } from 'contexts/HasAccessContext';
import { intl } from 'i18n';
import {
  usePausePreorderQuery,
  useUnpausePreorderQuery
} from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedMessage } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
  textileOrderId?: number;
}

const PreorderToggleScreen: React.FC<FormProps> = (props) => {
  const textileOrderId = props.textileOrderId
  const accessContext = useContext(HasAccessContext);
  const flashMessageContext = useContext(FlashMessageContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const pausePreorder = usePausePreorderQuery(textileOrderId);
  const unpausePreorder = useUnpausePreorderQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  if (!flashMessageContext) {
    throw Error('Component must be used within HasFlashMessageProvider');
  }

  useEffect(() => {
    const orderState = textileAppState?.textile_order.order_state;

    if (orderState) {
      props.form.setField('preorder', orderState === 'preorder' || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileAppState?.textile_order.order_state]);


  const setPreorderState = (preorderOpen: boolean) => {
    if (!textileOrderId) {
      return;
    }

    if (preorderOpen) {
      unpausePreorder.mutate(textileOrderId, {
        onSuccess: () => {
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'Saved' }),
            'success'
          );
        }
      });
    } else {
      pausePreorder.mutate(textileOrderId, {
        onSuccess: () => {
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'Saved' }),
            'success'
          );
        }
      });
    }
  };

  const renderActivate = () => {
    const { form } = props;

    if (form.values.preorder === undefined) {
      return null;
    }

    return (
      <>
        <FormStackBlock>
          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="preorder"
              {...form.bindCheckbox('preorder')}
              onClick={(e: any) => setPreorderState(e.target.checked)}
            />
            <CustomCheckboxBlock.LabelElement htmlFor="allow_edit_print_data">
              <FormattedMessage id="toggle edit states" />
            </CustomCheckboxBlock.LabelElement>
            {form.values.preorder ? (
              <TextElement size="S">
                <FormattedMessage id="preorder edit allowed" />
              </TextElement>
            ) : (
              <TextElement size="S">
                <FormattedMessage id="preorder edit not allowed" />
              </TextElement>
            )}
          </CustomCheckboxBlock>
        </FormStackBlock>
      </>
    );
  };

  return renderActivate();
};

export default (props: {textileOrderId?: number}) => {
  const form = useForm();
  // @ts-ignore
  return <PreorderToggleScreen {...props} form={form} />;
};
