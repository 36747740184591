import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { EveryCard, EveryCardBody, EveryCardHeadline, EveryCardPadding } from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import { useLegacyTextileOrderPlacedOverviewQuery } from 'queries/textile_deals/useOrderOverviewQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { MiniPreview } from 'screens/textile_design_settings/screens/colors/MiniPreview';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';


const LegacyTextileOrderPlacedOverviewScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const accessContext = useContext(HasAccessContext);
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const orderOverview = useLegacyTextileOrderPlacedOverviewQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (
      textileAppState?.textile_profile.is_student ||
      textileAppState?.textile_profile.is_applicant
    ) {
      props.history.replace(textileOrderRoute(props.match, ''));
    }

    accessContext.checkSectionAccess('order_placed');
  }, [accessContext]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>

        <HelpSubject subject="order" />
      </>
    );
  };

  if (orderOverview.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => orderOverview.refetch()} />
    );
  }

  if (!textileAppState || orderOverview.isLoading || !orderOverview.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderOverview = () => {
    if (!orderOverview.data.total_price) {
      return null;
    }

    const order = orderOverview.data.orders;
    const preordersAmount = orderOverview.data.preorders?.length || 0;
    const invoice_items = orderOverview.data.invoice_items;

    if (!order || order.length === 0) {
      return null;
    }

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="textile order overview" />}
          text={<FormattedMessage id="textile order overview total" />}
        />

        {preordersAmount !== 0 && (
          <EveryCard disabled={true}>
            <EveryCardPadding>
              <EveryCardHeadline>
                <FormattedMessage id="Total amount" />
              </EveryCardHeadline>
              <EveryCardBody right={true}>
                <TextElement color="TEXT_DARK" size="L" weight="BOLD">
                  {preordersAmount}
                </TextElement>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        )}

        {order.map((itm) => {
          return (
            <ItemStackBlock gap="S" key={itm.textile_name}>
              <Paragraph color="TEXT_LIGHT" textAlign="right" size="XS">
                <FormattedMessage id="Pieces" />
              </Paragraph>
              <HorizontalStackBlock justified={true}>
                <Paragraph size="L" weight="BOLD">
                  {itm.textile_name}
                </Paragraph>
                <Paragraph size="M" weight="BOLD">
                  {itm.textile_amount}
                </Paragraph>
              </HorizontalStackBlock>
              <Divider color="BLACK" useStackBlockGap={true} />

              {itm.textile_variants.map((variant) => {
                return (
                  <ItemStackBlock gap="S" key={variant.textile_color?.id}>
                    {variant.textile_color && variant.textile_color.color_hex && (
                      <HorizontalStackBlock justified={true}>
                        <HorizontalStackBlock gap="S">
                          <MiniPreview
                            colorCode={variant.textile_color.color_hex}
                          />
                          <Paragraph size="M" weight="BOLD">
                            {variant.textile_color?.display_name}
                          </Paragraph>
                        </HorizontalStackBlock>
                        <Paragraph size="M" weight="BOLD">
                          {variant.variant_amount}
                        </Paragraph>
                      </HorizontalStackBlock>
                    )}
                    <Divider useStackBlockGap={true} />

                    {variant.sizes.map((size) => {
                      return (
                        <ItemStackBlock gap="XXS" key={size.size}>
                          <HorizontalStackBlock justified={true}>
                            <HorizontalStackBlock gap="S">
                              <Paragraph color="TEXT_LIGHT">
                                {size.size}
                              </Paragraph>
                            </HorizontalStackBlock>
                            <Paragraph color="TEXT_LIGHT">
                              {size.size_amount}
                            </Paragraph>
                          </HorizontalStackBlock>
                          <Divider useStackBlockGap={true} />
                        </ItemStackBlock>
                      );
                    })}
                  </ItemStackBlock>
                );
              })}
            </ItemStackBlock>
          );
        })}

        {invoice_items && invoice_items.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Textile discount price" />
            </Headline.Medium>
            <ItemStackBlock gap="XXS">
              {invoice_items.map((itm) => {
                if (!itm.price) {
                  return null;
                }
                return (
                  <div key={itm.name}>
                    <ItemStackBlock gap="XXS">
                      <HorizontalStackBlock justified={true}>
                        <Paragraph size="M" weight="BOLD">
                          {itm.name}
                        </Paragraph>

                        <Paragraph size="M" weight="BOLD">
                          <FormattedNumber
                            value={itm.price}
                            style="currency"
                            currency="EUR"
                          />
                        </Paragraph>
                      </HorizontalStackBlock>
                      <HorizontalStackBlock justified={true}>
                        <Paragraph size="S" color="TEXT_LIGHT">
                          {itm.quantity}{' '}
                          <FormattedMessage id="textile pieces" />
                        </Paragraph>
                      </HorizontalStackBlock>
                    </ItemStackBlock>

                    <Divider />
                  </div>
                );
              })}
              <ItemStackBlock gap="S" marginTop="M">
                <ItemStackBlock gap="XXS">
                  <HorizontalStackBlock
                    gap="XXS"
                    centered={true}
                    noMarginLeft={true}
                  >
                    <TextElement size="L" weight="BOLD" color="BLACK">
                      <FormattedMessage id="Gross price possible" />
                    </TextElement>
                    <NakedLink
                      to={textileOrderRoute(props.match, '/discounts')}
                    >
                      <IconComponent icon="INFO_CIRCLE" />
                    </NakedLink>
                  </HorizontalStackBlock>
                  <Paragraph color="TEXT_LIGHT" size="XS">
                    <FormattedMessage id="textile include vat and used discounts" />
                  </Paragraph>
                </ItemStackBlock>

                <Paragraph size="HEADLINE_L" weight="EXTRA_BOLD">
                  <FormattedNumber
                    value={orderOverview.data.total_price}
                    style="currency"
                    currency="EUR"
                  />
                </Paragraph>
              </ItemStackBlock>
            </ItemStackBlock>
          </>
        )}
      </>
    );
  };

  return renderOverview();
};

export default LegacyTextileOrderPlacedOverviewScreen;