import React from 'react';

import {
  Route,
  Switch
} from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_TEXTILE_PROFILES } from 'utils/constants/routes';

import OwnPreoderEditScreen from './screens/OwnPreoderEditScreen';
import OwnPreorderAddScreen from './screens/OwnPreorderAddScreen';
import TextileProfileEdit from './screens/TextileProfileEdit';


export default () => (
  <Switch>

    <Route path={ROUTE_TEXTILE_PROFILES + "/preorder/add"} component={OwnPreorderAddScreen} />
    <Route path={ROUTE_TEXTILE_PROFILES + "/preorder/edit/:id"} component={OwnPreoderEditScreen} />
    <Route path={ROUTE_TEXTILE_PROFILES} exact={true} component={TextileProfileEdit} />

    <NotFoundRoute />
  </Switch>
);
