import { get, patch, post, uri } from "api/BaseApi";
import { UploadAgeForm, convertToFormData } from "api/NuggitApi";
import { TextilePrice } from "./fetchTextilePrices";
import { BaseTextileProfile } from "./fetchTextileProfiles";

export type PrintStateTextile =
  'print_open' |
  'enqueued' |
  'generating' |
  'generated'

export interface TextileCheckout {
  id: number;

  // Shipping context
  shipping_name?: string;
  phone_number?: string;
  shipping_address_extra?: string;
  shipping_city?: string;
  shipping_zip?: string;
  shipping_street?: string;
  shipping_housenumber?: string;

  // Billing context
  billing_name?: string;
  billing_address_extra?: string;
  billing_city?: string;
  billing_zip?: string;
  billing_street?: string;
  billing_housenumber?: string;
  billing_address_set?: boolean,

  // Printing context
  accept_print?: boolean;
  print_state?: PrintStateTextile;
  // AGB context
  accept_agb?: boolean;

  // Age context
  minor?: boolean;

  invoice?: Invoice;

  parental_approval?: string;

  parental_approval_checked?: boolean;

  payment_received?: boolean;

  author?: BaseTextileProfile;

  shipping_address?: boolean;
  billing_address?: boolean;

  delivery_date_selected?: boolean;
  age_verified?: boolean;

  price?: TextilePrice;
  student_front_preview?: string;
  student_back_preview?: string;
  order_overview_pdf?: string;
  student_front_mockup?: string;
  student_back_mockup?: string;
}

export interface Invoice {
  id: number;
  price: number;
  invoice_pdf?: string;
}

export const createTextileCheckout = (textileOrderId: number): Promise<TextileCheckout> => {
  return post('/textile_checkout', {
    textile_checkout: {},
    textile_order_id: textileOrderId
  });
}

export const getTextileCheckout = (textileOrderId: number): Promise<TextileCheckout> => {
  return get(`/textile_checkout?textile_order_id=${textileOrderId}`);
}

export const updateTextileCheckout = (checkoutPatch: any, textileOrderId: number): Promise<TextileCheckout> => {
  return patch('/textile_checkout/', {
    textile_checkout: checkoutPatch,
    textile_order_id: textileOrderId
  });
}

export const finishTextileCheckout = (textileOrderId: number): Promise<TextileCheckout> => {
  return patch('/textile_checkout/finish_checkout', {
    textile_order_id: textileOrderId
  });
}

export const uploadParentalApprovalTextile = (
  uploadPatch: UploadAgeForm,
  textileOrderId: number
): Promise<TextileCheckout> => {
  return patch(
    `/textile_checkout/upload_age_form?textile_order_id=${textileOrderId}`,
    convertToFormData(uploadPatch, 'textile_checkout')
  );
}

export const restartCheckout = (id: number): Promise<TextileCheckout> => {
  return get('/textile_orders/' + uri(id) + '/restart_checkout');
}
