import React from 'react';

import TextileProductImageComponent from 'screens/preorders/components/TextileProductImageComponent';
import TextileMockUpComponent from '../structure/TextileMockUpComponent';
import WaitForImagesTextiles from './WaitForImagesTextiles';

export interface TextileFrontTemplateProps {
  productImage?: string;
  layoutKey?: string;
  textileType?: string;
  layoutImage?: string;
}

export class TextileFrontMockUpTemplateComponent extends React.Component<
  TextileFrontTemplateProps
> {
  render() {
    const { productImage, layoutKey, textileType, layoutImage } = this.props;

    if (!productImage || !layoutKey || !textileType ) {
      return null;
    }

    return (
      <>
        <TextileMockUpComponent>
          <TextileProductImageComponent
            image={productImage}
            layoutKey={layoutKey}
            layoutImage={layoutImage}
            textileType={textileType}
            width="975px"
            height="1248px"
          />
        </TextileMockUpComponent>
        {<WaitForImagesTextiles />}
      </>
    );
  }
}

export default TextileFrontMockUpTemplateComponent;
