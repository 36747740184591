import { get } from 'api/BaseApi';

interface TextileThemes {
  themes: TextileThemeLayout[];
  mottos: TextileThemeMotto[];
}

export interface TextileThemeMotto {
  key: string;
  name: string;
}

export interface TextileThemeLayout {
  key: string;
  name?: string;
  tags: string[];
  defaultBackground?: string;
  defaultFont?: string;
  headlineFontColor?: string;
  subheadlineFontColor?: string;
  footerFontColor?: string;
  studentNamesFontColor?: string;
  teacherNamesFontColor?: string;
  groupsFontColor?: string;

  front?: string;
  frontPreview?: string;
}

export const getTextileThemes = (
  textileOrderId: number
): Promise<TextileThemes> => {
  return get('/textile_layouts/themes?textile_order_id=' + textileOrderId);
};
