import React from 'react';

import config from 'config';
import { Boundary } from 'utils/chunker/ContentChunker';

export const BACK_SIZE: Boundary = [3827, 5669];

interface TextileBackComponentProps {
  background?: string;
  children?: React.ReactNode;
}

const disableTextSelect: React.CSSProperties = {
  userSelect: 'none' /* supported by Chrome and Opera */,
  WebkitUserSelect: 'none' /* Safari */,
  KhtmlUserSelect: 'none' /* Konqueror HTML */,
  MozUserSelect: '-moz-none' /* Firefox */
  // MsUserSelect: 'none' /* Internet Explorer/Edge */
};

const defaultTextStyle: React.CSSProperties = {
  letterSpacing: 'normal',
  wordSpacing: 'normal',
  textAlign: 'left',
  fontFamily: config.layout.defaultFont,
  fontWeight: config.layout.defaultWeight,
  fontSize: config.layout.defaultSize,
  lineHeight: config.layout.defaultLineHeight,
  color: config.layout.defaultColor
};

export const TextileBackComponent = ({
  background,
  children
}: TextileBackComponentProps) => {
  const units = 'px';

  const printWidth = BACK_SIZE[0] + units;
  const printHeight = BACK_SIZE[1] + units;

  return (
    <div
      style={{
        ...disableTextSelect,
        ...defaultTextStyle,
        width: printWidth,
        height: printHeight,
        overflow: 'hidden'
      }}
    >
      <div>
        <div
          style={{
            width: printWidth,
            height: printHeight,
            position: 'relative',
            // TODO remove backgroundColor but make sure that page background is always white?
          }}
        >
          {!background ? null : (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: printWidth,
                height: printHeight
              }}
            >
              <img
                src={background}
                alt="background"
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          )}

          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: printWidth,
              height: printHeight
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextileBackComponent;
