import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import InputMessage from 'elements/InputMessage';
import UppercaseHeading from 'elements/UppercaseHeading';
import { useTextileGroupsQuery } from 'queries/textile_deals/useTextileGroupsQueries';
import { useTextileOrderForManagementQuery } from 'queries/textile_deals/useTextileOrderQueries';
import {
  useDeactivateProfilePrintQuery,
  useReactivateProfilePrintQuery,
  useTextileProfileQuery,
  useUpdateTextileProfileQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import isTextileLocked from 'utils/isTextileLocked';

interface FormProps {
  form: FormType;
}

const EditProfileForm: React.FC<HistoryProps & FormProps> = (props) => {
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const textileProfile = useTextileProfileQuery(id, textileOrderId);
  const textileGroups = useTextileGroupsQuery(textileOrderId);
  const textileOrder = useTextileOrderForManagementQuery(textileOrderId);
  const updateTextileProfile = useUpdateTextileProfileQuery();
  const deactivateProfilePrint = useDeactivateProfilePrintQuery(
    textileOrderId,
    id
  );
  const reactivateProfilePrint = useReactivateProfilePrintQuery(
    textileOrderId,
    id
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('management_checkout');
    const data = textileProfile.data;
    const groups = textileGroups.data;
    const appStateOrder = textileAppState?.textile_order;

    if (data && groups && appStateOrder) {
      props.form.setField('first_name', data.first_name || '');
      props.form.setField('last_name', data.last_name || '');
      if (appStateOrder.show_groups) {
        props.form.setField(
          'textile_group_id',
          data.textile_group?.id.toString() || groups[0]?.id.toString() || ''
        );
      }
      if (data.print_state == 'open') {
        props.form.setField('print_state', 'submitted');
      } else {
        props.form.setField('print_state', data.print_state);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessContext,
    textileProfile.data,
    textileGroups.data,
    textileAppState?.textile_order
  ]);

  const renderPage = (content?: React.ReactNode) => {
    if (!textileAppState) {
      return null;
    }

    return (
      <>
        <TopActionBlock>
          <BackLink
            to={
              isTextileLocked(
                textileAppState.textile_order.order_state,
                'atLeastOrderStarted'
              )
                ? textileOrderRoute(props.match, '/checkout')
                : textileOrderRoute(props.match, '/management')
            }
          />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileProfile.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileProfile.refetch()} />
    );
  }

  if (textileGroups.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileGroups.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (
    props.form.loading ||
    !textileAppState ||
    textileProfile.isLoading ||
    !textileProfile.data ||
    textileGroups.isLoading ||
    !textileGroups.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const deactivate = () => {
    const { form, match } = props;

    if (!id || !textileOrderId) {
      return;
    }

    form.setLoading(true);
    deactivateProfilePrint.mutate(
      { id: id, textileOrderId },
      {
        onSettled: () => {
          form.setLoading(false);
          props.history.push(
            isTextileLocked(
              textileAppState.textile_order.order_state,
              'atLeastOrderStarted'
            )
              ? textileOrderRoute(match, '/checkout')
              : textileOrderRoute(match, '/management')
          );
        }
      }
    );
  };

  const reactivate = () => {
    const { form, match } = props;

    if (!id || !textileOrderId) {
      return;
    }

    form.setLoading(true);
    reactivateProfilePrint.mutate(
      { id: id, textileOrderId },
      {
        onSettled: () => {
          form.setLoading(false);
          props.history.push(
            isTextileLocked(
              textileAppState.textile_order.order_state,
              'atLeastOrderStarted'
            )
              ? textileOrderRoute(match, '/checkout')
              : textileOrderRoute(match, '/management')
          );
        }
      }
    );
  };

  const save = () => {
    const { form, match, history } = props;

    if (!id || !textileOrderId) {
      return;
    }

    form.resetErrors();
    form.setLoading(true);

    updateTextileProfile.mutate(
      {
        data: { ...form.values },
        id: id,
        textileOrderId
      },
      {
        onSuccess: () => {
          history.push(
            isTextileLocked(
              textileAppState.textile_order.order_state,
              'atLeastOrderStarted'
            )
              ? textileOrderRoute(match, '/checkout')
              : textileOrderRoute(match, '/management')
          );
        },
        onError: (error: any) => {
          form.setLoading(false);
          if (handleFormError(form, error)) {
            return;
          }
        }
      }
    );
  };

  const renderSaveButton = () => {
    const { form } = props;
    return (
      <>
        {form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  };

  const renderReactivateButton = () => {
    return (
      <ButtonBlock
        background="SECONDARY_DARK"
        color="WHITE"
        onClick={() => reactivate()}
      >
        <FormattedMessage id="Reactivate preorder" />
      </ButtonBlock>
    );
  };

  const renderDeactivateButton = () => {
    return (
      <ButtonBlock
        background="RED_LIGHT"
        color="RED"
        onClick={() => setShowDeactivateModal(true)}
      >
        <FormattedMessage id="Deactivate preorder" />
      </ButtonBlock>
    );
  };

  const renderForm = () => {
    const { form } = props;
    const profile = textileProfile.data;
    const orderData = textileOrder.data;

    const groups: TextileGroup[] = textileGroups.data;
    const showGroups = textileAppState.textile_order.show_groups;

    let extra;
    if (showDeactivateModal) {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="deactivate textile profile print" />}
          text={<FormattedMessage id="deactivate textile profile print info" />}
          confirmText={<FormattedMessage id="Deactivate" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => deactivate()}
          onAbort={() => setShowDeactivateModal(false)}
          confirmColor="RED"
        />
      );
    }

    return renderPage(
      <>
        <PageHeader headline={profile.name} text="" />

        {extra}

        {orderData.organizer_print_data_message && (
          <InputMessage info={true}>
            <Paragraph weight="BOLD" size="L">
              <FormattedMessage id="message from organiser" />
            </Paragraph>

            <Divider color="PURPLE" />

            <Paragraph>{orderData.organizer_print_data_message}</Paragraph>
          </InputMessage>
        )}

        <FormStackBlock>
          <>
            <UppercaseHeading>
              <FormattedMessage id="Print textile name" />
            </UppercaseHeading>

            <ListStackBlock>
              <RadioButtonComponent
                name="print_state"
                value={'submitted'}
                label={<FormattedMessage id="Print name" />}
                {...form.bindRadioButton('print_state', 'submitted')}
              />

              <RadioButtonComponent
                name="print_state"
                value={'no_print'}
                label={<FormattedMessage id="no print name" />}
                {...form.bindRadioButton('print_state', 'no_print')}
              />
            </ListStackBlock>
          </>

          {form.values.print_state === 'submitted' && (
            <>
              <TextInputComponent
                name="first_name"
                label={<FormattedMessage id="First name" />}
                {...form.bindInput('first_name')}
                autoFocus={true}
              />

              <TextInputComponent
                name="last_name"
                label={<FormattedMessage id="Last name" />}
                {...form.bindInput('last_name')}
              />

              {showGroups && groups && groups.length !== 0 && (
                <>
                  <UppercaseHeading>
                    <FormattedMessage id="Groups" />
                  </UppercaseHeading>
                  <ListStackBlock>
                    {groups.map((group: TextileGroup) => (
                      <RadioButtonComponent
                        key={group.id}
                        name="group_id"
                        value={group.id}
                        label={group.name}
                        {...form.bindRadioButton(
                          'textile_group_id',
                          group.id.toString()
                        )}
                      />
                    ))}
                  </ListStackBlock>
                </>
              )}
            </>
          )}
        </FormStackBlock>
        {renderSaveButton()}
        <Divider />
        {renderDeactivateButton()}
      </>
    );
  };

  const renderReactivate = () => {
    return renderPage(
      <>
        <Paragraph size="M" color="RED">
          <FormattedMessage id="Deactivated" />
        </Paragraph>
        <Divider />

        {renderReactivateButton()}
      </>
    );
  };

  if (textileProfile.data.deactivated) {
    return renderReactivate();
  } else {
    return renderForm();
  }
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <EditProfileForm {...props} form={form} />;
};
