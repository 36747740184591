import HelpWidget from 'HelpWidget';
import HeaderComponent from 'components/HeaderComponent/HeaderComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { HasAccessProvider } from 'contexts/HasAccessContext';
import { useTextileAppStateQuery } from 'queries/textile_deals/useTextileAppStateQueries';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import TextileLegacyScreen from 'screens/account/TextileLegacyScreen';
import JoinTextileScreen from 'screens/join/JoinTextileScreen';
import TextileOrderRouter from 'screens/order/screens/textiles/TextileOrderRouter';
import PreOrderRouter from 'screens/preorders/PreOrderRouter';
import TextileCalculatorRouter from 'screens/textile_calculator/TextileCalculatorRouter';
import TextileCheckoutRouter from 'screens/textile_checkout/TextileCheckoutRouter';
import AccountScreen from 'screens/textile_dashboard/AccountScreen';
import TextileDashboardScreen from 'screens/textile_dashboard/TextileDashboardScreen';
import TextileGroupsRouter from 'screens/textile_design_groups/TextileGroupsRouter';
import TextileDesignSettingsRouter from 'screens/textile_design_settings/TextileDesignSettingsRouter';
import TextileManagementRouter from 'screens/textile_management/TextileManagementRouter';
import TextileOnboardingRouter from 'screens/textile_onboarding/TextileOnboardingRouter';
import TextileProfilesRouter from 'screens/textile_profiles/TextileProfilesRouter';
import ROLES from 'utils/constants/roles';
import {
  ROUTE_LOGIN,
  ROUTE_TEXTILE_CALCULATOR,
  ROUTE_TEXTILE_CHECKOUT,
  ROUTE_TEXTILE_DESIGN,
  ROUTE_TEXTILE_DESIGN_GROUPS,
  ROUTE_TEXTILE_MANAGEMENT,
  ROUTE_TEXTILE_ONBOARDING,
  ROUTE_TEXTILE_ORDER,
  ROUTE_TEXTILE_ORDER_OVERVIEW,
  ROUTE_TEXTILE_PREORDER,
  ROUTE_TEXTILE_PROFILES
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const TextileRoutes: React.FC<HistoryProps> = ({ match, history }) => {
  const textileOrderId = textileOrderIdFromMatch(match);
  const { data, isError, isLoading } = useTextileAppStateQuery(textileOrderId);

  useEffect(() => {
    if (isError) {
      history.push(ROUTE_LOGIN);
    }
  }, [isError, history]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (data?.textile_order.is_legacy) {
      const isManagerOrOrganizer =
        data.textile_profile.is_manager || data.textile_profile.is_organizer;

      const targetPath = isManagerOrOrganizer
        ? textileOrderRoute(match, '/order/placed/legacy')
        : textileOrderRoute(match, '/is_legacy');

      if (history.location.pathname !== targetPath) {
        history.push(targetPath);
      }
    }
  }, [data, isLoading, history, match]);

  if (isLoading) {
    return (
      <>
        <HeaderComponent />
        <LoadingOverlayComponent />
      </>
    );
  }

  if (!textileOrderId) {
    return null;
  }

  return (
    <>
      <HasAccessProvider textileAppState={data} history={history} match={match}>
        <Switch>
          <Route
            path={ROUTE_TEXTILE_ORDER + '/join_textile'}
            component={JoinTextileScreen}
          />

          <Route
            path={ROUTE_TEXTILE_ORDER + '/is_legacy'}
            component={TextileLegacyScreen}
          />
          <Route
            path={ROUTE_TEXTILE_ONBOARDING}
            component={TextileOnboardingRouter}
          />

          <Route
            path={ROUTE_TEXTILE_ORDER + '/account'}
            component={AccountScreen}
          />

          <Route
            path={ROUTE_TEXTILE_DESIGN}
            component={TextileDesignSettingsRouter}
          />

          <Route
            path={ROUTE_TEXTILE_DESIGN_GROUPS}
            component={TextileGroupsRouter}
          />

          <Route
            path={ROUTE_TEXTILE_MANAGEMENT}
            component={TextileManagementRouter}
          />

          <Route
            path={ROUTE_TEXTILE_PROFILES}
            component={TextileProfilesRouter}
          />

          <Route path={ROUTE_TEXTILE_PREORDER} component={PreOrderRouter} />

          <Route
            path={ROUTE_TEXTILE_ORDER_OVERVIEW}
            component={TextileOrderRouter}
          />

          <Route
            path={ROUTE_TEXTILE_CALCULATOR}
            component={TextileCalculatorRouter}
          />

          <Route
            path={ROUTE_TEXTILE_CHECKOUT}
            component={TextileCheckoutRouter}
          />

          <Route
            path={ROUTE_TEXTILE_ORDER}
            exact={true}
            component={TextileDashboardScreen}
          />

          <NotFoundRoute />
        </Switch>
      </HasAccessProvider>
      <HelpWidget
        isClosed={data?.textile_order.is_closed}
        role={
          data?.textile_profile.is_manager
            ? ROLES.MANAGER
            : data?.textile_profile.is_organizer
            ? ROLES.ORGANIZER
            : ROLES.STUDENT
        }
      />
    </>
  );
};

export default TextileRoutes;