import React from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import {
  ROUTE_TEXTILE_MANAGEMENT
} from 'utils/constants/routes';
import ManageTextileProfilesScreen from './ManageTextileProfilesScreen';
import EditProfileForm from './screens/EditProfileForm';
import OverviewScreen from './screens/OverviewScreen';
import PrintDataMessageFormScreen from './screens/PrintDataMessageFormScreen';
import PrintOrderScreen from './screens/PrintOrderScreen';

import AddTeacherTextileName from './screens/textile_names/AddTeacherTextileName';
import AddVirtualTextileName from './screens/textile_names/AddVirtualTextileName';
import EditTeacherTextileName from './screens/textile_names/EditTeacherTextileName';
import EditVirtualTextileName from './screens/textile_names/EditVirtualTextileName';
import TeacherOverviewScreen from './screens/textile_names/TeacherOverviewScreen';

export default () => (
  <Switch>
    {/* Routes virtual textile profile */}
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/virtual/add'}
      component={AddVirtualTextileName}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/virtual/edit/:id'}
      component={EditVirtualTextileName}
    />

    {/* Routes virtual textile teachers */}
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/teachers/add'}
      component={AddTeacherTextileName}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/teachers/edit/:id'}
      component={EditTeacherTextileName}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/teachers'}
      component={TeacherOverviewScreen}
    />

    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/overview/:id'}
      component={OverviewScreen}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/edit/:id'}
      component={EditProfileForm}
    />

    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/print_data_message'}
      component={PrintDataMessageFormScreen}
    />

    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/print_order'}
      component={PrintOrderScreen}
    />

    <Route
      path={ROUTE_TEXTILE_MANAGEMENT}
      component={ManageTextileProfilesScreen}
      exact={true}
    />
    <NotFoundRoute />
  </Switch>
);
