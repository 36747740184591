import React from 'react';

import { Textile } from 'api/textile_deals/fetchTextiles';
import ItemStackBlock from 'blocks/ItemStackBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import InputMessage from 'elements/InputMessage';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { FormType } from 'utils/hooks/useForm';
import { sanitizeNumberInput } from 'utils/sanitize_number_input';

interface CalculatorFormProps {
  form: FormType;
  errors?: string[];
  textiles: Textile[];
  match: any;
}

const TextileCalculatorForm = ({
  form,
  textiles,
  errors,
  match
}: CalculatorFormProps) => {
  const errorMessages = errors;

  return (
    <>
      <FormStackBlock>
        <TextInputComponent
          name="amount"
          type="number"
          label={<FormattedMessage id="textile count" />}
          {...form.bindInput('amount')}
          value={form.values['amount']}
          min="20"
          step="1"
          onKeyDown={(event: { key: string; preventDefault: () => any; }) => sanitizeNumberInput(event)}
        />

        <ItemStackBlock gap="XXS">
          <Paragraph size="M">
            <FormattedMessage id="textile model label" />
          </Paragraph>
          {textiles.map((textile) => {
            if (!textile.possible_price) {
              return null;
            }

            return (
              <RadioButtonComponent
                key={textile.id}
                name="selected_textile_id"
                value={textile.id}
                label={textile.name}
                info={
                  <TextElement>
                    <FormattedMessage
                      id="textile from price"
                      values={{
                        price: (
                          <FormattedNumber
                            value={textile.possible_price}
                            style="currency"
                            currency="EUR"
                          />
                        )
                      }}
                    />
                  </TextElement>
                }
                {...form.bindRadioButton(
                  'selected_textile_id',
                  textile.id.toString()
                )}
              />
            );
          })}
        </ItemStackBlock>
      </FormStackBlock>

      {errorMessages && (
        <InputMessage error={true}>{[...errorMessages!]}</InputMessage>
      )}
    </>
  );
};

export default TextileCalculatorForm;
