import React from 'react';

import { BaseTextileDesignSetting } from 'api/textile_deals/fetchTextileDesignSetting';
import { TextileVariant } from 'api/textile_deals/fetchTextileVariants';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import FontsPreloader, { Fonts } from 'components/print/FontsPreloader';
import CoverPreviewComponent from 'components/print/layout-editor/CoverPreviewComponent';
import ProfilePreviewComponent from 'components/print/layout-editor/ProfilePreviewComponent';
import QuotesPreviewComponent from 'components/print/layout-editor/QuotesPreviewComponent';
import RankingsPreviewComponent from 'components/print/layout-editor/RankingsPreviewComponent';
import TextPreviewComponent from 'components/print/layout-editor/TextPreviewComponent';
import appConfig from 'config';
import { ChapterType } from 'models/ChapterTypeEnum';
import {
  ColorSchemeModelType,
  createColorSchemeModel
} from 'models/print/ColorSchemeModel';
import { FontModelType, createFontModel } from 'models/print/FontModel';
import { LayoutModelType, createLayoutModel } from 'models/print/LayoutModel';
import TextileBackGeneratedPreviewComponent from 'screens/preorders/components/TextileBackGeneratedPreviewComponent';
import TextileProductImageComponent from 'screens/preorders/components/TextileProductImageComponent';
import { CustomBackground } from 'utils/chunker/replace-variables';
import { HistoryProps } from 'utils/history';
import { LayoutPreviewPageBorder, LayoutPreviewPrintBorder } from './elements';

type TextilePreviewType = 'textile-back' | 'textile-front';
type LayoutPreviewType = ChapterType | TextilePreviewType;

interface State {
  loadingState?: 'loading' | 'error';
  message?: string;
  background?: string;
  font?: FontModelType;
  color?: ColorSchemeModelType;
  layout?: LayoutModelType;
  textileVariants?: TextileVariant[];
  textileSettings?: BaseTextileDesignSetting;
  textileBackUrl?: string;
  textileFrontUrl?: string;
}

class LayoutPreviewContainer extends React.Component<HistoryProps, State> {
  state: State = {
    loadingState: 'loading'
  };

  private isURLAllowed(url: string) {
    return Boolean(
      url.match(/^https?:\/\/(localhost|127\.0\.0\.1)(:[0-9]+)?\//i) ||
        url.match(/^https?:\/\/([a-z0-9.-]*\.)?nuggit\.de(:[0-9]+)?\//i)
    );
  }

  private async loadJson() {
    const url = this.props.location.query?.url;
    if (!url) {
      this.setState({
        loadingState: 'error',
        message: 'URL missing'
      });
      return;
    }
    if (!this.isURLAllowed(url.toString())) {
      this.setState({
        loadingState: 'error',
        message: 'URL not allowed'
      });
      return;
    }

    try {
      const response = await fetch(url);
      const json = await response.json();

      this.setState({
        loadingState: undefined,
        // book layouts
        background: json.background || undefined,
        font: createFontModel(json.font),
        color: createColorSchemeModel(json.color),
        layout: createLayoutModel(json.layout),
        // textile layouts
        textileSettings: json.textile_settings,
        textileVariants: json.textile_variants,
        textileBackUrl: json.textile_back_url || undefined,
        textileFrontUrl: json.textile_front_url || undefined
      });
    } catch (e) {
      console.error('Could not load layout:', e);
      this.setState({
        loadingState: 'error',
        message: 'Could not load layout'
      });
    }
  }

  componentDidMount() {
    this.loadJson();
  }

  private getPreloadFonts() {
    const { font, layout } = this.state;
    return (font ? font.specs : [])
      .concat((appConfig.layout.defaultFonts as Fonts) || [])
      .concat(layout?.fontSpecs || []);
  }

  private isPrintBorderVisible(): boolean {
    return this.props.location.query?.border === 'true';
  }

  private renderPreview(type: ChapterType) {
    const { background, font, color, layout } = this.state;

    const preloadFonts = this.getPreloadFonts();

    const customBackground: CustomBackground | undefined = background
      ? {
          printLeft: background,
          previewLeft: background,
          printRight: background,
          previewRight: background
        }
      : undefined;

    const isBorderVisible = this.isPrintBorderVisible();
    return (
      <FontsPreloader fonts={preloadFonts} print={false}>
        {type === 'cover' ? (
          <div style={{ width: '1780px' }}>
            <HorizontalStackBlock noMarginLeft noMarginRight justified>
              <CoverPreviewComponent
                print={false}
                config={layout!.layoutDefinition}
                title="Layout Preview"
                header="Header Preview"
                footer="Footer Preview"
                subtitle="This Is The Subtitle"
                spineText="Spine Text Preview"
                forceNames={this.props.location.query?.names === 'true'}
              />
            </HorizontalStackBlock>
          </div>
        ) : (
          <LayoutPreviewPageBorder
            visible={isBorderVisible}
            margin={isBorderVisible}
          >
            {type === 'factsheet' && (
              <ProfilePreviewComponent
                print={true}
                firstPageOnly={false}
                config={layout!.layoutDefinition}
                title="Layout Preview"
                background={customBackground}
                colors={color?.templateColors}
                pageHeaderStyle={font?.pageHeaderStyle}
                sectionHeaderStyle={font?.sectionHeaderStyle}
              />
            )}

            {type === 'ranking' && (
              <RankingsPreviewComponent
                print={true}
                firstPageOnly={true}
                config={layout!.layoutDefinition}
                title="Layout Preview"
                background={customBackground}
                colors={color?.templateColors}
                pageHeaderStyle={font?.pageHeaderStyle}
                sectionHeaderStyle={font?.sectionHeaderStyle}
              />
            )}

            {type === 'quote' && (
              <QuotesPreviewComponent
                print={true}
                firstPageOnly={true}
                config={layout!.layoutDefinition}
                title="Layout Preview"
                background={customBackground}
                colors={color?.templateColors}
                pageHeaderStyle={font?.pageHeaderStyle}
                sectionHeaderStyle={font?.sectionHeaderStyle}
              />
            )}

            {type === 'text' && (
              <>
                <TextPreviewComponent
                  print={true}
                  firstPageOnly={false}
                  config={layout!.layoutDefinition}
                  title="Layout Preview"
                  background={customBackground}
                  colors={color?.templateColors}
                  pageHeaderStyle={font?.pageHeaderStyle}
                  sectionHeaderStyle={font?.sectionHeaderStyle}
                  long={true}
                />
                <TextPreviewComponent
                  start="right"
                  print={true}
                  firstPageOnly={false}
                  config={layout!.layoutDefinition}
                  title="Layout Preview"
                  background={customBackground}
                  colors={color?.templateColors}
                  pageHeaderStyle={font?.pageHeaderStyle}
                  sectionHeaderStyle={font?.sectionHeaderStyle}
                  long={true}
                />
              </>
            )}

            {isBorderVisible && <LayoutPreviewPrintBorder />}
          </LayoutPreviewPageBorder>
        )}
      </FontsPreloader>
    );
  }

  private renderTextilePreview(type: TextilePreviewType) {
    const {
      textileSettings,
      textileVariants,
      textileBackUrl,
      textileFrontUrl
    } = this.state;
    if (!textileSettings || !textileSettings.theme || !textileVariants || !textileSettings.layout_key) {
      return <p>Error: Missing layout or props</p>;
    }

    if (type === 'textile-back') {
      const variant = textileVariants[0];

      if (!variant) {
        return null;
      }

      return (
        <TextileBackGeneratedPreviewComponent
          key={variant.id}
          bottom={variant.back_preview_bottom || ''}
          settings={textileSettings}
          previewBackground={textileBackUrl}
          width="100%"
          useFakeData={true}
          textileType={variant.textile_internal_identifier}
        />
      );
    } else if (type === 'textile-front') {
      const variant = textileVariants[0];

      if (!variant || !variant.front_preview_bottom) {
        return null;
      }

      return <TextileProductImageComponent image={variant.front_preview_bottom} textileType={variant.textile_internal_identifier} previewFront={textileFrontUrl}/>;
    } else {
      return null;
    }
  }

  render() {
    const { loadingState, message } = this.state;

    if (loadingState === 'error') {
      return <p>Error: {message || 'Unknown'}</p>;
    }
    if (loadingState === 'loading') {
      return <p>Preparing preview...</p>;
    }

    const type =
      (this.props.match.params.type as LayoutPreviewType) || 'unknown';
    switch (type) {
      case 'cover':
      case 'factsheet':
      case 'ranking':
      case 'quote':
      case 'text':
        return this.renderPreview(type);

      case 'textile-back':
        return this.renderTextilePreview(type);

      case 'textile-front':
        return this.renderTextilePreview(type);

      default:
        return <p>Error: Layout type not supported</p>;
    }
  }
}

export default LayoutPreviewContainer;
