import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import UppercaseHeading from 'elements/UppercaseHeading';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormType } from 'utils/hooks/useForm';

interface PreorderPreferenceComponentProps {
  form: FormType;
}

const PreorderPreferenceComponent: React.FC<PreorderPreferenceComponentProps> = ({
  form
}) => {
  return (
    <FormStackBlock>
      <UppercaseHeading>
        <FormattedMessage id="Order" />
      </UppercaseHeading>

      <RadioButtonComponent
        name="preorder_state"
        value={'submitted'}
        label={<FormattedMessage id="order textile" />}
        {...form.bindRadioButton('preorder_state', 'submitted')}
      />

      <RadioButtonComponent
        name="preorder_state"
        value={'no_order'}
        label={<FormattedMessage id="no order textile" />}
        {...form.bindRadioButton('preorder_state', 'no_order')}
      />
    </FormStackBlock>
  );
};

export default PreorderPreferenceComponent;
