import { get } from 'api/BaseApi';

export interface TextileVariant {
  id: number;
  textile_id: number;
  textile_name: string;
  textile_size_info: string;
  textile_internal_identifier: string;
  internal_identifier: string;
  inactive: boolean;
  textile_color?: TextileColor;
  textile_sizes?: TextileSize[];
  front_preview_bottom?: string;
  back_preview_bottom?: string;
}

export interface TextileColor {
  id: number;
  name: string;
  display_name: string;
  color_hex: string;
}

export interface TextileSize {
  id: number;
  title: string;
}

export const getTextileVariantsForTextileOrder = (
  textileOrderId: number
): Promise<TextileVariant[]> => {
  return get(
    '/textile_variants?textile_order_id=' + textileOrderId
  );
};

export const getTextileVariantByTextile = (
  id: number
): Promise<TextileVariant[]> => {
  return get('/textile_variants/variants_by_textile?textile_id=' + id);
};
