import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import TextilePreorderProfilesList from 'domain/textileStudentList/TextilePreorderProfilesList';
import NakedLink from 'elements/NakedLink';
import { useSelectedTextileVariantQuery } from 'queries/textile_deals/useSelectedTextileVariantQueries';
import {
  useStartPreorderQuery,
  useTextileOrderForPreorderQuery
} from 'queries/textile_deals/useTextileOrderQueries';
import { useTextilePreorderProfilesListQuery } from 'queries/textile_deals/useTextilePreorderQueries';
import { useCurrentTextileProfileQuery } from 'queries/textile_deals/useTextileProfileQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import PreorderToggleScreen from './screens/PreorderToggleScreen';

const PreorderManagementScreen: React.FC<HistoryProps> = (
  props
) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const accessContext = useContext(HasAccessContext);
  const textileProfiles = useTextilePreorderProfilesListQuery(textileOrderId);
  const currentTextileProfile = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );
  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const startPreorderQuery = useStartPreorderQuery(textileOrderId);
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }
  const selectedTextileVariant = useSelectedTextileVariantQuery(textileOrderId);

  useEffect(() => {
    accessContext.checkSectionAccess('preorder');
  }, [accessContext]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (selectedTextileVariant.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => selectedTextileVariant.refetch()}
      />
    );
  }

  if (textileProfiles.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileProfiles.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (currentTextileProfile.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => currentTextileProfile.refetch()}
      />
    );
  }

  if (
    !textileAppState ||
    textileProfiles.isLoading ||
    !textileProfiles.data ||
    textileOrder.isLoading ||
    !textileOrder.data ||
    currentTextileProfile.isLoading ||
    !currentTextileProfile.data ||
    selectedTextileVariant.isLoading ||
    !selectedTextileVariant.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const startPreorder = () => {
    const { history, match } = props;

    if (!textileOrderId) {
      return;
    }

    startPreorderQuery.mutate(textileOrderId, {
      onError: () => {
        history.push(textileOrderRoute(match, ''));
      }
    });
  };

  const renderStartPreorder = () => {
    const { history } = props;

    return (
      <>
        <PageStackBlock>
          <ConfirmBoxComponent
            header={<FormattedMessage id="confirm start preorder" />}
            text={<FormattedMessage id="confirm start preorder info" />}
            confirmText={<FormattedMessage id="Start" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => startPreorder()}
            onAbort={() => history.push(textileOrderRoute(props.match, ''))}
            confirmColor="PRIMARY"
          />
        </PageStackBlock>
      </>
    );
  };

  const renderOverview = () => {
    const orderData = textileOrder.data;
    const selectedVariant = selectedTextileVariant.data;

    if (textileAppState.textile_order.order_state === 'preparing') {
      return renderStartPreorder();
    }

    if (!selectedVariant) {
      return null;
    }

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Preorders" />}
          text={<FormattedMessage id="Preorder info" />}
        />

        <PreorderToggleScreen textileOrderId={orderData.id} />

        <ButtonListBlock>
          <ButtonListBlockItem>
            <NakedLink
              to={textileOrderRoute(props.match, '/preorder/preorder_message')}
            >
              <ButtonListBlockBody>
                <FormattedMessage id="notification header" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>
        </ButtonListBlock>

        <NakedLink to={textileOrderRoute(props.match, `/preorder/virtual/add`)}>
          <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK">
            <FormattedMessage id="preorder add manuell" />
          </ButtonBlock>
        </NakedLink>

        <Divider />

        <TextilePreorderProfilesList
          textileOrderId={textileOrderIdFromMatch(props.match)}
          onProfileClick={(
            virtual: boolean,
            preorderId?: number,
            profileId?: number
          ) => {
            if (!preorderId) {
              props.history.push(
                textileOrderRoute(
                  props.match,
                  `/preorder/profile/add/${profileId}`
                )
              );
            } else {
              if (virtual) {
                props.history.push(
                  textileOrderRoute(
                    props.match,
                    `/preorder/virtual/edit/${preorderId}`
                  )
                );
              } else {
                props.history.push(
                  textileOrderRoute(
                    props.match,
                    `/preorder/profile/edit/${preorderId}`
                  )
                );
              }
            }
          }}
          profiles={textileProfiles.data}
        />
      </>
    );
  };

  return renderOverview();
};

export default PreorderManagementScreen;
