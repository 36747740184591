import React, { JSXElementConstructor, MouseEvent } from 'react';

import {
  EveryCard,
  EveryCardBody,
  EveryCardFooter,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';

import {
  StudentType,
  TextileStudent
} from 'api/textile_deals/fetchStudentList';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';
import { FormattedMessage } from 'react-intl';
import { CardTag } from 'screens/settings/screens/AccountScreen/ManageStudentsListItem';

export interface TextileProfileListItemProps {
  profile: TextileStudent;
  onClick?: (e: MouseEvent, studentId: number, type?: StudentType) => void;
}

export type TextileProfileListItemType = JSXElementConstructor<
  TextileProfileListItemProps
>;

export const TextileProfileListItem: TextileProfileListItemType = ({
  profile,
  onClick
}: TextileProfileListItemProps) => (
  <EveryCard
    as={onClick && 'button'}
    onClick={
      onClick && ((e: MouseEvent) => onClick(e, profile.id, profile.type))
    }
  >
    <EveryCardPadding>
      <EveryCardBody>
        <EveryCardHeadline>{profile.name || profile.id}</EveryCardHeadline>

        <TextElement>
          {profile && profile.textile_group ? (
            profile.textile_group.name
          ) : (
            <FormattedMessage id="No group" />
          )}
        </TextElement>
      </EveryCardBody>

      <CardTag
        type={
          profile.type && profile.type === 'textile_name'
            ? 'manuel'
            : profile.type === 'textile_profile' && profile.organizer
            ? 'organizer'
            : undefined
        }
      />
    </EveryCardPadding>
    {profile.type === 'textile_profile' && (
      <>
        {profile.print_state === 'open' ? (
          <EveryCardFooter textStart={true} background="PRIMARY_LIGHT">
            <TextElement color="BLACK">
              <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />
              <FormattedMessage id="TBD" />
            </TextElement>
          </EveryCardFooter>
        ) : profile.deactivated ? (
          <EveryCardFooter textStart={true}>
            <TextElement color="BLACK">
              <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
              <FormattedMessage id="Deactivated" />
            </TextElement>
          </EveryCardFooter>
        ) : (
          ''
        )}
      </>
    )}
  </EveryCard>
);

export default TextileProfileListItem;
