import React, { useContext, useEffect, useState } from 'react';

import { BaseTextileDesignSetting } from 'api/textile_deals/fetchTextileDesignSetting';
import { FullName } from 'api/textile_deals/fetchTextileGroups';
import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import CardBlock from 'blocks/CardBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import WideBlock from 'blocks/WideBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import UppercaseHeading from 'elements/UppercaseHeading';
import { useSelectedTextileVariantQuery } from 'queries/textile_deals/useSelectedTextileVariantQueries';
import { useTextileNamesBackQuery } from 'queries/textile_deals/useTextileBackNamesQueries';
import { useTextileDesignSettingQuery } from 'queries/textile_deals/useTextileDesignSettingQueries';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import TextileBackGeneratedPreviewComponent from 'screens/preorders/components/TextileBackGeneratedPreviewComponent';
import { getTextileBackCreativePhoto } from 'utils/getTextileBackCreativePhoto';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import {
  sortByFirstNameThenLastName,
  sortByLastNameThenFirstName
} from 'utils/sort-functions';

const TextileDesignSettingsBack: React.FC<HistoryProps> = (props) => {
  const [useFakeData, setUseFakeData] = useState<boolean>(true);
  const textileOrderId = textileOrderIdFromMatch(props.match);

  const textileDesignSetting = useTextileDesignSettingQuery(textileOrderId);
  const selectedTextileVariant = useSelectedTextileVariantQuery(textileOrderId);
  const textileBackNames = useTextileNamesBackQuery(textileOrderId);

  const accessContext = useContext(HasAccessContext);
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('design');
    const data = textileDesignSetting.data;

    if (data) {
      const hasRequiredSettings = data.layout_key && data.theme;
      if (!hasRequiredSettings) {
        const route = textileOrderRoute(props.match, '/textile_design/layout');
        props.history.push(route);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileDesignSetting.data]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/textile_design')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="textile design back" />}
            text={<FormattedMessage id="textile design edit" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileDesignSetting.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => textileDesignSetting.refetch()}
      />
    );
  }

  if (selectedTextileVariant.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => selectedTextileVariant.refetch()}
      />
    );
  }

  if (textileBackNames.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileBackNames.refetch()} />
    );
  }

  if (
    textileDesignSetting.isLoading ||
    !textileDesignSetting.data ||
    selectedTextileVariant.isLoading ||
    !selectedTextileVariant.data ||
    textileBackNames.isLoading ||
    !textileBackNames.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const studentsAndTeachers = (
    sorting?: 'first_name' | 'last_name'
  ): { students: FullName[]; teachers: FullName[] } => {
    const students: FullName[] = [];
    const teachers: FullName[] = [];
    const settings = textileDesignSetting.data;

    if (!textileBackNames.data) {
      return { students, teachers };
    }

    if (settings.show_groups && textileBackNames.data.groups) {
      textileBackNames.data.groups.forEach((group) => {
        students.push(...group.student_names);
        teachers.push(...group.teacher_names);
      });
    } else {
      students.push(...textileBackNames.data.student_names);
      teachers.push(...textileBackNames.data.teacher_names);
    }

    if (sorting && sorting === 'first_name') {
      students.sort(sortByFirstNameThenLastName);
      teachers.sort(sortByFirstNameThenLastName);
    } else {
      students.sort(sortByLastNameThenFirstName);
      teachers.sort(sortByLastNameThenFirstName);
    }

    return {
      students,
      teachers
    };
  };

  const renderOptions = () => {
    const settings = textileDesignSetting.data;
    if (!settings) {
      return null;
    }

    const { students, teachers } = studentsAndTeachers(settings.names_order);

    const backDesign = settings.textile_back || 'neutral';

    const groups = textileBackNames.data.groups;
    const layout = settings?.theme;
    const font = settings?.font;
    const background = settings?.background;
    const selectedVariant = selectedTextileVariant.data;

    if (!layout || !font || !background) {
      return null;
    }

    let studentNames: FullName[] = [];
    if (students) {
      students.forEach((student: FullName) => {
        studentNames.push({
          first_name: student.first_name,
          last_name: student.last_name
        });
      });
    }

    let teacherNames: FullName[] = [];
    if (teachers) {
      teachers.forEach((teacher: FullName) => {
        teacherNames.push({
          first_name: teacher.first_name,
          last_name: teacher.last_name
        });
      });
    }

    return renderPage(
      <PageStackBlock>
        <WideBlock padded={true}>
          <ItemStackBlock gap="S">
            <UppercaseHeading>
              <FormattedMessage id="Design preview" />
            </UppercaseHeading>

            <>
              <TextileBackGeneratedPreviewComponent
                key={selectedVariant.id}
                bottom={selectedVariant.back_preview_bottom || ''}
                students={studentNames}
                teachers={teacherNames}
                useFakeData={useFakeData}
                settings={settings}
                groups={groups}
                textileType={selectedVariant.textile_internal_identifier}
              />
            </>

            {settings.textile_back === 'generated' && (
              <>
                <CustomCheckboxBlock>
                  <CustomCheckboxBlock.CheckboxElement
                    id="show_groups"
                    value={useFakeData}
                    onChange={() => setUseFakeData(!useFakeData)}
                  />
                  <CustomCheckboxBlock.LabelElement htmlFor="show_groups">
                    <FormattedMessage id="textile back preview use real" />
                  </CustomCheckboxBlock.LabelElement>
                  {useFakeData && (
                    <TextElement>
                      <FormattedMessage id="textile back preview use real info" />
                    </TextElement>
                  )}
                </CustomCheckboxBlock>
              </>
            )}
          </ItemStackBlock>
        </WideBlock>

        <Link
          to={textileOrderRoute(props.match, '/textile_design/back/design')}
        >
          <CardBlock>
            <PaddingBlock arrow={true}>
              <Headline.Small color="GRAY10">
                <FormattedMessage id="textile design back option" />
              </Headline.Small>
              <TextElement>
                <FormattedMessage id={'textile back design ' + backDesign} />
              </TextElement>
            </PaddingBlock>
          </CardBlock>
        </Link>

        <Divider />

        {renderDesignSettings(settings)}
      </PageStackBlock>
    );
  };

  const renderDesignSettings = (settings: BaseTextileDesignSetting) => {
    const backDesign = settings.textile_back || 'neutral';

    const textileBackCreativePhoto = getTextileBackCreativePhoto(settings);

    if (backDesign === 'neutral') {
      return (
        <>
          <ItemStackBlock gap="XXS">
            <Headline.Medium>
              <FormattedMessage id={'textile back design ' + backDesign} />
            </Headline.Medium>
            <Paragraph color="TEXT_LIGHT">
              <FormattedMessage id="textile back print no design" />
            </Paragraph>
          </ItemStackBlock>

          <ButtonListBlock>
            <ButtonListBlockItem>
              <NakedLink
                to={textileOrderRoute(
                  props.match,
                  '/textile_design/back/frame'
                )}
              >
                <ButtonListBlockBody>
                  <FormattedMessage id="Frame" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>
          </ButtonListBlock>
        </>
      );
    }

    if (backDesign === 'generated') {
      return (
        <>
          <ItemStackBlock gap="S">
            <Headline.Medium>
              <FormattedMessage id="textile back generated" />
            </Headline.Medium>
            <FormattedMessage id="textile back generated description" />
          </ItemStackBlock>
          <ButtonListBlock>
            <ButtonListBlockItem>
              <NakedLink
                to={textileOrderRoute(
                  props.match,
                  '/textile_design/back/header'
                )}
              >
                <ButtonListBlockBody>
                  <FormattedMessage id="Headlines" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            <ButtonListBlockItem>
              <NakedLink
                to={textileOrderRoute(
                  props.match,
                  '/textile_design/back/frame'
                )}
              >
                <ButtonListBlockBody>
                  <FormattedMessage id="Frame" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            <ButtonListBlockItem>
              <NakedLink to={textileOrderRoute(props.match, '/groups')}>
                <ButtonListBlockBody>
                  <FormattedMessage id="textile back groups" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            <ButtonListBlockItem>
              <NakedLink
                to={textileOrderRoute(props.match, '/management/teachers')}
              >
                <ButtonListBlockBody>
                  <FormattedMessage id="textile back teacher names" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>
          </ButtonListBlock>
        </>
      );
    }

    if (backDesign === 'photo') {
      return (
        <>
          <ItemStackBlock gap="XXS">
            <Headline.Medium>
              <FormattedMessage id="textile back design creative" />
            </Headline.Medium>
            <Paragraph color="TEXT_LIGHT">
              <FormattedMessage id="textile back design creative info" />
            </Paragraph>
          </ItemStackBlock>
          <ItemStackBlock gap="XS">
            <Link
              to={textileOrderRoute(
                props.match,
                '/textile_design/back/creative'
              )}
            >
              <CardBlock>
                <PaddingBlock arrow={true}>
                  <Headline.Small color="GRAY10">
                    <FormattedMessage id="textile back design creative" />
                  </Headline.Small>
                  <TextElement>
                    {textileBackCreativePhoto ? (
                      textileBackCreativePhoto.photo ? (
                        <FormattedMessage id="Edit photo" />
                      ) : (
                        <>
                          <IconComponent icon="ARROW_ROTATE" fill="GREEN" />{' '}
                          <FormattedMessage id="Photo is being processed" />
                        </>
                      )
                    ) : (
                      <FormattedMessage id="Add photo" />
                    )}
                  </TextElement>
                </PaddingBlock>
              </CardBlock>
            </Link>
            <TextElement color="TEXT_LIGHT">
              <FormattedMessage id="textile back design creative hint" />
            </TextElement>
          </ItemStackBlock>
        </>
      );
    }
  };

  return renderOptions();
};

export default TextileDesignSettingsBack;
