interface imageOffsets {
  top: string;
  left: string;
  height: string;
  width: string;
}

export const layoutImageOffsetsByTextile = (
  textileType: string,
  side: 'front' | 'back'
): imageOffsets => {
  switch (textileType) {
    case 'classicCollegeHoodie':
      if (side === 'front') {
        return {
          top: '25.11%',
          left: '32.82%',
          height: '24.93%',
          width: '34.23%'
        };
      } else {
        return {
          top: '23.51%',
          left: '31.75%',
          height: '42.39%',
          width: '36.39%'
        };
      }
    case 'premiumOversizedHoodie':
      if (side === 'front') {
        return {
          top: '22.71%',
          left: '32.82%',
          height: '24.93%',
          width: '34.23%'
        };
      } else {
        return {
          top: '19.94%',
          left: '31.54%',
          height: '43.01%',
          width: '36.96%'
        };
      }
    case 'classicShirt':
      if (side === 'front') {
        return {
          top: '21.99%',
          left: '32.82%',
          height: '24.93%',
          width: '34.23%'
        };
      } else {
        return {
          top: '19.24%',
          left: '33.76%',
          height: '37.57%',
          width: '32.31%'
        };
      }
    default:
      return {
        top: '0',
        left: '0',
        height: '100%',
        width: '100%'
      };
  }
};
