import { get } from 'api/BaseApi';
import { PreorderState } from './fetchPreorders';
import { TextileProfilePrintState } from './fetchTextileProfiles';

export interface TextileOrganizerDashboard {
  preorders_amount: number;
  layout_key: string
}

export interface TextileStudentDashboard {
  first_name: string;
  is_checkout_author: boolean;
  preorder_state: PreorderState;
  requested_organizer_role: boolean;
  textile_preorder_id: number;
  print_state: TextileProfilePrintState;
}
export const getTextileOrganizerDashboard = (
  textileOrderId: number
): Promise<TextileOrganizerDashboard> => {
  return get(
    '/textile_dashboard/organizer?textile_order_id=' + textileOrderId
  );
};

export const getTextileStudentDashboard = (
  textileOrderId: number
): Promise<TextileStudentDashboard> => {
  return get(
    '/textile_dashboard/student?textile_order_id=' + textileOrderId
  );
};
