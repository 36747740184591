import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TextileProfilePrintState } from 'api/textile_deals/fetchTextileProfiles';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import {
  IconNameType,
  UppercaseIconNameType
} from 'components/IconComponent/svg-sprites-info';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import COLORS from 'utils/constants/colors';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import TextileDashboardArrowComponent from '../components/TextileDashboardArrowComponent';

export const TextileMyProfileBlockComponent = ({
  textileOrderId,
  locked,
  printDataOpen,
  printState
}: {
  textileOrderId: number;
  locked: boolean;
  printDataOpen: boolean;
  printState: TextileProfilePrintState;
}) => {
  if (locked || (!printDataOpen && printState !== 'submitted')) {
    return (
      <HorizontalStackBlock justified={true} gap="XS" centered={true}>
        <IconComponent
          icon="MULTIPLE_STUDENTS"
          roundBackground="GRAY950"
          size={3.5}
          square={true}
          fill="GRAY800"
        />
        <ItemStackBlock gap="XXS">
          <Headline.Small color="GRAY560">
            <FormattedMessage id="Textile name student" />
          </Headline.Small>
          <Paragraph color="GRAY560">
            <IconComponent icon="LOCK" fill="GRAY400" />
            <FormattedMessage id="Editing deactivated" />
          </Paragraph>
        </ItemStackBlock>
        <TextileDashboardArrowComponent status="locked" />
      </HorizontalStackBlock>
    );
  }

  let state: string = 'textile design start';
  let icon: IconNameType | UppercaseIconNameType | undefined = undefined;
  let fill: keyof typeof COLORS | undefined = undefined;

  if (printState === 'submitted' || printState === 'no_print') {
    state = 'textile my profile verified';
    icon = 'TICK_CIRCLE';
    fill = 'GREEN';
  } else {
    state = 'textile my profile open';
    icon = 'WARNING_CIRCLE';
    fill = 'PRIMARY';
  }

  return (
    <NakedLink to={textileOrderRoute(undefined, '/my_profile', textileOrderId)}>
      <HorizontalStackBlock justified={true} gap="XS" centered={true}>
        <IconComponent
          icon="MULTIPLE_STUDENTS"
          roundBackground="PRIMARY"
          size={3.5}
          square={true}
          fill="WHITE"
        />
        <ItemStackBlock gap="XXS">
          <Headline.Small>
            <FormattedMessage id="Textile name student" />
          </Headline.Small>
          <Paragraph color="TEXT_LIGHT">
            {icon && fill && <IconComponent icon={icon} fill={fill} />}
            <FormattedMessage id={state} />
          </Paragraph>
        </ItemStackBlock>
        <TextileDashboardArrowComponent status="open" />
      </HorizontalStackBlock>
    </NakedLink>
  );
};

export default TextileMyProfileBlockComponent;
