import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import TextilePhotoEditScreen from 'domain/PhotoEditScreen/TextilePhotoEditScreen';
import { useOptimisticUpdateTextileDesignSettingQuery, useTextileDesignSettingQuery } from 'queries/textile_deals/useTextileDesignSettingQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { getTextileBackCreativePhoto } from 'utils/getTextileBackCreativePhoto';

import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface BackSettingsScreenBaseProps extends HistoryProps{
  what: 'textile_back' | 'back_header' | 'creative' | 'show_frame';
}

interface FormProps {
  form: FormType;
}

const MAX_LENGTH = {
  back_headline: 120,
  back_subheadline: 120,
  back_footer: 120
};

const TextileDesignSettingsBackForm: React.FC<
  BackSettingsScreenBaseProps & FormProps
> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);

  const accessContext = useContext(HasAccessContext);
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  const textileDesignSetting = useTextileDesignSettingQuery(textileOrderId);
  const updateTextileDesignSettings = useOptimisticUpdateTextileDesignSettingQuery(textileOrderId);
  useEffect(() => {
    accessContext.checkSectionAccess('design');
    const data = textileDesignSetting.data;

    if (data) {
      const hasRequiredSettings = data.layout_key && data.theme;
      if (!hasRequiredSettings) {
        const route = textileOrderRoute(props.match, '/textile_design/layout');
        props.history.push(route);
      }

      props.form.setField(
        'textile_back',
        textileDesignSetting.data.textile_back || 'generated'
      );
      props.form.setField(
        'back_headline',
        textileDesignSetting.data.back_headline || ''
      );
      props.form.setField(
        'back_subheadline',
        textileDesignSetting.data.back_subheadline || ''
      );
      props.form.setField(
        'back_footer',
        textileDesignSetting.data.back_footer || ''
      );
      props.form.setField(
        'show_frame',
        textileDesignSetting.data.show_frame || false
      );
      props.form.setField(
        'show_groups',
        textileDesignSetting.data.show_groups || false
      );
      props.form.setField(
        'group_name',
        textileDesignSetting.data.show_groups || false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileDesignSetting.data]);

  const renderHeader = () => {
    switch (props.what) {
      case 'textile_back':
        return (
          <PageHeader
            headline={<FormattedMessage id="textile design back option" />}
            text={<FormattedMessage id="textile settings options info" />}
          />
        );

      case 'back_header':
        return (
          <PageHeader
            headline={<FormattedMessage id="Text headlines" />}
            text={<FormattedMessage id="textile settings headers info" />}
          />
        );

      case 'creative':
        return <FormattedMessage id="back design creative" />;

      case 'show_frame':
        return (
          <PageHeader
            headline={<FormattedMessage id="Frame" />}
            text={<FormattedMessage id="textile settings frames info" />}
          />
        );
      default:
        return null;
    }
  };


  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink
            to={textileOrderRoute(props.match, '/textile_design/back')}
          />
        </TopActionBlock>

        <PageStackBlock>
          {renderHeader()}
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileDesignSetting.isError) {
    return (
      renderPage(<GenericErrorComponent
        onRetryClick={() => textileDesignSetting.refetch()}
      />)
    );
  }

  if (
    props.form.loading ||
    textileDesignSetting.isLoading ||
    !textileDesignSetting.data
  ) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const handlePhotoSave = () => {
    console.log('handlePhotoSave');
    props.history.push(textileOrderRoute(props.match, '/textile_design/back'));
  };

  const save = () => {
    if (!textileOrderId) {
      return;
    }

    const settings = textileDesignSetting.data;

    const patch = { ...settings, ...props.form.values };

    props.form.setLoading(true);

    updateTextileDesignSettings.mutate(
      { data: patch, textileOrderId },
      {
        onSuccess: () => {
          props.history.push(
            textileOrderRoute(props.match, '/textile_design/back')
          );
        },
        onError: (error) => {
          props.form.setLoading(false);
          if (!handleFormError(props.form, error)) {
            props.form.setError('base', true);
          }
        }
      }
    );
  };

  const renderSaveButton = () => {
    return (
      <>
        {props.form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  };


  const renderDesign = () => {
    return renderPage(
      <>
        <FormStackBlock>
          <RadioButtonComponent
            name="textile_back"
            value="generated"
            label={<FormattedMessage id="textile back design names" />}
            {...props.form.bindRadioButton('textile_back', 'generated')}
          />

          <RadioButtonComponent
            name="textile_back"
            value="photo"
            label={
              <FormattedMessage id="textile back design creative choice" />
            }
            {...props.form.bindRadioButton('textile_back', 'photo')}
          />

          <RadioButtonComponent
            name="textile_back"
            value="neutral"
            label={<FormattedMessage id="textile back design neutral" />}
            {...props.form.bindRadioButton('textile_back', 'neutral')}
          />
        </FormStackBlock>

        {renderSaveButton()}
      </>
    );
  };

  const renderHeaderForm = () => {
    return renderPage(
      <>
        <FormStackBlock>
          <TextInputComponent
            name="back_headline"
            label={<FormattedMessage id="Headline" />}
            {...props.form.bindInput('back_headline')}
            autoFocus={true}
            maxLength={MAX_LENGTH.back_headline}
          />

          <CharCounterComponent
            max={MAX_LENGTH.back_headline}
            value={props.form.values.back_headline}
          />

          <TextInputComponent
            name="back_subheadline"
            label={<FormattedMessage id="Subheadline" />}
            {...props.form.bindInput('back_subheadline')}
            autoFocus={true}
            maxLength={MAX_LENGTH.back_subheadline}
          />

          <CharCounterComponent
            max={MAX_LENGTH.back_subheadline}
            value={props.form.values.back_subheadline}
          />

          <TextInputComponent
            name="back_footer"
            label={<FormattedMessage id="Footer" />}
            {...props.form.bindInput('back_footer')}
            autoFocus={true}
            maxLength={MAX_LENGTH.back_footer}
          />

          <CharCounterComponent
            max={MAX_LENGTH.back_footer}
            value={props.form.values.back_footer}
          />
        </FormStackBlock>
        {renderSaveButton()}
      </>
    );
  };

  const renderPhotoEditScreen = () => {
    const settings = textileDesignSetting.data;

    if (!settings) {
      return null;
    }
    const textileDesignBackPhoto = getTextileBackCreativePhoto(settings);

    return (
      <TextilePhotoEditScreen
        photoId={textileDesignBackPhoto?.id ? parseInt(textileDesignBackPhoto?.id): undefined}
        parentId={settings.id}
        handleSave={handlePhotoSave}
      />
    );
  };

  const renderShowFrame = () => {
    const { form } = props;
    if ( form.values.show_frame === undefined) {
      return null;
    }

    return renderPage(
      <>
        <FormStackBlock>
          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="show_frame"
              {...props.form.bindCheckbox('show_frame')}
            />
            <CustomCheckboxBlock.LabelElement htmlFor="show_frame">
              <FormattedMessage id="textile back show frame" />
            </CustomCheckboxBlock.LabelElement>
            <TextElement>
              {props.form.values.show_frame ? (
                <FormattedMessage id="textile back show frame active" />
              ) : (
                <FormattedMessage id="textile back show frame inactive" />
              )}
            </TextElement>
          </CustomCheckboxBlock>

          {renderSaveButton()}
        </FormStackBlock>
      </>
    );
  };

  switch (props.what) {
    case 'textile_back':
      return renderDesign();
    case 'back_header':
      return renderHeaderForm();
    case 'creative':
      return renderPhotoEditScreen();
    case 'show_frame':
      return renderShowFrame();
    default:
      return null;
  }
};

export default (props: BackSettingsScreenBaseProps) => {
  const form = useForm();
  // @ts-ignore
  return <TextileDesignSettingsBackForm {...props} form={form} />;
};
