import { get } from 'api/BaseApi';
import { Preorder } from './fetchPreorders';
import { TextilePrice } from './fetchTextilePrices';
import { TextileColor, TextileVariant } from './fetchTextileVariants';
import { Textile } from './fetchTextiles';

export interface OverviewPreorder {
  preorders?: Preorder[];
  textiles?: Textile[];
  price?: TextilePrice;
  preorder_notification?: Notification;
  orders?: CompleteOrder[];
  textile_variants?: TextileVariant[];
  profile_name?: string;
  is_rejected?: boolean;
  active_students?: number;
  submitted_preorders_students?: number;
}

interface CompleteOrder {
  size: string;
  size_amount: number;
}

export interface OverviewPlaced {
  preorders?: Preorder[];
  textiles?: Textile[];
  orders?: CompleteOrder[];
  invoice_items: InvoiceItem[];
  total_price: number;
  price_per_piece: number;
}

interface InvoiceItem {
  name: string;
  price: number;
  quantity: number;
}


interface LegacyOverviewPlaced {
  preorders?: Preorder[];
  textiles?: Textile[];
  orders?: LegacyCompleteOrder[];
  invoice_items: InvoiceItem[];
  total_price: number;
}

interface LegacyCompleteOrder {
  textile_name: string;
  textile_id: number;
  textile_amount: number;
  textile_variants: LegacyMinimalVariantAmount[];
}

export interface LegacyMinimalVariantAmount {
  textile_color: TextileColor;
  variant_amount: number;
  sizes: LegacyMinimalSizeAmount[];
}

export interface LegacyMinimalSizeAmount {
  size: string;
  size_amount: number;
}


export const getTextilePreorderOverview = (
  textileOrderId: number
): Promise<OverviewPreorder> => {
  return get(
    `/textile_screen/overview_preorders?textile_order_id=${textileOrderId}`
  );
};

export const getTextileOrderPlacedOverview = (textileOrderId: number): Promise<OverviewPlaced> => {
  return get(`/textile_screen/order_placed_overview?textile_order_id=${textileOrderId}`);
}


export const getLegacyTextileOrderPlacedOverview = (textileOrderId: number): Promise<LegacyOverviewPlaced> => {
  return get(`/textile_screen/order_placed_legacy?textile_order_id=${textileOrderId}`);
}