import Paragraph from 'components/Paragraph/Paragraph';
import InputMessage from 'elements/InputMessage';
import React from 'react';

interface PreordersFormErrorsComponentProps {
  errors?: string[];
}

const PreordersFormErrorsComponent: React.FC<PreordersFormErrorsComponentProps> = ({
  errors
}) => {
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <InputMessage error={true}>
      {errors.map((error) => {
        return (
          <Paragraph color="RED_RGB" key={error}>
            {' '}
            {error}{' '}
          </Paragraph>
        );
      })}
    </InputMessage>
  );
};

export default PreordersFormErrorsComponent;
