import React, { useContext, useEffect, useState } from 'react';

import TopActionBlock from 'blocks/TopActionBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import BackLink from 'domain/Links/BackLink';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextileCheckout } from 'api/textile_deals/fetchTextileCheckout';
import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import Headline from 'elements/Headline';
import { useTextileCheckoutQuery } from 'queries/textile_deals/useTextileCheckoutQueries';
import { useTextileOrderForCheckoutQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedMessage } from 'react-intl';
import TextileChooseDeliveryDateScreen from 'screens/textile_checkout/screens/TextileChooseDeliveryDateScreen';
import DownloadInvoiceButton from '../DownloadInvoiceButton';
import OrderPaymentInfo from '../OrderPaymentInfo';
import TextileOrderTimeline from './TextileOrderTimeline';

const TextileOrderScreen: React.FC<HistoryProps> = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDeliveryDate, setShowDeliveryDate] = useState<boolean>(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const textileCheckout = useTextileCheckoutQuery(textileOrderId, {
    refetchInterval: (data: TextileCheckout) =>
      data?.invoice === null ? 150 : false
  });
  const textileOrder = useTextileOrderForCheckoutQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('order');
  }, [
    accessContext,
    textileOrder.data,
    textileCheckout.data,
    textileCheckout.data?.invoice
  ]);

  const reloadData = () => {
    textileCheckout.refetch();
    textileOrder.refetch();
    setShowDeliveryDate(false);
  };

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileCheckout.isError) {
    props.history.replace(textileOrderRoute(props.match, '/checkout'));
  }

  if (
    !textileAppState ||
    textileCheckout.isLoading ||
    !textileCheckout.data ||
    textileCheckout.data.invoice === null ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderOrder = () => {
    const invoice = textileCheckout.data.invoice;

    const isAuthor =
      textileAppState?.textile_profile.id === textileCheckout.data.author?.id;

    if (!invoice) {
      return null;
    }

    return renderPage(
      <>
        {showDeliveryDate ? (
          <TextileChooseDeliveryDateScreen
            deliveryDateSet={() => reloadData()}
            checkoutFinished={true}
            textileOrderId={textileOrderId}
          />
        ) : (
          <>
            <Headline.Large>
              <FormattedMessage id="Order finish" />
            </Headline.Large>

            {showSpinner ? (
              <LoadingIndicatorComponent />
            ) : (
              <>
                <>
                  <a
                    href={textileCheckout.data.order_overview_pdf}
                    download="bestellliste.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <EveryCard
                      disabled={!textileCheckout.data.order_overview_pdf}
                    >
                      <EveryCardPadding>
                        <EveryCardBody>
                          <EveryCardHeadline>
                            <FormattedMessage id="download textile order overview" />
                          </EveryCardHeadline>
                          <Paragraph color="GRAY400">
                            <FormattedMessage id="PDF file" />
                          </Paragraph>
                        </EveryCardBody>
                        <IconComponent icon="DOWNLOAD" fill="BLACK" size={2} />
                      </EveryCardPadding>
                    </EveryCard>
                  </a>
                </>

                {!textileCheckout.data.order_overview_pdf && (
                  <ButtonBlock
                    background="SECONDARY"
                    onClick={() => {
                      textileCheckout.refetch();
                      setTimeout(() => {
                        setShowSpinner(true);
                        setTimeout(() => setShowSpinner(false), 1000); // Hides the spinner after 1 second
                      }, 200);
                    }}
                  >
                    <FormattedMessage id="Reload" />
                  </ButtonBlock>
                )}
              </>
            )}

            <Divider />

            <OrderPaymentInfo
              paymentReceived={textileCheckout.data.payment_received || false}
              price={invoice.price}
              usage={'t-' + textileOrder.data.id}
            />

            {invoice.invoice_pdf && <DownloadInvoiceButton invoice={invoice} />}

            <Divider />

            <TextileOrderTimeline
              textileOrder={textileOrder.data}
              deliveryDate={textileOrder.data.textile_delivery_date}
              paymentReceived={textileCheckout.data.payment_received || false}
              changeStateShowDeliveryDate={() => setShowDeliveryDate(true)}
              showButton={isAuthor}
            />
          </>
        )}
      </>
    );
  };

  return renderOrder();
};

export default TextileOrderScreen;
