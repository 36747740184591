import React from 'react';

import { NAMES, getNamesByIndex, getTeachersByIndex } from 'utils/constants/names';

import { BaseTextileDesignSetting, Font, TextileBackground } from 'api/textile_deals/fetchTextileDesignSetting';
import { FullName, TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import { namesFromResponseData, namesGroupFromResponseData, teachersFromResponseData } from 'utils/textileBackSorting';
import { PrintPicture } from '../elements/PicturePrintComponent';
import FontPreloadComponent from '../preview/FontPreloadComponent';
import TextileBackTemplateComponent from '../templates/TextileBackTemplateComponent';

export type NamesByGroup = {
  groupName: string;
  names: string[];
  teacherNames: string[];
};

interface TextileBackPreviewComponentProps {
  font: Font;
  background: TextileBackground | string;
  settings: BaseTextileDesignSetting;
  useFake: boolean;
  groups?: TextileGroup[];
  teachers: FullName[];
  students: FullName[];
}

export class TextileBackPreviewComponent extends React.Component<
  TextileBackPreviewComponentProps
> {
  generateNames(count: number) {
    if (count < 1) {
      return undefined;
    }

    return NAMES.slice(0, count);
  }

  generateGroupsWithNames(groups?: TextileGroup[]): NamesByGroup[] {
    const { settings } = this.props;
    const groupsWithFakeNames: {group_name: string, names: FullName[], teacher_names: FullName[]}[] = [];
    // @ts-ignore
    groups.forEach((group, index) => {
      const names = getNamesByIndex(index).map((name: string) => {return {first_name: name.split(' ')[0], last_name: name.split(' ')[1]}});
      const teachers = settings.show_teachers ? getTeachersByIndex(index).map((name: string) => {return {first_name: name.split(' ')[0], last_name: name.split(' ')[1]}}) : [];
      groupsWithFakeNames.push({
        group_name: group.name,
        names: names,
        teacher_names: teachers
      });
    });


    return namesGroupFromResponseData({groups_with_names: groupsWithFakeNames}, settings.names_order) || [];
  }

  displayGroups(groups?: TextileGroup[]): NamesByGroup[] | undefined {
    const { useFake, settings } = this.props;

    if (!groups) {
      return;
    }

    if (useFake) {
      return this.generateGroupsWithNames(groups);
    }
    let groupsWithNames: {group_name: string, names: FullName[], teacher_names: FullName[]}[] = [];
    groups.forEach((group) =>
      groupsWithNames.push({
        group_name: group.name,
        names: group.student_names,
        teacher_names: group.teacher_names
      })
    );

    return namesGroupFromResponseData({groups_with_names: groupsWithNames}, settings.names_order) || [];
  }

  canShowGroups(): boolean {
    const { groups, settings } = this.props;

    if (settings.show_groups && groups) {
      return true;
    }

    return false;
  }

  displayTeachers() {
    const { useFake, teachers, settings } = this.props;

    if (useFake) {
      return this.generateNames(10);
    }

    return teachersFromResponseData({teachers: teachers}, settings.names_order) || [];
  }

  displayStudents() {
    const { useFake, students, settings } = this.props;

    if (useFake) {
      return this.generateNames(30);
    }

    return namesFromResponseData({names: students}, settings.names_order) || [];
  }

  render() {
    const {settings, groups, background } = this.props;

    if (!settings) {
      return null;
    }
    let backPicture: PrintPicture | undefined;

    let backType: 'default' | 'picture' = 'default';
    switch (settings.textile_back) {
      case 'photo':
        backType = 'picture';
        // Find back cover creative photo
        const photo = settings.textile_back_creative_photo;
        const url = photo?.photo?.preview;
        if (url) {
          backPicture = { url, fit: !!photo!.fit_to_frame };
        }
        break;

      default:
    }

    const usedBackground = background || settings.background;
    if (!settings.theme || !settings.font || !usedBackground) {
      return null;
    }

    // TODO ChapterFontPreloadComponent does not work here as it requires a chapter to
    //      actually load any other fonts than the default fonts.
    return (
      <>
        <FontPreloadComponent print={false} textile={settings}>
          <TextileBackTemplateComponent
            config={settings.theme}
            font={settings.font}
            background={usedBackground}
            backPicture={backPicture}
            backType={backType}
            names={
              settings.textile_back === 'generated'
                ? this.displayStudents()
                : undefined
            }
            headline={settings.back_headline}
            subheadline={settings.back_subheadline}
            footer={settings.back_footer}
            teachers={
              settings.textile_back === 'generated' && settings.show_teachers
                ? this.displayTeachers()
                : undefined
            }
            namesByGroup={
              settings.textile_back === 'generated' && this.canShowGroups()
                ? this.displayGroups(groups)
                : undefined
            }
            showBackground={settings.show_frame}
          />
        </FontPreloadComponent>
      </>
    );
  }
}

export default TextileBackPreviewComponent;
