import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import {
  StudentType,
  TextileStudent
} from 'api/textile_deals/fetchStudentList';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import TextileProfilesList from 'domain/textileStudentList/TextileProfilesList';
import NakedLink from 'elements/NakedLink';
import { useStudentListQuery } from 'queries/textile_deals/useStudentListQueries';
import { useTextileOrderForManagementQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { useCurrentTextileProfileQuery } from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import NameEditingToggleScreen from './screens/NameEditingToggleScreen';

const ManageTextileProfilesScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const studentList = useStudentListQuery(textileOrderId);
  const textileOrder = useTextileOrderForManagementQuery(textileOrderId);
  const currentTextileProfile = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('management');
  }, [accessContext]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink
            onClick={() =>
              props.history.location?.state?.canGoBack
                ? props.history.goBack()
                : props.history.push(textileOrderRoute(props.match, ''))
            }
          />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="textile profiles names" />}
            text={<FormattedMessage id="textile profiles manage info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (studentList.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => studentList.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (currentTextileProfile.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => currentTextileProfile.refetch()}
      />
    );
  }

  if (
    !textileAppState ||
    studentList.isLoading ||
    !studentList.data ||
    textileOrder.isLoading ||
    !textileOrder.data ||
    currentTextileProfile.isLoading ||
    !currentTextileProfile.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const routeByType = (profileId: number, type?: StudentType) => {
    if (!currentTextileProfile.data) {
      return '';
    }
    const student: TextileStudent | undefined = studentList.data.find(
      (profile: TextileStudent) => profileId === profile.id
    );

    if (type === 'textile_name') {
      return '/management/virtual/edit/' + profileId;
    } else {
      if (student) {
        return '/management/edit/' + profileId;
      } else {
        return '';
      }
    }
  };

  const renderOverview = () => {
    const orderData = textileOrder.data;

    return renderPage(
      <>
        <NameEditingToggleScreen textileOrder={orderData} />

        <ButtonListBlock>
          <ButtonListBlockItem withBorder={true}>
            <NakedLink
              to={textileOrderRoute(
                props.match,
                '/management/print_data_message'
              )}
            >
              <ButtonListBlockBody>
                <FormattedMessage id="notification header" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>
        </ButtonListBlock>

        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          onClick={() =>
            props.history.push(
              textileOrderRoute(props.match, '/management/virtual/add')
            )
          }
        >
          <FormattedMessage id="textile add manuell" />
        </ButtonBlock>

        <Divider />

        <TextileProfilesList
          students={studentList.data}
          inviteUrl={textileAppState.textile_order.invitation_url}
          textileOrderId={textileOrderIdFromMatch(props.match)}
          showSorting={true}
          sortingLink={textileOrderRoute(
            props.match,
            '/management/print_order'
          )}
          sorting={orderData.names_order}
          onProfileClick={(e, profileId, type) =>
            props.history.push(
              textileOrderRoute(props.match, routeByType(profileId, type))
            )
          }
        />
      </>
    );
  };

  return renderOverview();
};

export default ManageTextileProfilesScreen;
