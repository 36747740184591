import { useEffect } from 'react';
import ROLES from 'utils/constants/roles';

const HelpWidget = ({isClosed, role}: {isClosed?: boolean, role?: ROLES}) => {
  // Define a function to load the external script based on the user role
  const loadScript = (isClosed?: boolean, role?: ROLES) => {
    // Clean up any existing script by removing it if it exists
    const existingScript = document.getElementById('help-widget-script');
    if (existingScript) {
      existingScript.remove();
    }

    // Create the new script element
    const script = document.createElement('script');

    let src = '';

    console.log("Test")
    switch (role) {
      case ROLES.MANAGER:
        if (isClosed) {
          src = 'https://widget.superchat.de/snippet.js?applicationKey=WC1baYn4VO89lyeaEDKzg53Wmq';
        } else {
          src = `https://widget.superchat.de/snippet.js?applicationKey=WC1XQN40jAd9g643r3kv6mYZ8e`;
        }
        break;
      case ROLES.ORGANIZER:
        if (isClosed) {
          src = 'https://widget.superchat.de/snippet.js?applicationKey=WC5l4bpoJkV9mw8oByaqWLN7KA';
        } else {
          src = 'https://widget.superchat.de/snippet.js?applicationKey=WC1XQN40jAd9g643r3kv6mYZ8e';
        }
        break;
      case ROLES.STUDENT || ROLES.APPLICANT:
        src = 'https://widget.superchat.de/snippet.js?applicationKey=WC5l4bpoJkV9mw8oByaqWLN7KA';
        break;
    }

    console.log('Loading help widget script:', src);

    script.id = 'help-widget-script';
    script.src = src;
    script.referrerPolicy = 'no-referrer-when-downgrade';
    script.async = true;

    // Append the new script to the body
    document.body.appendChild(script);
  };

  useEffect(() => {
    // Load the script based on the initial user role when the component mounts
    loadScript(isClosed, role);

    // Return a cleanup function to remove the script when the component unmounts or when userRole changes
    return () => {
      const existingScript = document.getElementById('help-widget-script');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [isClosed, role]); // This effect will re-run every time the userRole prop changes

  return null; // This component doesn't render anything visually
};

export default HelpWidget;
