import { useQuery } from "@tanstack/react-query";
import { getSelectedTextileVariant } from "api/textile_deals/fetchSelectedTextileVariant";

export const useSelectedTextileVariantQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['selected_textile_variant', textileOrderId],
    queryFn: () => getSelectedTextileVariant(textileOrderId!),
    enabled: !!textileOrderId,
    staleTime: 60000,
    cacheTime: 60000
  });
};