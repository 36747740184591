import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { BaseTextileProfile } from 'api/textile_deals/fetchTextileProfiles';
import { EveryCard, EveryCardBody, EveryCardPadding } from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import BackLink from 'domain/Links/BackLink';
import { intl } from 'i18n';
import { useJoinTextileOrderQuery } from 'queries/textile_deals/useTextileAppStateQueries';
import { useCurrentTextileProfileQuery } from 'queries/textile_deals/useTextileProfileQueries';
import { ROUTE_ACCOUNT_DASHBOARD } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const JoinTextileScreen: React.FC<HistoryProps> = (props) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const { data, isLoading, isError, refetch } = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );
  const joinTextileOrder = useJoinTextileOrderQuery(textileOrderId);
  const flashMessageContext = useContext(FlashMessageContext);

  useEffect(() => {
    if (textileAppState?.textile_profile.join_role_selected) {
      props.history.replace(textileOrderRoute(props.match, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textileAppState?.textile_profile]);

  if (!flashMessageContext) {
    throw Error('Component must be used within a FlashMessageProvider');
  }

  if (isError) {
    return <GenericErrorComponent onRetryClick={() => refetch()} />;
  }

  if (isLoading || !data) {
    return <LoadingIndicatorComponent />;
  }
  const requestStudent = () => {
    if (!data) {
      return;
    }

    const currentTextileProfile: BaseTextileProfile = data;

    if (!textileOrderId || !currentTextileProfile) {
      return;
    }

    setLoadingState(true);

    if (currentTextileProfile.role !== 'applicant') {
      joinTextileOrder.mutate({ orderId: textileOrderId });
    }

    joinTextileOrder.mutate(
      { orderId: textileOrderId, requestType: 'applicant_to_student' },
      {
        onSuccess: () => {
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'role change requested flash' }),
            'success'
          );
        },
        onError: () => {
          setLoadingState(false);
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'role change request error flash' }),
            'error'
          );
        }
      }
    );
  };

  const requestOrganizerRole = () => {
    if (!data) {
      return;
    }

    const currentTextileProfile: BaseTextileProfile = data;

    if (!textileOrderId || !currentTextileProfile) {
      return;
    }

    setLoadingState(true);

    if (currentTextileProfile.role === 'applicant') {
      joinTextileOrder.mutate(
        { orderId: textileOrderId, requestType: 'applicant_to_organizer' },
        {
          onSuccess: () => {
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'role change requested flash' }),
              'success'
            );
          },
          onError: () => {
            setLoadingState(false);
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'role change request error flash' }),
              'error'
            );
          }
        }
      );
    }

    if (currentTextileProfile.role === 'student') {
      joinTextileOrder.mutate(
        { orderId: textileOrderId, requestType: 'student_to_organizer' },
        {
          onSuccess: () => {
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'role change requested flash' }),
              'success'
            );
          },
          onError: () => {
            setLoadingState(false);
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'role change request error flash' }),
              'error'
            );
          }
        }
      );
    }
  };

  const renderPage = (content?: JSX.Element) => {
    return (
      <>
        {renderTopAction()}

        <PageStackBlock>{content}</PageStackBlock>

        <HelpSubject hidden={true} />
      </>
    );
  };

  const renderTopAction = () => {
    return (
      <TopActionBlock>
        <BackLink to={ROUTE_ACCOUNT_DASHBOARD} />
      </TopActionBlock>
    );
  };

  const renderOnboarding = () => {
    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="onboarding organizer header" />}
          text=""
        />

        <ListStackBlock>
          <EveryCard onClick={() => requestStudent()}>
            <EveryCardPadding>
              <EveryCardBody>
                <NonGapStackBlock>
                  <ItemStackBlock gap="XXS" marginBottom="XXS">
                    <Paragraph size="L" weight="BOLD">
                      <FormattedMessage id="role student" />
                    </Paragraph>
                  </ItemStackBlock>
                  <Paragraph color="TEXT_LIGHT" size="S">
                    <FormattedMessage id="role student info" />
                  </Paragraph>
                </NonGapStackBlock>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>

          <EveryCard onClick={() => requestOrganizerRole()}>
            <EveryCardPadding>
              <EveryCardBody>
                <NonGapStackBlock>
                  <ItemStackBlock gap="XXS" marginBottom="XXS">
                    <Paragraph size="L" weight="BOLD">
                      <FormattedMessage id="role organizer" />
                    </Paragraph>
                  </ItemStackBlock>
                  <Paragraph color="TEXT_LIGHT" size="S">
                    <FormattedMessage id="role organizer info" />
                  </Paragraph>
                </NonGapStackBlock>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </ListStackBlock>
      </>
    );
  };

  return (
    <>{loadingState ? <LoadingIndicatorComponent /> : renderOnboarding()}</>
  );
};

export default JoinTextileScreen;
