import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextileRoleChangeRequest } from 'api/textile_deals/fetchTextileRoleChangeRequests';
import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import UppercaseHeading from 'elements/UppercaseHeading';
import { intl } from 'i18n';
import {
  useAcceptTextileRoleChangeQuery,
  useRejectTextileRoleChangeQuery,
  useTextileRoleChangeRequestsQuery
} from 'queries/textile_deals/useTextileRoleChangeRequestQueries';
import { sortByField } from 'utils/sort-functions';
import TextileRoleChangeItemComponent from './TextileRoleChangeItemComponent';

interface TextileRoleChangeComponentState {
  displayAll?: boolean;
  confirmRejectId?: number;
}

const MAX_COLLAPSED = 3;

interface TextileRoleChangeComponentProps {
  textileOrderId: number
}

const TextileRoleChangeComponent: React.FC<TextileRoleChangeComponentProps> = ({textileOrderId}) => {
  const [state, setState] = useState<TextileRoleChangeComponentState>({});
  const flashMessageContext = useContext(FlashMessageContext);
  const queryClient = useQueryClient();
  const {
    data,
    isError,
    isLoading,
    refetch
  } = useTextileRoleChangeRequestsQuery(textileOrderId);
  const acceptMutation = useAcceptTextileRoleChangeQuery(textileOrderId);
  const rejectMutation = useRejectTextileRoleChangeQuery(textileOrderId);
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  if (!flashMessageContext) {
    throw  Error('Component must be used within a FlashMessageProvider');
  }

  const sortedRoleChangeRequests = (
    limit?: number
  ): TextileRoleChangeRequest[] => {
    if (!data) {
      return [];
    }

    const list = data.sort(sortByField('created_at'));

    if (limit) {
      return list.slice(0, limit);
    }

    return list;
  };

  const accept = (requestId: number) => {
    if (!data) {
      return;
    }

    const request = data.find((itm) => itm.id === requestId);
    if (!request) {
      return;
    }

    acceptMutation.mutate({id: requestId, textileOrderId}, {
      onSuccess: () => {
        flashMessageContext.triggerFlashMessage(
          intl.formatMessage({ id: 'role change accepted flash' }),
          'success'
        );
      },
      onError: () => {
        flashMessageContext.triggerFlashMessage(
          intl.formatMessage({ id: 'role change accept error flash' }),
          'error'
        );
      },
    });
  };

  const reject = (requestId: number, confirmed: boolean = false) => {
    if (!data) {
      return;
    }

    const request = data.find((itm) => itm.id === requestId);
    if (!request) {
      return;
    }

    if (request.request_type === 'applicant_to_student' && !confirmed) {
      setState({
        confirmRejectId: request.id
      });
      return;
    }

    setState({
      confirmRejectId: undefined,
    });

    rejectMutation.mutate({id: requestId, textileOrderId }, {
      onSuccess: () => {
        flashMessageContext.triggerFlashMessage(
          intl.formatMessage({ id: 'role change rejected flash' }),
          'warning'
        );
      },
      onError: () => {
        flashMessageContext.triggerFlashMessage(
          intl.formatMessage({ id: 'role change reject error flash' }),
          'error'
        );
      }
    });
  };

  const maybeRenderConfirm = () => {
    const { confirmRejectId } = state;

    if (!confirmRejectId) {
      return null;
    }

    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="reject applicant confirm header" />}
        text={<FormattedMessage id="reject applicant confirm" />}
        confirmColor="RED"
        confirmText={<FormattedMessage id="Reject" />}
        onConfirm={() => reject(confirmRejectId, true)}
        abortText={<FormattedMessage id="Cancel" />}
        onAbort={() => setState({ confirmRejectId: undefined })}
      />
    );
  };

  if (isError || !textileAppState) {
    return <GenericErrorComponent onRetryClick={() => refetch()} />;
  }

  if (isLoading || !data) {
    return <LoadingOverlayComponent />;
  }

  if (textileAppState.textile_profile.is_organizer) {
    const { displayAll } = state;
    const count = data.length;

    if (!count || count === 0) {
      return null;
    }

    const list = sortedRoleChangeRequests(
      displayAll ? undefined : MAX_COLLAPSED
    );

    return (
      <>
        <ItemStackBlock gap="S">
          <UppercaseHeading>
            <FormattedMessage id="new requests count" values={{ count }} />
          </UppercaseHeading>

          <ListStackBlock>
            {list.map((request: TextileRoleChangeRequest) => (
              <TextileRoleChangeItemComponent
                key={request.id}
                request={request}
                onAcceptClick={() => accept(request.id)}
                onRejectClick={() => reject(request.id)}
              />
            ))}
          </ListStackBlock>
        </ItemStackBlock>

        {displayAll ? (
          count > MAX_COLLAPSED ? (
            <ButtonBlock
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              onClick={() => setState({ displayAll: false })}
            >
              <FormattedMessage id="Show less" />
            </ButtonBlock>
          ) : null
        ) : count > list.length ? (
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => setState({ displayAll: true })}
          >
            <FormattedMessage id="Show all" />
          </ButtonBlock>
        ) : null}

        {maybeRenderConfirm()}
      </>
    );
  } else {
    return null;
  }
}

export default TextileRoleChangeComponent;
