import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TextElement from 'components/TextElement/TextElement';
import TrustSymbolsComponent from 'components/TrustSymbolsComponent';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import NakedButton from 'elements/NakedButton';
import {
  useFinishTextileCheckoutQuery,
  useTextileCheckoutQuery,
  useUpdateTextileCheckoutQuery,
  useUploadTextileParentalApprovalQuery
} from 'queries/textile_deals/useTextileCheckoutQueries';
import {
  useGenerateTextilePreviewQuery,
  useTextileOrderForCheckoutQuery
} from 'queries/textile_deals/useTextileOrderQueries';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AddressForm from 'screens/checkout/screens/AddressForm';
import CheckoutAGB from 'screens/checkout/screens/CheckoutAGB';
import TextileCalculatorPrice from 'screens/textile_calculator/Components/TextileCalculatorPrice';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import { UppercaseIconNameType } from 'components/IconComponent/svg-sprites-info';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { intl } from 'i18n';
import COLORS from 'utils/constants/colors';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, {
  FormType,
  FormValues,
  handleFormError
} from 'utils/hooks/useForm';
import TextileAgeScreen from './screens/TextileAgeScreen';
import TextileChooseDeliveryDateScreen from './screens/TextileChooseDeliveryDateScreen';
import TextilePrintingScreen from './screens/TextilePrintingScreen';

export type TextileCheckoutStep =
  | 'overview'
  | 'shipping'
  | 'billing'
  | 'address'
  | 'printing'
  | 'age'
  | 'delivery_date'
  | 'agb'
  | 'complete';

export type UpdateTextileCheckoutContext = Exclude<
  TextileCheckoutStep,
  'complete' | 'overview' | 'shipping' | 'billing'
>;

interface FormProps {
  form: FormType;
}

interface TextileCheckoutScreenState {
  showConfirmBox: boolean;
  step: TextileCheckoutStep;
  pdfGeneration: 'none' | 'generating' | 'failed';
}

export const TextileCheckoutScreen: React.FC<HistoryProps & FormProps> = (
  props
) => {
  const [checkoutState, setCheckoutState] = useState<
    TextileCheckoutScreenState
  >({
    showConfirmBox: false,
    step: 'overview',
    pdfGeneration: 'none'
  });
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const flashMessageContext = useContext(FlashMessageContext);
  const textileCheckout = useTextileCheckoutQuery(textileOrderId);
  const textileOrder = useTextileOrderForCheckoutQuery(textileOrderId);
  const updateTextileCheckoutQuery = useUpdateTextileCheckoutQuery(
    textileOrderId
  );
  const generateTextilePreview = useGenerateTextilePreviewQuery(textileOrderId);
  const uploadParentalApproval = useUploadTextileParentalApprovalQuery(textileOrderId);
  const finishCheckoutQuery = useFinishTextileCheckoutQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  if (!flashMessageContext) {
    throw Error('Component must be used within HasFlashMessageProvider');
  }

  useEffect(() => {
    if (
      textileAppState && (textileAppState.textile_order.order_state === 'order_placed')
    ) {
      return props.history.push(
        textileOrderRoute(props.match, '/order')
      );
    }
    if (
      textileAppState && (textileAppState.textile_order.order_state !== 'order_started')
    ) {
      return props.history.push(
        textileOrderRoute(props.match, '/calculator/order')
      );
    }
    accessContext.checkSectionAccess('checkout');
    const data = textileCheckout.data;
    if (data && !props.form.values) {
      props.form.setField('shipping_name', data.shipping_name || '');
      props.form.setField('phone_number', data.phone_number || '');
      props.form.setField(
        'shipping_address_extra',
        data.shipping_address_extra || ''
      );
      props.form.setField('shipping_street', data.shipping_street || '');
      props.form.setField(
        'shipping_housenumber',
        data.shipping_housenumber || ''
      );
      props.form.setField('shipping_zip', data.shipping_zip || '');
      props.form.setField('shipping_city', data.shipping_city || '');

      props.form.setField('billing_name', data.billing_name || '');
      props.form.setField(
        'billing_address_extra',
        data.billing_address_extra || ''
      );
      props.form.setField('billing_street', data.billing_street || '');
      props.form.setField(
        'billing_housenumber',
        data.billing_housenumber || ''
      );
      props.form.setField('billing_zip', data.billing_zip || '');
      props.form.setField('billing_city', data.billing_city || '');
      props.form.setField('billing_address_set', data.billing_address_set);
      props.form.setField('accept_print', data.accept_print);
      props.form.setField('accept_agb', data.accept_agb);
      if (data.minor === undefined || data.minor === null) {
        props.form.setField('minor', undefined);
      } else {
        props.form.setField('minor', data.minor ? 'true' : 'false');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileCheckout.data, textileAppState]);

  const reloadPrint = () => {
    textileCheckout.refetch();
    textileOrder.refetch();
  };

  const generate = () => {
    if (!textileOrderId) {
      return;
    }
    generateTextilePreview.mutate(textileOrderId, {
      onSettled: () => {
        setCheckoutState({ ...checkoutState, pdfGeneration: 'generating' });
      },
      onError: () => {
        setCheckoutState({ ...checkoutState, pdfGeneration: 'failed' });
      }
    });
  };

  const handleUploadParentalApproval = (file: File) => {
    const { form } = props;

    if (!textileOrderId) {
      return;
    }

    form.resetErrors();
    form.setLoading(true);
    const patch = {
      parental_approval: {
        file
      }
    };

    uploadParentalApproval.mutate(
      { patch, textileOrderId },
      {
        onError: (error: any) => {
          form.setLoading(false);

          handleFormError(form, error);
        },
        onSettled: () => {
          form.setLoading(false);
        }
      }
    );
  };

  const updateAgb = (formValues: FormValues) => {
    if (!textileOrderId) {
      return false;
    }

    const patch = {
      ...formValues,
      context: 'agb'
    };

    updateTextileCheckoutQuery.mutate({ patch, textileOrderId });
  };

  const updateAge = (minor: boolean) => {
    const { form } = props;

    if (!textileOrderId) {
      return;
    }

    form.resetErrors();

    const patch = {
      minor,
      context: 'age'
    };

    updateTextileCheckoutQuery.mutate(
      { patch, textileOrderId },
      {
        onSuccess: () => {
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'Saved' }),
            'success'
          );
        },
        onError: (error: any) => {

          handleFormError(form, error);
        }
      }
    );
  };

  const updateTextileCheckout = (context: UpdateTextileCheckoutContext) => {
    const { form } = props;

    if (!textileOrderId) {
      return;
    }

    form.resetErrors();
    form.setLoading(true);

    const patch = {
      ...form.values,
      context
    };

    updateTextileCheckoutQuery.mutate(
      { patch, textileOrderId },
      {
        onSuccess: () => {
          form.setLoading(false);
          setCheckoutState({ ...checkoutState, step: 'overview' });
        },
        onError: (error: any) => {
          form.setLoading(false);
          handleFormError(form, error);
        }
      }
    );
  };

  const finishCheckout = () => {
    const { history, match, form } = props;

    if (!textileOrderId) {
      return;
    }
    form.setLoading(true);

    finishCheckoutQuery.mutate(textileOrderId, {
      onSettled: () => {
        history.push(textileOrderRoute(match, '/order'));
      }
    })
  };

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          {checkoutState.step === 'overview' ? (
            <BackLink to={textileOrderRoute(props.match, '')} />
          ) : (
            <NakedButton
              onClick={() =>
                setCheckoutState({ ...checkoutState, step: 'overview' })
              }
            >
              <TextElement color="GRAY10">
                <HorizontalStackBlock gap="S">
                  <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />
                  <FormattedMessage id="Back" />
                </HorizontalStackBlock>
              </TextElement>
            </NakedButton>
          )}
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileCheckout.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileCheckout.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (
    props.form.loading ||
    !textileAppState ||
    textileCheckout.isLoading ||
    !textileCheckout.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const validSteps = (): TextileCheckoutStep[] => {
    const steps: TextileCheckoutStep[] = [];

    if (!textileCheckout.data) {
      return steps;
    }

    if (textileCheckout.data.shipping_address) {
      steps.push('shipping');
    }

    if (textileCheckout.data.billing_address) {
      steps.push('billing');
    }

    if (textileCheckout.data.accept_print) {
      steps.push('printing');
    }

    if (textileCheckout.data.age_verified) {
      steps.push('age');
    }

    if (textileCheckout.data.delivery_date_selected) {
      steps.push('delivery_date');
    }

    return steps;
  };

  const invalidSteps = (): TextileCheckoutStep[] => {
    const steps: TextileCheckoutStep[] = [];

    if (!textileCheckout.data) {
      return steps;
    }

    if (
      !textileCheckout.data.shipping_address ||
      !textileCheckout.data.billing_address
    ) {
      steps.push('address');
    }

    if (
      !textileCheckout.data.accept_print ||
      textileCheckout.data.print_state !== 'generated'
    ) {
      steps.push('printing');
    }

    if (!textileCheckout.data.age_verified) {
      steps.push('age');
    }

    if (!textileCheckout.data.delivery_date_selected) {
      steps.push('delivery_date');
    }

    return steps;
  };

  const renderValidDataCard = (identifier: TextileCheckoutStep) => {
    let cardHeadline;
    let cardInfo;

    switch (identifier) {
      case 'shipping':
        cardHeadline = 'textile checkout shipping';
        cardInfo = (
          <>
            <ItemStackBlock gap="XXS">
              <TextElement color="BLACK">
                {textileCheckout.data.shipping_name}
              </TextElement>
              <TextElement color="BLACK">
                {textileCheckout.data.shipping_street}{' '}
                {textileCheckout.data.shipping_housenumber}
              </TextElement>
              <TextElement color="BLACK">
                {textileCheckout.data.shipping_zip}
              </TextElement>
              <TextElement color="BLACK">
                {textileCheckout.data.shipping_city}
              </TextElement>
            </ItemStackBlock>
          </>
        );
        break;

      case 'billing':
        cardHeadline = 'textile checkout billing';
        cardInfo = (
          <>
            <ItemStackBlock gap="XXS">
              <TextElement color="BLACK">
                {textileCheckout.data.billing_name}
              </TextElement>
              <TextElement color="BLACK">
                {textileCheckout.data.billing_street}{' '}
                {textileCheckout.data.billing_housenumber}
              </TextElement>
              <TextElement color="BLACK">
                {textileCheckout.data.billing_zip}
              </TextElement>
              <TextElement color="BLACK">
                {textileCheckout.data.billing_city}
              </TextElement>
            </ItemStackBlock>
          </>
        );
        break;
      case 'printing':
        cardHeadline = 'textile checkout print';
        cardInfo = (
          <TextElement color="BLACK">
            <FormattedMessage id="textile checkout print valid" />
          </TextElement>
        );
        break;
      case 'age':
        cardHeadline = 'textile checkout age';
        cardInfo = (
          <TextElement color="BLACK">
            <FormattedMessage id="textile checkout age valid" />
          </TextElement>
        );
        break;
      case 'delivery_date':
        const day = intl.formatDate(
          textileOrder.data.textile_delivery_date?.delivery_date,
          { day: '2-digit' }
        );
        const month = intl.formatDate(
          textileOrder.data.textile_delivery_date?.delivery_date,
          { month: '2-digit' }
        );
        const year = intl.formatDate(
          textileOrder.data.textile_delivery_date?.delivery_date,
          { year: '2-digit' }
        );
        const date = day + '.' + month + '.' + year;
        cardHeadline = 'textile checkout delivery date';
        cardInfo = (
          <TextElement color="BLACK">
            <FormattedMessage
              id="textile checkout delivery date valid"
              values={{ date: date }}
            />
          </TextElement>
        );
        break;
      default:
        return null;
    }

    return (
      <NakedButton
        onClick={() => setCheckoutState({ ...checkoutState, step: identifier })}
      >
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <HorizontalStackBlock justified={true}>
                <EveryCardHeadline>
                  <FormattedMessage id={cardHeadline} />
                </EveryCardHeadline>
                <IconComponent icon="EDIT" fill="BLACK" />
              </HorizontalStackBlock>
              <HorizontalStackBlock gap="XXS">
                <TextElement color="BLACK">{cardInfo}</TextElement>
              </HorizontalStackBlock>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
      </NakedButton>
    );
  };

  const renderMissingDataCard = (identifier: TextileCheckoutStep) => {
    let cardHeadline;
    let cardInfo;
    let cardIcon: UppercaseIconNameType = 'WARNING_CIRCLE';
    let cardIconFill: keyof typeof COLORS = 'PRIMARY';

    switch (identifier) {
      case 'address':
        cardHeadline = 'textile checkout address';
        cardInfo = 'textile checkout address';
        break;
      case 'printing':
        cardHeadline = 'textile checkout print';
        cardInfo = 'textile checkout print missing';
        break;
      case 'age':
        cardHeadline = 'textile checkout age';
        cardInfo = 'textile checkout age missing';
        if (
          textileCheckout.data.parental_approval &&
          !textileCheckout.data.parental_approval_checked
        ) {
          cardInfo = 'Checkout parental approval check';
          cardIcon = 'CLOCK_CIRCLE';
          cardIconFill = 'BLACK';
        }
        break;
      case 'delivery_date':
        cardHeadline = 'textile checkout delivery date';
        cardInfo = 'textile checkout delivery date missing';
        break;
      default:
        return null;
    }

    return (
      <NakedButton
        onClick={() => setCheckoutState({ ...checkoutState, step: identifier })}
      >
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>
                <FormattedMessage id={cardHeadline} />
              </EveryCardHeadline>
              <HorizontalStackBlock gap="XXS">
                <IconComponent icon={cardIcon} fill={cardIconFill} />
                <TextElement color="BLACK">
                  <FormattedMessage id={cardInfo} />
                </TextElement>
              </HorizontalStackBlock>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
      </NakedButton>
    );
  };

  const renderConfirmFinish = () => {
    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="Checkout confirm choice header" />}
        text={<FormattedMessage id="Textile checkout confirm choice text" />}
        confirmText={<FormattedMessage id="Confirm" />}
        abortText={<FormattedMessage id="Cancel" />}
        onAbort={() => {
          setCheckoutState({ ...checkoutState, showConfirmBox: false });
        }}
        onConfirm={() => {
          setCheckoutState({ ...checkoutState, showConfirmBox: false });
          finishCheckout();
        }}
        confirmColor="PRIMARY"
        abortColor="SECONDARY_DARK"
      />
    );
  };

  const renderOverview = () => {
    const { form } = props;

    const textilePrice = textileCheckout.data.price;

    if (!textilePrice) {
      return null;
    }
    const missingData = invalidSteps();
    const validData = validSteps();

    const finishAllowed =
      missingData.length === 0 && form.values.accept_agb === true;

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Place order" />}
          text={<FormattedMessage id="textile checkout place order info" />}
        />

        <Divider />

        {missingData && missingData.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="missing checkout data" />
            </Headline.Medium>

            <ItemStackBlock gap="S">
              {missingData.map((data) => {
                return (
                  <ItemStackBlock gap="M" key={data}>
                    {renderMissingDataCard(data)}
                  </ItemStackBlock>
                );
              })}
            </ItemStackBlock>
          </>
        )}

        {validData && validData.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="valid checkout data" />
            </Headline.Medium>

            <ItemStackBlock gap="S">
              {validData.map((data) => {
                return (
                  <ItemStackBlock gap="M" key={data}>
                    {renderValidDataCard(data)}
                  </ItemStackBlock>
                );
              })}
            </ItemStackBlock>
          </>
        )}

        <Divider />

        <TextileCalculatorPrice textilePrice={textilePrice} isCheckout={true} />

        <Divider />

        <CheckoutAGB
          form={form}
          updateAgb={(formValues) => updateAgb(formValues)}
        />

        {/* Finish button */}

        <ButtonBlock
          background={finishAllowed ? 'PRIMARY' : 'GRAY950'}
          color={finishAllowed ? 'WHITE' : 'GRAY800'}
          disabled={!finishAllowed}
          onClick={() => {
            setCheckoutState({ ...checkoutState, showConfirmBox: true });
          }}
        >
          <FormattedMessage id="confirm order" />
        </ButtonBlock>

        {checkoutState.showConfirmBox && renderConfirmFinish()}

        <IconListBlock>
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="Free shipping" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="Climate neutral print" />
          </IconListElement>
        </IconListBlock>

        <TrustSymbolsComponent />
      </>
    );
  };

  const renderAddress = () => {
    const { form } = props;

    return renderPage(
      <AddressForm
        form={form}
        updateAddress={() => updateTextileCheckout('address')}
      />
    );
  };

  const renderBilling = () => {
    const { form } = props;

    return renderPage(
      <AddressForm
        form={form}
        updateAddress={() => updateTextileCheckout('address')}
        setBilling={true}
      />
    );
  };

  const renderPrint = () => {
    const { form } = props;

    return renderPage(
      <TextilePrintingScreen
        form={form}
        updatePrinting={() => updateTextileCheckout('printing')}
        generatePrint={() => generate()}
        checkForPreview={() => reloadPrint()}
        textileOrder={textileOrder.data}
        studentFrontPreview={textileCheckout.data.student_front_preview}
        studentBackPreview={textileCheckout.data.student_back_preview}
        studentFrontMockup={textileCheckout.data.student_front_mockup}
        studentBackMockup={textileCheckout.data.student_back_mockup}
      />
    );
  };

  const renderAge = () => {
    const { form } = props;

    return renderPage(
      <TextileAgeScreen
        form={form}
        handleUploadParentalApproval={(file) =>
          handleUploadParentalApproval(file)
        }
        updateAge={(minor: boolean) => updateAge(minor)}
        reloadData={() => {
          setCheckoutState({ ...checkoutState, step: 'overview' });
          reloadPrint();
        }}
        textileCheckout={textileCheckout.data}
      />
    );
  };

  const renderDelivery = () => {
    return renderPage(
      <TextileChooseDeliveryDateScreen
        deliveryDateSet={() => {
          setCheckoutState({ ...checkoutState, step: 'overview' });
          reloadPrint();
        }}
        textileOrderId={textileOrderId}
      />
    );
  };

  switch (checkoutState.step) {
    case 'overview':
      return renderOverview();
    case 'address':
      return renderAddress();
    case 'shipping':
      return renderAddress();
    case 'billing':
      return renderBilling();
    case 'printing':
      return renderPrint();
    case 'age':
      return renderAge();
    case 'delivery_date':
      return renderDelivery();
    default:
      return renderOverview();
  }
};

export default (props: HistoryProps) => {
  const form = useForm();

  // @ts-ignore
  return <TextileCheckoutScreen {...props} form={form} />;
};
