// https://babeljs.io/docs/en/babel-polyfill
import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'regenerator-runtime/runtime';

// intl polyfills
import 'intl';
// import 'intl/locale-data/jsonp/en';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import 'intl/locale-data/jsonp/de';
// import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/de';

import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { RawIntlProvider } from 'react-intl';

import App from './App';
import bootstrap from './config/bootstrap/bootstrap';

import { storeIntlRef } from 'i18n';
import * as serviceWorker from './serviceWorker';

const { stores, intl } = bootstrap();
storeIntlRef(intl);

ReactDOM.render(
  <RawIntlProvider value={intl}>
    <Provider {...stores}>
      <App />
    </Provider>
  </RawIntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
