import { useQuery } from "@tanstack/react-query";
import { getOrderCalculatorOverview } from "api/textile_deals/fetchOrderCalculatorOverview";
import { getLegacyTextileOrderPlacedOverview, getTextileOrderPlacedOverview, getTextilePreorderOverview } from "api/textile_deals/fetchOverviewPreorder";

export const useOrderOverviewQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['order_overview', textileOrderId],
    queryFn: () => getTextilePreorderOverview(textileOrderId!),
    enabled: !!textileOrderId
  });
};

export const useCalculatorOverviewQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['calculator_overview', textileOrderId],
    queryFn: () => getOrderCalculatorOverview(textileOrderId!),
    enabled: !!textileOrderId,
    refetchOnMount: 'always',
    cacheTime: 0
  });
};

export const useTextileOrderPlacedOverviewQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['order_placed_overview', textileOrderId],
    queryFn: () => getTextileOrderPlacedOverview(textileOrderId!),
    enabled: !!textileOrderId
  });
}

export const useLegacyTextileOrderPlacedOverviewQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['order_placed_overview', textileOrderId],
    queryFn: () => getLegacyTextileOrderPlacedOverview(textileOrderId!),
    enabled: !!textileOrderId
  });
}