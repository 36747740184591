import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { useSelectedTextileVariantQuery } from 'queries/textile_deals/useSelectedTextileVariantQueries';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import {
  useProfileUpdateTexitlePreorderQuery,
  useTextilePreoderQuery
} from 'queries/textile_deals/useTextilePreorderQueries';
import { FormattedMessage } from 'react-intl';
import InfoTextoForTextileComponent from 'screens/preorders/components/InfoTextForTextileComponent';
import OrganizerMessageComponent from 'screens/preorders/components/OrganizerMessageComponent';
import PreordersFormErrorsComponent from 'screens/preorders/components/PreorderFormErrorsComponent';
import PreorderPreferenceComponent from 'screens/preorders/components/PreorderPreferenceComponent';
import SizesByTextileComponent from 'screens/preorders/components/SizesByTextileVariantComponent';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const OwnPreorderEditScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const [errors, setErrors] = useState<string[]>([]);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const textilePreorderId = idFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const textilePreorder = useTextilePreoderQuery(
    textilePreorderId,
    textileOrderId
  );
  const selectedTextileVariant = useSelectedTextileVariantQuery(textileOrderId);
  const updateTextilePreorder = useProfileUpdateTexitlePreorderQuery(
    textileOrderId,
    textilePreorderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    const data = textilePreorder.data;
    if (data) {
      props.form.setField('preorder_state', data.profile_preorder_state);
      props.form.setField('size', data.textile_size_id?.toString());
    }

    accessContext.checkSectionAccess('own_preorder');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessContext,
    textileAppState?.textile_order.order_state,
    textilePreorder.data
  ]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/preorder')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={textilePreorder.data?.preorder_name}
            text={''}
          />
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (selectedTextileVariant.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => selectedTextileVariant.refetch()}
      />
    );
  }

  if (textilePreorder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textilePreorder.refetch()} />
    );
  }

  if (
    props.form.loading ||
    !textileAppState ||
    textileOrder.isLoading ||
    !textileOrder.data ||
    selectedTextileVariant.isLoading ||
    !selectedTextileVariant.data ||
    textilePreorder.isLoading ||
    !textilePreorder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const updateProfilePreorder = () => {
    const { form, match, history } = props;

    if (!textileOrderId || !textilePreorderId) {
      return;
    }

    const currentErrors = [];

    if (form.values.preorder_state === 'submitted' && !form.values.size) {
      currentErrors.push(
        intl.formatMessage({ id: 'preorder add missing size' })
      );
      setErrors(currentErrors);
    }

    let patch;
    if (form.values.preorder_state === 'submitted' && form.values.size) {
      patch = {
        textile_profile_id: textilePreorder.data?.textile_profile_id,
        textile_size_id: form.values.size
      };
    } else if (form.values.preorder_state === 'no_order') {
      patch = {
        textile_profile_id: textilePreorder.data?.textile_profile_id
      };
    }

    if (patch) {
      form.setLoading(true);
      updateTextilePreorder.mutate(
        {
          id: textilePreorderId,
          textileOrderId,
          data: patch,
          preorder_state: form.values.preorder_state
        },
        {
          onSettled: () => {
            form.setLoading(false);
            history.push(textileOrderRoute(match, '/preorder'));
          }
        }
      );
    }
  };

  const renderAdd = () => {
    return renderPage(
      <>
        <Divider />
        <Headline.Medium>
          <FormattedMessage id="Your Order" />
        </Headline.Medium>
        <OrganizerMessageComponent
          organizerMessage={textileOrder.data.organizer_preorder_message}
        />
        <InfoTextoForTextileComponent
          size_info={selectedTextileVariant.data.textile_size_info}
        />
        <PreorderPreferenceComponent form={props.form} />
        <SizesByTextileComponent
          form={props.form}
          sizes={selectedTextileVariant.data.textile_sizes}
        />
        <PreordersFormErrorsComponent errors={errors} />
        <ButtonBlock
          background="PRIMARY"
          onClick={() => updateProfilePreorder()}
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  };

  return renderAdd();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <OwnPreorderEditScreen {...props} form={form} />;
};
