import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PreorderState } from 'api/textile_deals/fetchPreorders';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import {
  IconNameType,
  UppercaseIconNameType
} from 'components/IconComponent/svg-sprites-info';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import COLORS from 'utils/constants/colors';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import TextileDashboardArrowComponent from '../components/TextileDashboardArrowComponent';

export const TextilePreorderStudentBlockComponent = ({
  textileOrderId,
  textilePreorderId,
  locked,
  preorderState,
  preorderOpen,
}: {
  textileOrderId: number;
  textilePreorderId: number;
  locked: boolean;
  preorderState: PreorderState;
  preorderOpen: boolean;
}) => {
  if (locked) {
    return (
      <HorizontalStackBlock justified={true} gap="XS" centered={true}>
        <IconComponent
          icon="PREORDER"
          roundBackground="GRAY950"
          size={3.5}
          square={true}
          fill="GRAY800"
        />
        <ItemStackBlock gap="XXS">
          <Headline.Small color="GRAY560">
            <FormattedMessage id="Textile preorder" />
          </Headline.Small>

          <Paragraph color="GRAY560">
            <IconComponent icon="LOCK" fill="GRAY400" />
            <FormattedMessage id="Editing deactivated" />
          </Paragraph>
        </ItemStackBlock>
        <TextileDashboardArrowComponent status={'locked'} />
      </HorizontalStackBlock>
    );
  }

  let state: string = 'textile design start';
  let icon: IconNameType | UppercaseIconNameType | undefined = undefined;
  let fill: keyof typeof COLORS | undefined = undefined;

  if (preorderState === 'open')  {
    state = 'textile student preorder open';
    icon = 'WARNING_CIRCLE';
    fill = 'PRIMARY';
  } else if (preorderState === 'submitted') {
    state = 'textile student preorder submitted';
    icon = 'TICK_CIRCLE';
    fill = 'GREEN';
  } else if (preorderState === 'no_order') {
    state = 'textile student preorder no order';
    icon = 'TICK_CIRCLE';
    fill = 'GREEN';
  }

  if (!preorderOpen) {
    state = 'textile student preorder closed';
    icon = 'LOCK';
    fill = 'GRAY400';
  }

  const link = preorderState === 'open' ? textileOrderRoute(undefined, `/my_profile/preorder/add`, textileOrderId) : textileOrderRoute(undefined, `/my_profile/preorder/edit/${textilePreorderId}`, textileOrderId)

  return (
    <NakedLink
      to={link}
    >
      <HorizontalStackBlock justified={true} gap="XS" centered={true}>
        <IconComponent
          icon="PREORDER"
          roundBackground="PRIMARY"
          size={3.5}
          square={true}
          fill="WHITE"
        />
        <ItemStackBlock gap="XXS">
          <Headline.Small>
            <FormattedMessage id="Textile preorder" />
          </Headline.Small>
          <Paragraph color="TEXT_LIGHT">
            {icon && fill && <IconComponent icon={icon} fill={fill} />}
            <FormattedMessage id={state} />
          </Paragraph>
        </ItemStackBlock>
        <TextileDashboardArrowComponent status="open" />
      </HorizontalStackBlock>
    </NakedLink>
  );
};

export default TextilePreorderStudentBlockComponent;
