import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import { useTextileOrderPlacedOverviewQuery } from 'queries/textile_deals/useOrderOverviewQueries';
import { useSelectedTextileVariantQuery } from 'queries/textile_deals/useSelectedTextileVariantQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import TextileOrderInformation from '../components/TextileOrderPlacedInformation';

const TextileOrderPlacedOverviewScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const accessContext = useContext(HasAccessContext);
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const orderOverview = useTextileOrderPlacedOverviewQuery(textileOrderId);
  const selectedTextileVariant = useSelectedTextileVariantQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (
      textileAppState?.textile_profile.is_student ||
      textileAppState?.textile_profile.is_applicant
    ) {
      props.history.replace(textileOrderRoute(props.match, ''));
    }

    accessContext.checkSectionAccess('order_placed');
  }, [accessContext, orderOverview.data]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>

        <HelpSubject subject="order" />
      </>
    );
  };

  if (orderOverview.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => orderOverview.refetch()} />
    );
  }

  if (selectedTextileVariant.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => selectedTextileVariant.refetch()}
      />
    );
  }

  if (
    !textileAppState ||
    orderOverview.isLoading ||
    !orderOverview.data ||
    !selectedTextileVariant.data ||
    selectedTextileVariant.isLoading
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderOverview = () => {
    if (!orderOverview.data.total_price) {
      return null;
    }

    const order = orderOverview.data.orders;
    const preordersAmount = orderOverview.data.preorders?.length || 0;
    const selectedVariant = selectedTextileVariant.data;

    if (!order || order.length === 0) {
      return null;
    }

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="textile order overview" />}
          text={<FormattedMessage id="textile order overview total" />}
        />

        <TextileOrderInformation
          textileName={selectedVariant.textile_name}
          colorName={selectedVariant.textile_color?.display_name}
        />

        {preordersAmount !== 0 && (
          <EveryCard disabled={true}>
            <EveryCardPadding>
              <EveryCardHeadline>
                <FormattedMessage id="Total amount" />
              </EveryCardHeadline>
              <EveryCardBody right={true}>
                <TextElement color="TEXT_DARK" size="L" weight="BOLD">
                  {preordersAmount}
                </TextElement>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        )}

        {order &&
          order.map((itm) => {
            return (
              <ItemStackBlock gap="XXS" key={itm.size}>
                <HorizontalStackBlock justified={true}>
                  <HorizontalStackBlock gap="S">
                    <Paragraph color="TEXT_LIGHT">{itm.size}</Paragraph>
                  </HorizontalStackBlock>
                  <Paragraph color="TEXT_LIGHT">{itm.size_amount}</Paragraph>
                </HorizontalStackBlock>
                <Divider useStackBlockGap={true} />
              </ItemStackBlock>
            );
          })}

        <ItemStackBlock gap="NO">
          <HorizontalStackBlock justified={true}>
            <Paragraph>
              <FormattedMessage id="Gross price textile" />
            </Paragraph>

            <Paragraph>
              <FormattedNumber
                value={orderOverview.data.total_price}
                style="currency"
                currency="EUR"
              />
            </Paragraph>
          </HorizontalStackBlock>

          <Paragraph color="TEXT_LIGHT" size="XS">
            <FormattedMessage id="textile include vat and used discounts" />
          </Paragraph>

          <ItemStackBlock gap="NO" marginTop="S">
            <HorizontalStackBlock justified={true}>
              <Paragraph color="TEXT_LIGHT" size="XS">
                <FormattedMessage id="textile price per piece final" />
              </Paragraph>
              <FormattedNumber
                value={orderOverview.data.price_per_piece}
                style="currency"
                currency="EUR"
              />
            </HorizontalStackBlock>
          </ItemStackBlock>
        </ItemStackBlock>
      </>
    );
  };

  return renderOverview();
};

export default TextileOrderPlacedOverviewScreen;
