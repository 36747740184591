import React from 'react';
import styled from 'styled-components';
import { layoutImageOffsetsByTextile } from 'utils/layout-image-offsets';
import textileThemesImagePath from 'utils/textile-theme-image-path';

interface ProductImageContainerProps {
  height?: string;
  width?: string;
  align?: string;
  justify?: string;
  heightAsRef?: boolean;
}

interface LayoutImageContainerProps {
  top: string;
  left: string;
  height: string;
  width: string;
}

export const ProductImageContainer = styled.div<ProductImageContainerProps>`
  position: relative;
  aspect-ratio: 355/452;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  ${(props) => props.align && `align-self: ${props.align};`}
  ${(props) => props.justify && `justify-self: ${props.justify};`}

  .productImage {
    position: absolute;
    top: 0;
    left: 0;

    ${(props) =>
      props.heightAsRef
        ? `
      width: auto;
      height: 100%;
    `
        : `width: 100%;
      height: auto;`}
  }
`;

const LayoutImageContainer = styled.img<LayoutImageContainerProps>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  object-fit: fill;

`

const TextileProductImageComponent = ({
  image,
  height,
  width,
  heightAsRef,
  align,
  justify,
  layoutKey,
  layoutImage,
  textileType,
  previewFront
}: {
  image: string;
  height?: string;
  width?: string;
  align?: string;
  justify?: string;
  heightAsRef?: boolean;
  layoutKey?: string;
  layoutImage?: string;
  textileType: string;
  previewFront?: string
}) => {
  if (!image) {
    return null;
  }

  const offsets = layoutImageOffsetsByTextile(textileType, 'front')
  let layout;
  if (layoutKey) {
    layout = textileThemesImagePath(
      layoutKey,
      layoutImage
    )
  } else {
    layout = previewFront
  }

  return (
    <ProductImageContainer
      width={width}
      height={height}
      align={align}
      justify={justify}
      heightAsRef={heightAsRef}
    >
      <img
        src={image}
        alt="vorschau bild"
        className="productImage"
        loading="lazy"
      />
      <LayoutImageContainer
        src={layout}
        alt="vorschau bild"
        loading="lazy"
        top={offsets.top}
        left={offsets.left}
        height={offsets.height}
        width={offsets.width}
      />
    </ProductImageContainer>
  );
};

export default TextileProductImageComponent;
