import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import {
  BaseTextileDesignSetting,
  getTextileOrderDesignByTextileOrderId,
  updateTextileDesignSetting
} from 'api/textile_deals/fetchTextileDesignSetting';

export const useTextileDesignSettingQuery = (id?: number) => {
  return useQuery({
    queryKey: ['textile_design_setting', id],
    queryFn: () => getTextileOrderDesignByTextileOrderId(id!),
    enabled: !!id
  });
};

export const useOptimisticUpdateTextileDesignSettingQuery = (
  textileOrderId?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: {
      data: BaseTextileDesignSetting;
      textileOrderId: number;
    }) => updateTextileDesignSetting(patch.textileOrderId, patch.data),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_design_setting',
        patch.textileOrderId
      ]);
      await queryClient.cancelQueries([
        'textile_app_state',
        patch.textileOrderId
      ]);
      const previousState:
        | BaseTextileDesignSetting
        | undefined = queryClient.getQueryData([
        'textile_design_setting',
        patch.textileOrderId
      ]);

      const previousAppState:
        | TextileAppState
        | undefined = queryClient.getQueryData([
        'textile_app_state',
        patch.textileOrderId
      ]);

      if (previousState) {
        const newState: BaseTextileDesignSetting = patch.data;
        queryClient.setQueryData(
          ['textile_design_setting', patch.textileOrderId],
          newState
        );
      }

      if (previousAppState) {
        const newAppState: TextileAppState = {
          ...previousAppState,
          textile_order: {
            ...previousAppState.textile_order,
            theme: patch.data.theme,
            layout_key: patch.data.layout_key,
            show_groups: patch.data.show_groups,
          }
        };
        queryClient.setQueryData(
          ['textile_app_state', patch.textileOrderId],
          newAppState
        );
      }

      return { previousState, previousAppState };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_design_setting', patch.textileOrderId],
        context?.previousState
      );

      queryClient.setQueryData(
        ['textile_app_state', patch.textileOrderId],
        context?.previousState
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_design_setting', textileOrderId]);
      queryClient.invalidateQueries(['textile_app_state', textileOrderId]);
    }
  });
};
