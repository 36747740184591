import React, { useContext, useEffect, useRef } from 'react';

import { TextilePrice } from 'api/textile_deals/fetchTextilePrices';
import { Textile } from 'api/textile_deals/fetchTextiles';
import ButtonBlock from 'blocks/ButtonBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import InfoBoxComponent from 'components/InfoBoxComponent/InfoBoxComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TrustSymbolsComponent from 'components/TrustSymbolsComponent';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Cookies from 'js-cookie';
import {
  useTextilePriceFromFormQuery,
  useTextilePricingListQuery
} from 'queries/textile_deals/useTextilePriceQueries';
import { useTextilesQuery } from 'queries/textile_deals/useTextileQueries';
import { FormattedMessage } from 'react-intl';
import TextileOrderInformation from 'screens/order/screens/components/TextileOrderPlacedInformation';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import { FormType, useForm } from 'utils/hooks/useForm';
import TextileCalculatorForm from './Components/TextileCalculatorForm';
import TextileCalculatorPrice from './Components/TextileCalculatorPrice';
import TextilePricingTable from './Components/TextilePricingTable';

interface FormProps {
  form: FormType;
}

const TextileCalculator: React.FC<HistoryProps & FormProps> = (props) => {
  const sendTimeoutHandle = useRef<NodeJS.Timeout | undefined>();
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const textiles = useTextilesQuery(textileOrderId);
  const textilePrice = useTextilePriceFromFormQuery();
  const textilePriceList = useTextilePricingListQuery();

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  const setupForm = (textiles?: Textile[], price?: TextilePrice) => {
    if (Cookies.get('textile_pricing_config')) {
      return;
    }

    if (!textiles || textiles.length === 0) {
      return;
    }

    const { form } = props;
    form.setField('selected_textile_id', textiles[0].id.toString());
    form.setField('amount', '20');
  };

  const getPrice = () => {
    const { form } = props;

    if (!textileOrderId || !textiles.data || textiles.data.length === 0) {
      return;
    }

    form.resetErrors();
    form.setLoading(true);

    textilePrice.mutate(
      {
        textileOrderId,
        textileId: form.values.selected_textile_id,
        amount: form.values.amount
      },
      {
        onSettled: () => {
          form.setLoading(false);
        }
      }
    );
  };

  const scheduleGetPrice = () => {
    clearTimeout(sendTimeoutHandle.current);
    sendTimeoutHandle.current = setTimeout(() => {
      getPrice();
    }, 1000);
  };

  useEffect(() => {
    accessContext.checkSectionAccess('calculator');

    setupForm(textiles.data, textilePrice.data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textiles.data]);

  useEffect(() => {
    scheduleGetPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.values, textiles.data]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader
            text={<FormattedMessage id="textile calculator text" />}
            headline={<FormattedMessage id="Calculator" />}
          ></PageHeader>
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textiles.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textiles.refetch()} />
    );
  }

  if (textilePriceList.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textilePriceList.refetch()} />
    );
  }

  if (Object.keys(props.form.errors).length > 0) {
    return renderPage(
      <InfoBoxComponent error={true}>
        <FormattedMessage id="calculator error" />
      </InfoBoxComponent>
    );
  }

  if (
    textiles.isLoading ||
    textilePriceList.isLoading ||
    !textiles.data ||
    !textilePriceList.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const offerAsPDF = () => {
    props.history.push(textileOrderRoute(props.match, '/calculator/offer'));
  };

  const renderCalculator = () => {
    const { form } = props;

    if (textiles.data.length === 0) {
      return null;
    }

    const isLoading = form.loading;

    const textileName = textiles.data.find(
      (item) => item.id === parseInt(form.values.selected_textile_id)
    )?.internal_identifier;

    const displayName = textiles.data.find(
      (item) => item.id === parseInt(form.values.selected_textile_id)
    )?.name;

    return renderPage(
      <ItemStackBlock gap="L">
        <TextileOrderInformation />
        <TextileCalculatorForm
          form={form}
          textiles={textiles.data}
          errors={textilePrice.data?.errors}
          match={props.match}
        />

        <TextilePricingTable
          pricingsJSON={textilePriceList.data}
          textileName={textileName}
          displayName={displayName}
        />

        {isLoading ? (
          <LoadingIndicatorComponent />
        ) : (
          !textilePrice.data?.errors && (
            <ItemStackBlock gap="XL">
              <TextileCalculatorPrice textilePrice={textilePrice.data} />

              <ButtonBlock background="PRIMARY" onClick={() => offerAsPDF()}>
                <FormattedMessage id="Offer as PDF" />
              </ButtonBlock>
            </ItemStackBlock>
          )
        )}

        <IconListBlock>
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="Free shipping" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="Climate neutral print" />
          </IconListElement>
        </IconListBlock>

        <TrustSymbolsComponent />
      </ItemStackBlock>
    );
  };

  return renderCalculator();
};

const TextileCalculatorScreen = (props: any) => {
  // Read last config from cookie
  const cookie = Cookies.get('textile_pricing_config');

  const textileOrderId = textileOrderIdFromMatch(props.match);
  let initialFormValues;

  if (cookie) {
    try {
      const cookieValues = JSON.parse(cookie);
      if (
        typeof cookieValues === 'object' &&
        cookieValues['textile_order_id'] === textileOrderId &&
        cookieValues.form_values
      ) {
        initialFormValues = cookieValues.form_values;
      }
    } catch (e) {
    }
  }

  const form = useForm(initialFormValues);
  return <TextileCalculator {...props} form={form} />;
};

export default TextileCalculatorScreen;
