import { useMutation, useQuery } from "@tanstack/react-query";
import { calculateTextilePriceFromForm, getTextilePricingList } from "api/textile_deals/fetchTextilePrices";

export const useTextilePriceFromFormQuery = () => {
  return useMutation({
    mutationFn: (data: { textileOrderId: number; textileId: number; amount: number }) =>
    calculateTextilePriceFromForm(data.textileId, data.amount, data.textileOrderId),
  });
};

export const useTextilePricingListQuery = () => {
  return useQuery({
    queryKey: ['pricings_list'],
    queryFn: () => getTextilePricingList(),
    staleTime: 60000
  })
}