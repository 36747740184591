import useResizeObserver from '@react-hook/resize-observer';
import { BaseTextileDesignSetting, TextileBackground } from 'api/textile_deals/fetchTextileDesignSetting';
import { FullName, TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import TextileBackPreviewComponent from 'components/print/layout-editor/TextileBackPreviewComponent';
import ScaledTextilePreviewComponent from 'components/print/structure/ScaledTextilePreviewComponent';
import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { layoutImageOffsetsByTextile } from 'utils/layout-image-offsets';
import { ProductImageContainer } from './TextileProductImageComponent';

interface PreviewContainerProps {
  top: string;
  left: string;
}

export const PreviewContainer = styled.div<PreviewContainerProps>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`;

const useSize = (target: any): { width: number; height: number } => {
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0
  });

  useLayoutEffect(() => {
    if (target !== null && target.current !== null) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) =>
    setSize({
      width: entry.contentRect.width,
      height: entry.contentRect.height
    })
  );
  return size;
};

const TextileBackGeneratedPreviewComponent = ({
  bottom,
  width,
  students,
  teachers,
  settings,
  previewBackground,
  groups,
  useFakeData,
  textileType,
}: {
  bottom: string;
  width?: string;
  students?: FullName[];
  teachers?: FullName[];
  settings?: BaseTextileDesignSetting;
  previewBackground?: TextileBackground | string;
  groups?: TextileGroup[];
  useFakeData?: boolean;
  textileType: string;
}) => {
  if (!bottom) {
    return null;
  }
  if (!settings) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const target = React.useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const size = useSize(target);

  if (!settings) {
    return null;
  }

  const layout = settings?.theme;
  const font = settings?.font;
  const background = settings?.background;

  if (!layout || !font || !background) {
    return null;
  }

  const offsets = layoutImageOffsetsByTextile(textileType, 'back')


  const previewSize = {
    width: (size.width / 100) * parseInt(offsets.width.slice(0, -1)),
    height: (size.height / 100) * parseInt(offsets.height.slice(0, -1))
  };


  return (
    <ProductImageContainer height={size.height.toString() + 'px'} width={width}>
      <img
        src={bottom}
        alt="vorschau bild"
        className="productImage"
        ref={target}
      />
      <PreviewContainer top={offsets.top} left={offsets.left}>
        <ScaledTextilePreviewComponent width={previewSize.width} height={previewSize.height}>

          <TextileBackPreviewComponent
            font={font}
            background={previewBackground || background}
            settings={settings}
            groups={groups}
            students={students || []}
            teachers={teachers || []}
            useFake={!!useFakeData}
          />
        </ScaledTextilePreviewComponent>
      </PreviewContainer>
    </ProductImageContainer>
  );
};

export default TextileBackGeneratedPreviewComponent;
