import React, { useContext, useEffect, useState } from 'react';

import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextileVariant } from 'api/textile_deals/fetchTextileVariants';
import {
  ImageCard,
  ImageCardImage,
  ImageCardLabel,
  LabelledImageCard
} from 'blocks/ImageCard/ImageCard';
import TopActionBlock from 'blocks/TopActionBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import { useSelectedTextileVariantQuery } from 'queries/textile_deals/useSelectedTextileVariantQueries';
import { useCreateTextileOrdersTextileVariantsQuery } from 'queries/textile_deals/useTextileOrdersTextileVariantsQueries';
import { useTextileVariantsByTextileQuery } from 'queries/textile_deals/useTextileVariantsQueries';
import { FormattedMessage } from 'react-intl';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const TextileDesignColorSelection: React.FC<HistoryProps> = (props) => {
  const [showModelChangeModal, setShowModelChangeModal] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState<TextileVariant | null>()
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const textileId = idFromMatch(props.match, 'textileId');
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  const selectedTextileVariant = useSelectedTextileVariantQuery(textileOrderId);
  const textileVariants = useTextileVariantsByTextileQuery(textileId);
  const createTextileOrdersTextileVariant = useCreateTextileOrdersTextileVariantsQuery();

  useEffect(() => {
    accessContext.checkSectionAccess('design');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink
            to={textileOrderRoute(
              props.match,
              '/textile_design/model_selection'
            )}
          />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={textileVariants.data?.[0].textile_name}
            text={<FormattedMessage id="textile design variants info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileVariants.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileVariants.refetch()} />
    );
  }

  if (selectedTextileVariant.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => selectedTextileVariant.refetch()}
      />
    );
  }

  if (
    selectedTextileVariant.isLoading ||
    !selectedTextileVariant.data ||
    textileVariants.isLoading ||
    !textileVariants.data ||
    !textileAppState
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }


  const renderModelChangeModal = () => {
    if (selectedVariant === null || !selectedVariant) {
      return null;
    }

    return (
      <ConfirmBoxComponent
      header={<FormattedMessage id="textile order model change" />}
      text={<FormattedMessage id="textile order model change info" />}
      confirmText={<FormattedMessage id="Change and delete" />}
      abortText={<FormattedMessage id="Cancel" />}
      onConfirm={(() => selectVariant(selectedVariant))}
      onAbort={() => setShowModelChangeModal(false)}
      confirmColor="RED"
    />
    )
  };

  const selectVariant = (textileVariant: TextileVariant) => {
    if (!textileOrderId) {
      return;
    }

    createTextileOrdersTextileVariant.mutate(
      { textileOrderId, textileVariant },
      {
        onSuccess: () => {
          props.history.push(textileOrderRoute(props.match, '/textile_design'));
        }
      }
    );
  };

  const renderModelSelection = () => {
    const allVariants = textileVariants.data;
    const selectedVariant = selectedTextileVariant.data;

    if (!selectedVariant) {
      return null;
    }

    const isDifferentModel = selectedVariant.textile_id !== textileId;

    if (showModelChangeModal) {
      return renderModelChangeModal();
    }

    return renderPage(
      <>
        <TwoColumnBlock>
          {allVariants.map((variant) => (
            <LabelledImageCard
              key={variant.id}
              onClick={() => {
                setSelectedVariant(variant);
                if (isDifferentModel) {
                  setShowModelChangeModal(true);
                } else {
                  selectVariant(variant);
                }
              }}
            >
              <ImageCard
                contain={true}
                border={
                  selectedTextileVariant.data?.id === variant.id
                    ? 'PRIMARY'
                    : false
                }
              >
                <ImageCardImage src={variant.front_preview_bottom} />
              </ImageCard>
              <ImageCardLabel>
                {variant.textile_color?.display_name}
              </ImageCardLabel>
            </LabelledImageCard>
          ))}
        </TwoColumnBlock>
      </>
    );
  };

  return renderModelSelection();
};

export default TextileDesignColorSelection;
