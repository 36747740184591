/* eslint-disable react/style-prop-object */
import React from 'react';

import { TextilePrice } from 'api/textile_deals/fetchTextilePrices';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import Paragraph from 'components/Paragraph/Paragraph';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import TextileDiscountListItem from 'screens/textile_dashboard/components/TextileDiscountListItem';

interface TextileCalculatorPriceProps {
  textilePrice?: TextilePrice;
  isCheckout?: boolean;
}

const TextileCalculatorPrice = ({
  textilePrice,
  isCheckout
}: TextileCalculatorPriceProps) => {
  if (!textilePrice || !textilePrice.price) {
    return null;
  }

  const formAmount = textilePrice.form_amount;
  const preordersAmount = textilePrice.preorders_amount;

  const amount = isCheckout ? preordersAmount : formAmount;

  if (!amount) {
    return null;
  }

  const price = textilePrice.price.price_textiles;
  const endPrice = textilePrice.price.end_price;
  const possiblePrice = textilePrice.price.possible_price;
  const discounts = textilePrice.discounts || [];
  const pricePerPiece = textilePrice.price.price_per_piece;

  return (
    <>
      <ItemStackBlock gap="M">
        <ItemStackBlock gap="NO">
          <HorizontalStackBlock justified={true}>
            <Paragraph size="L" weight="BOLD">
              <FormattedMessage
                id="amount textiles for pricing"
                values={{ amount: amount }}
              />
            </Paragraph>

            <Paragraph>
              <FormattedNumber value={price} style="currency" currency="EUR" />
            </Paragraph>
          </HorizontalStackBlock>

          <Paragraph color="TEXT_LIGHT" size="S">
            {textilePrice.price.textile_name}
          </Paragraph>
        </ItemStackBlock>

        {discounts.length > 0 && <Divider useStackBlockGap={true} />}

        {discounts.map((discount) => (
          <TextileDiscountListItem
            discount={discount}
            active={discount.checked}
            isCheckout={isCheckout}
            key={discount.identifier}
          />
        ))}

        <Divider useStackBlockGap={true} />

        <ItemStackBlock gap="NO">
          <HorizontalStackBlock justified={true}>
            <Paragraph size="L" weight="BOLD">
              {isCheckout ? (
                <FormattedMessage id="Gross price textile" />
              ) : (
                <FormattedMessage id="Gross price possible" />
              )}
            </Paragraph>

            <Paragraph size="L">
              {isCheckout ? (
                <FormattedNumber
                  value={endPrice}
                  style="currency"
                  currency="EUR"
                />
              ) : (
                <FormattedNumber
                  value={possiblePrice}
                  style="currency"
                  currency="EUR"
                />
              )}
            </Paragraph>
          </HorizontalStackBlock>

          <Paragraph color="TEXT_LIGHT" size="S">
            {isCheckout ? (
              <FormattedMessage id="textile include vat and used discounts" />
            ) : (
              <FormattedMessage id="textile include vat and discounts" />
            )}
          </Paragraph>

          <ItemStackBlock gap="NO" marginTop="M">
            <HorizontalStackBlock justified={true}>
              <Paragraph color="TEXT_LIGHT">
                <FormattedMessage id="textile price per piece final" />
              </Paragraph>
              <Paragraph>
                <FormattedNumber
                  value={pricePerPiece}
                  style="currency"
                  currency="EUR"
                />
              </Paragraph>
            </HorizontalStackBlock>
          </ItemStackBlock>
        </ItemStackBlock>
      </ItemStackBlock>
    </>
  );
};

export default TextileCalculatorPrice;
