import React from 'react';

import { PreviewContainer } from 'screens/preorders/components/TextileBackGeneratedPreviewComponent';
import { ProductImageContainer } from 'screens/preorders/components/TextileProductImageComponent';
import { layoutImageOffsetsByTextile } from 'utils/layout-image-offsets';
import TextileMockUpComponent from '../structure/TextileMockUpComponent';
import WaitForImagesTextiles from './WaitForImagesTextiles';

export interface TextileFrontTemplateProps {
  productImage?: string;
  textileType?: string;
  backImage?: string;
}

export class TextileBackMockUpTemplateComponent extends React.Component<
  TextileFrontTemplateProps
> {
  render() {
    const { productImage, textileType, backImage } = this.props;

    if (!productImage || !textileType || !backImage) {
      return null;
    }

    const offsets = layoutImageOffsetsByTextile(textileType, 'back');

    const previewSize = {
      width: (975 / 100) * parseInt(offsets.width.slice(0, -1)),
      height: (1248 / 100) * parseInt(offsets.height.slice(0, -1))
    };

    return (
      <>
        <TextileMockUpComponent>
          <ProductImageContainer height={'1248px'} width={'975px'}>
            <img
              src={productImage}
              alt="vorschau bild"
              className="productImage"
            />
            <PreviewContainer top={offsets.top} left={offsets.left}>
              <div
                style={{
                  position: 'relative',
                  width: previewSize.width,
                  height: previewSize.height
                }}
              >
                <img
                  src={backImage}
                  alt="back image"
                  width="100%"
                  height="100%"
                />
              </div>
            </PreviewContainer>
          </ProductImageContainer>
        </TextileMockUpComponent>
        {<WaitForImagesTextiles />}
      </>
    );
  }
}

export default TextileBackMockUpTemplateComponent;
