import { destroy, get, patch, post, uri } from 'api/BaseApi';
import { convertToFormData } from 'api/NuggitApi';

interface RemoteFile {
  full: string;
  cropped: string;
  shrinked: string;
  small: string;
  preview: string;
}

export interface TextilePhoto {
  id: number;
  photo: RemoteFile;
  parent_id: number;
  image_config: ImageConfig;
  fit_to_frame: boolean;
  caption?: string;
  metadata: {
    width: number;
    height: number;
  }
}

interface ImageCutOff {
  width: number | null;
  height: number | null;
  top: number | null;
  left: number | null;
}

interface ImageConfig {
  cutOff: ImageCutOff;
  rotation: number | null;
  crop: boolean | null;
}

interface PhotoFile {
  file: File;
  old?: {
    url: string;
    preview: string;
  }
}

interface BaseTextilePhotoBody {
  photo: PhotoFile;
  image_config?: ImageConfig;
}

export interface CreateTextilePhotoBody extends BaseTextilePhotoBody {
  parent_id: number;
}

export interface UpdateTextilePhotoBody extends BaseTextilePhotoBody {
  id: number;
}

export const getTextilePhoto = (
  id: number,
  textileOrderId: number
): Promise<TextilePhoto> => {
  return get(`/textile_photos/${uri(id)}?textile_order_id=${textileOrderId}`);
};

export const createPhotoTextile = (
  photo: CreateTextilePhotoBody,
  textileOrderId: number
): Promise<TextilePhoto> => {
  return post(`/textile_photos?textile_order_id=${textileOrderId}`, convertToFormData(photo, 'photo') );
};

export const updatePhotoTextile = (
  photo: UpdateTextilePhotoBody,
  textileOrderId: number
): Promise<TextilePhoto> => {
  return patch(
    `/textile_photos/${uri(photo.id)}?textile_order_id=${textileOrderId}`,
    convertToFormData(photo, 'photo')
  );
};

export const removePhotoTextile = (id: number, textileOrderId: number) => {
  return destroy(
    `/textile_photos/${uri(id)}?textile_order_id=${textileOrderId}`
  );
};
